<script lang="ts" setup>
import nxtPeopleLoader from '~/assets/animations/nxt-people-loader.json';
import nxtPlanLoader from '~/assets/animations/nxt-plan-loader.json';

const props = defineProps({
   mode: { type: String as PropType<'nxt-people' | 'nxt-plan'>, default: 'nxt-plan' },
   height: { type: Number, default: 100 },
   width: { type: Number, default: 100 },
});

const loader = computed(() => {
   if (props.mode === 'nxt-people') {
      return nxtPeopleLoader;
   }

   if (props.mode === 'nxt-plan') {
      return nxtPlanLoader;
   }

   return nxtPeopleLoader;
});
</script>

<template>
   <LayoutLottie :animation-data="loader" :height="height" :width="width" />
</template>

<style lang="scss">
.spinner {
   // Positioning

   // Box-model
   display: flex;
   width: fit-content;

   // Typography
   font-size: 2rem;
   color: var(--nxt-main);

   // Visual

   // Misc
   &.-tiny i {
      font-size: 1rem;
   }
   &.-small i {
      font-size: 1.5rem;
   }
   &.-large i {
      font-size: 2rem;
   }
   &.-giant i {
      font-size: 5rem;
   }
}
</style>
