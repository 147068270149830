import type { TenantStyle } from '~/models/tenant-branding.interface';

export const MY_LETS_WORK_BRANDING: TenantStyle = {
   tenantName: 'My Lets Work',
   themeColor: '#ffffff',
   bannerImage: '/myletswork-icons/banner.png',
   fontUrl: 'https://nxtpeople-cdn.s3.eu-central-1.amazonaws.com/fonts/Usual_NXT/Usual_NXT.css',
   showPositions: false,
   files: {
      termsAndConditionsUrl: (locale) => {
         if (locale === 'fr') return 'https://www.letswork.be/fr/conditions/';
         if (locale === 'nl') return 'https://www.letswork.be/nl/algemene-voorwaarden/';
         return 'https://www.letswork.be/en/terms-and-conditions/';
      },
      privacyPolicyUrl: (locale) => {
         if (locale === 'fr') return 'https://www.letswork.be/fr/politique-de-confidentialite/';
         if (locale === 'nl') return 'https://www.letswork.be/nl/privacy-policy/';
         return 'https://www.letswork.be/en/privacy-policy/';
      },
      cookiePolicyUrl: (locale) => {
         if (locale === 'fr') return 'https://www.letswork.be/fr/politique-de-confidentialite/';
         if (locale === 'nl') return 'https://www.letswork.be/nl/privacy-policy/';
         return 'https://www.letswork.be/en/privacy-policy/';
      },
   },
   cssProperties: {
      '--nxt-main': 'var(--letswork-main)',
      '--nxt-secondary': 'var(--letswork-red)',
      '--nxt-secondary-text': 'var(--nxt-white)',
      '--nxt-main-highlight': 'var(--letswork-main-highlight)',
      '--nxt-main-overlay': 'var(--letswork-main-overlay)',
      '--nxt-base-header-background': 'var(--nxt-white)',
      '--nxt-base-header-color': 'var(--nxt-dark)',
      '--nxt-base-background': 'var(--letswork-grey)',
      '--nxt-special-background': "var(--letswork-grey) url('/myletswork-icons/background.svg') no-repeat 40% 100%",
      '--nxt-card-background': 'var(--nxt-white)',

      '--nxt-radius': '0',
      '--nxt-radius-small': '0',
      '--nxt-radius-large': '0',

      '--nxt-font-family': 'var(--letswork-font-family)',
   },
};
