export const PUBLIC_ROUTE_NAMES = [
   'not-found',
   'not-allowed',
   'register',
   'register-candidateId',
   'register-personId-tenantId',
   'privacy-policy',
   'badge',
   'person-personId-verify-verificationId',
];
