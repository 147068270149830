<script lang="ts" setup>
defineEmits<{
   (e: 'onClick'): void;
}>();
const props = defineProps({
   showDayOfWeek: { type: Boolean, default: true },
   dayOfWeek: { type: String, default: null },
   status: { type: [String, Array, Object], default: () => ({}) },
});

const classes = computed(() => {
   if (typeof props.status === 'string') return [props.status];
   if (Array.isArray(props.status)) return props.status;

   const classes = [];
   for (const [key, value] of Object.entries(props.status)) {
      if (value) classes.push(key);
   }
   return classes;
});
</script>

<template>
   <div :class="['layout-day', ...classes, { '-show-day-of-week': showDayOfWeek }]">
      <small v-if="showDayOfWeek && dayOfWeek" class="layout-day__day-of-week">
         {{ dayOfWeek }}
      </small>

      <button :tabindex="-1" :class="'layout-day__day'" @click="() => $emit('onClick')">
         <div class="layout-day__day__number">
            <slot />
         </div>
      </button>
   </div>
</template>

<style lang="scss" scoped>
.layout-day {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: toRem(3);
   @include mono;

   &.current_date {
      .layout-day__day__number {
         position: relative;
         &::before {
            content: '';
            position: absolute;
            top: toRem(1);
            left: 50%;
            height: toRem(4);
            width: toRem(4);
            border-radius: toRem(2.5);
            background-color: var(--nxt-dark);
            transform: translateX(-50%);
         }
      }
   }

   &.unavailable {
      .layout-day__day__number {
         border-color: var(--nxt-red);
      }
   }

   &.partially_available {
      .layout-day__day__number {
         border-color: var(--nxt-orange);
      }
   }

   &.available {
      .layout-day__day__number {
         border-color: var(--nxt-green);
      }
   }

   &.planning_confirmation_sent {
      .layout-day__day {
         background-color: var(--nxt-plan-verified-green);
      }
   }

   &.planning_confirmation_approved {
      .layout-day__day__number {
         color: var(--nxt-white);
      }
      .layout-day__day {
         background-color: var(--nxt-plan-main);
      }
      &.active .layout-day__day__number::before {
         background-color: var(--nxt-white);
      }
   }

   &.planning_reserve_sent {
      .layout-day__day {
         background-color: var(--nxt-plan-reserve-yellow);
      }
   }

   &.planning_reserve_approved {
      .layout-day__day__number {
         color: var(--nxt-dark);
      }
      .layout-day__day {
         background-color: var(--nxt-yellow);
      }
   }

   &.presence_show {
      .layout-day__day__number {
         color: var(--nxt-white);
      }
      .layout-day__day {
         background-color: var(--nxt-plan-show-blue);
      }

      &.active .layout-day__day__number::before {
         background-color: var(--nxt-white);
      }
   }
   &.presence_no_show {
      .layout-day__day__number {
         color: var(--nxt-white);
      }
      .layout-day__day {
         background-color: var(--nxt-plan-no-show-red);
      }

      &.active .layout-day__day__number::before {
         background-color: var(--nxt-white);
      }
   }

   &.placeholder {
      .layout-day__day__number {
         opacity: 0.4;
      }
   }

   &.active {
      .layout-day__day__number {
         background-color: var(--nxt-dark);
         color: var(--nxt-white);

         &::before {
            background-color: var(--nxt-white);
         }
      }
   }
}

.layout-day__day {
   border: 0;
   background: transparent;
   height: toRem(28);
   min-width: toRem(28);
   border-radius: toRem(28);
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   outline: 0;
   padding: 0;
   color: var(--nxt-dark);
   text-decoration: none;

   transition-property: border-color, background-color, color;
   transition-duration: 0.3s;
   transition-timing-function: ease;
}

.layout-day__day-of-week {
   font-size: toRem(8);
   text-transform: capitalize;
}

.layout-day__day__number {
   width: toRem(28);
   height: toRem(28);
   text-align: center;
   line-height: toRem(26);
   border-radius: 50%;
   border: 1px solid transparent;
   background-color: transparent;
   @include mono;
   font-size: toRem(12);
}
</style>
