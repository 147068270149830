<script setup lang="ts">
// #region Globals
const { disabled, name, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region ModelValue
const modelValue = defineModel<boolean>('modelValue', { default: false });

function toggle() {
   if (disabled.value) return;
   modelValue.value = !modelValue.value;

   validate();
   touch();
}
// #endregion
</script>

<template>
   <div class="precognition-switch" :class="{ disabled, checked: !!modelValue }" @click="toggle">
      <div class="precognition-switch__handle"></div>
      <input :id="name" :name="name" class="hide-input" :value="modelValue" readonly @input="toggle" />
   </div>
</template>

<style lang="scss" scoped>
.precognition-switch {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: toRem(4);
   height: toRem(28);
   border-radius: toRem(20);
   background: var(--nxt-light-grey);
   cursor: pointer;
   width: toRem(50);
   min-width: toRem(50);
   box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.precognition-switch.disabled {
   opacity: 0.5;
   cursor: not-allowed;
}

.precognition-switch.checked {
   background: var(--nxt-green);

   .precognition-switch__handle {
      transform: translateX(toRem(22));
   }
}

.precognition-switch__handle {
   display: flex;
   justify-content: center;
   align-items: center;
   width: toRem(21);
   height: toRem(21);
   background: white;
   border-radius: 50%;
   transition: all 0.2s ease-in-out;

   i {
      font-size: 1.2rem;
   }
}
</style>
