import type { Directive, DirectiveBinding } from 'vue';

function hex(x: string) {
   return ('0' + parseInt(x).toString(16)).slice(-2);
}

function rgb2hex(rgb: any) {
   if (!rgb) throw new Error('rgb is required to get hex');

   rgb = rgb.match(/^(rgb|hsl)(a?)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/);
   return '#' + hex(rgb[3]) + hex(rgb[4]) + hex(rgb[5]);
}

function setFontColorByHex(el: HTMLElement, binding: DirectiveBinding<boolean>) {
   if (binding.value === false) return;

   const BACKGROUND_RGB = el.parentElement ? window.getComputedStyle(el.parentElement).getPropertyValue('background-color') : '';

   if (BACKGROUND_RGB === 'rgba(0, 0, 0, 0)' || !BACKGROUND_RGB) return;

   let backgroundHex = rgb2hex(BACKGROUND_RGB);

   if (backgroundHex.indexOf('#') === 0) {
      backgroundHex = backgroundHex.slice(1);
   }

   // convert 3-digit hex to 6-digits.
   if (backgroundHex.length === 3) {
      backgroundHex = backgroundHex[0] + backgroundHex[0] + backgroundHex[1] + backgroundHex[1] + backgroundHex[2] + backgroundHex[2];
   }

   if (backgroundHex.length !== 6) {
      throw new Error('Invalid HEX color.');
   }

   const r = parseInt(backgroundHex.slice(0, 2), 16);
   const g = parseInt(backgroundHex.slice(2, 4), 16);
   const b = parseInt(backgroundHex.slice(4, 6), 16);

   el.style.color = r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'var(--nxt-dark)' : 'var(--nxt-white)';
}

const FontColorContraster: Directive = {
   mounted: setFontColorByHex,
   updated: setFontColorByHex,
};

export { FontColorContraster };
