export default function usePreventDoubleClick(prevent: boolean = false) {
   const isClicked = ref(false);

   function handleClick(callback: Function) {
      if (!prevent) {
         callback();
         return;
      }
      if (!isClicked.value) {
         isClicked.value = true;
         callback();
         setTimeout(() => {
            isClicked.value = false;
         }, 500);
      }
   }

   return { handleClick };
}
