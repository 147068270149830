<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { GlobalModalKey } from '~/resources/modal/modal.interface';
import { useModalStore } from '~/resources/modal/modal.store';

// #region Globals
const router = useRouter();
const route = useRoute();
const localePath = useLocalePath();
const modalStore = useModalStore();
const { globalModal, confirmModal } = storeToRefs(modalStore);
// #endregion

// #region GlobalModal
function closeGlobalModal() {
   router.replace(localePath({ fullPath: route.fullPath, query: route.query, hash: '' }));
   modalStore.deleteGlobalModal();
}

function finished() {
   if (!globalModal.value?.callback) return;

   globalModal.value.callback();
}
// #endregion
</script>

<template>
   <!-- Planning Request -->
   <SchedulePlanningRequestModal
      v-if="globalModal?.key === GlobalModalKey.PlanningRequest"
      v-bind="globalModal?.params"
      @on-finished="finished"
      @on-close="closeGlobalModal"
   />

   <!-- Input Availabilities -->
   <ScheduleWeekAvailabilitiesModal
      v-else-if="globalModal?.key === GlobalModalKey.InputAvailablities"
      v-bind="globalModal?.params"
      @on-finished="finished"
      @on-close="closeGlobalModal"
   />

   <!-- Person Todos -->
   <PersonTodoModal
      v-else-if="globalModal?.key === GlobalModalKey.PersonTodo"
      v-bind="globalModal?.params"
      @on-finished="finished"
      @on-close="closeGlobalModal"
   />

   <!-- Onboarding Modal -->
   <PersonTodoOnboardingModal
      v-else-if="globalModal?.key === GlobalModalKey.Onboarding"
      v-bind="globalModal?.params"
      @on-finished="finished"
      @on-close="closeGlobalModal"
   />

   <!--JobApply -->
   <JobsApplyModal v-else-if="globalModal?.key === GlobalModalKey.JobApply" v-bind="globalModal?.params" @on-close="closeGlobalModal" />

   <!-- Confirm Modal -->
   <ModalConfirm
      v-if="confirmModal"
      :title="confirmModal.title"
      :primary-button-text="confirmModal.confirmText"
      :primary-button-icon="confirmModal.confirmIcon"
      :secondary-button-text="confirmModal.cancelText"
      @on-confirm="
         () => {
            if (confirmModal?.confirmCallback) confirmModal.confirmCallback();
            modalStore.deleteConfirmModal();
         }
      "
      @on-cancel="
         () => {
            if (confirmModal?.cancelCallback) confirmModal.cancelCallback();
            modalStore.deleteConfirmModal();
         }
      "
   >
      {{ confirmModal.message }}
   </ModalConfirm>
</template>
