<script lang="ts" setup>
import ModalSide from '~/components/modal/Side.vue';
// #region Globals
const { t } = useI18n();
// #endregion

// #region Props & Emits
defineEmits<{ (e: 'onClose'): void }>();

const props = defineProps<{
   planningRequest?: PlanningRequest | null;
   loading?: boolean;
}>();
// #endregion

// #region Title
const title = computed(() => {
   if (props.loading) return t('generic.loading');

   if (props.planningRequest?.planning_request_state.key === PlanningRequestStateKey.OUTDATED) return t('schedules.status.outdated');
   if (props.planningRequest?.period_summary) return props.planningRequest?.period_summary;

   return t('generic.info');
});
// #endregion

// #region Scroll
const sideModalRef = ref<InstanceType<typeof ModalSide>>();
const cardMode = ref<'compact' | 'tiny'>('compact');
// #endregion

// #region Tabs
const activeTabKey = ref<string>('planned-hours');
// #endregion
</script>

<template>
   <ModalSide
      ref="sideModalRef"
      :title="title"
      :height="'var(--nxt-modal-layer-1)'"
      :width="'40rem'"
      @on-close="$emit('onClose')"
      @touch-up="cardMode = 'tiny'"
      @touch-down="cardMode = 'compact'"
   >
      <div :class="'day-details-modal'">
         <div class="day-details-modal__card">
            <slot name="card" v-bind="{ mode: cardMode }"></slot>
         </div>

         <LayoutTabs
            v-if="planningRequest?.squad?.street"
            v-model:active-tab-key="activeTabKey"
            :auto-height="false"
            :tabs="[
               { key: 'planned-hours', label: $t('generic.when') },
               { key: 'location', label: $t('generic.where') },
            ]"
         >
            <template #planned-hours>
               <slot name="planned-hours"></slot>
            </template>

            <template #location>
               <slot name="location"></slot>
            </template>
         </LayoutTabs>

         <slot v-else name="planned-hours"></slot>
      </div>

      <template v-if="$slots.actions && props.planningRequest?.planning_request_state.key !== PlanningRequestStateKey.OUTDATED" #actions>
         <slot name="actions"></slot>
      </template>

      <slot></slot>
   </ModalSide>
</template>

<style lang="scss" scoped>
.day-details-modal {
   display: flex;
   flex-direction: column;
   height: 100%;
   position: sticky;
   top: 0;
   overflow: auto;
}

.day-details-modal__card {
   position: sticky;
   background-color: var(--nxt-white);
   z-index: var(--zindex-sticky);
   top: 0;

   padding-bottom: var(--nxt-gutter-small);
}

.day-details-modal__tabs {
   flex: 1;
   transition: all 0.1s ease-in-out;
}
.day-details-modal__actions {
   display: flex;
   gap: var(--nxt-gutter-small);
   margin: 0 var(--nxt-gutter--negative);
   padding: var(--nxt-gutter);
   z-index: var(--zindex-sticky);
}
.day-details-modal__practical {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
   font-weight: var(--nxt-font-light);
}

.day-details-modal__customer {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
   font-weight: var(--nxt-font-light);
}

.day-details-modal__customer__title {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}
</style>
