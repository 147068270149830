import { useUserInfoStore } from '../user-info/user-info.store';

export const usePersonStore = defineStore('person', () => {
   const { changeUserPerson } = useUserInfoStore();
   const timout = ref<NodeJS.Timeout>();

   function patchPerson(person: Partial<Person>) {
      if (timout.value) clearTimeout(timout.value);

      return new Promise<Person | null>((resolve) => {
         timout.value = setTimeout(async () => {
            const response = await authFetch<Person>(`/person`, {
               method: 'PATCH',
               body: person,
            });

            if (response) {
               changeUserPerson(response);
            }

            resolve(response);
         }, 300);
      });
   }

   return { patchPerson };
});
