<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { MyWeekJobApplication } from '~/resources/job-application/job-application.interface';
import { GlobalModalKey } from '~/resources/modal/modal.interface';
import { TaskTypeKey } from '~/resources/my-week/my-week.interface';
import { useMyWeekStore } from '~/resources/my-week/my-week.store';

// #region Globals
const { t } = useI18n();
const dayjs = useDayjs();
const route = useRoute();
const router = useRouter();
const { tenantName, tenantKey, showPositions } = useBranding();
const myWeekStore = useMyWeekStore();

useSeoMeta({ title: t('my-week.my-week') });
definePageMeta({ keepalive: true, middleware: ['refresh-user-info'] });
useDefaultLayout(() => ({
   title: tenantName.value,
   fullNavigation: true,
   root: true,
}));
// #endregion

// #region Schedule
const { myWeekSchedule } = storeToRefs(myWeekStore);

onActivated(myWeekStore.refreshMyWeekSchedule);
// #endregion

// #region My Week
const { myWeek, loadingMyWeek } = storeToRefs(myWeekStore);
const today = computed(() => dayjs().format('YYYY-MM-DD'));
const hasTasks = computed(() => myWeek.value?.my_tasks?.length > 0);
const todaysSchedule = computed(() =>
   (myWeek.value?.my_planning ?? [])
      .filter((p) => (p.slots ?? []).find((s) => s.date === today.value))
      .sort((a, b) => a.must_be_handled_at.localeCompare(b.must_be_handled_at)),
);

onActivated(() => myWeekStore.refreshMyWeek());
// #endregion

// #region Availabilities
function openAvailabilities() {
   const planningRequestTodo = myWeek.value?.my_tasks?.find((task) => task.task_type_key === TaskTypeKey.PlanningRequest);
   if (planningRequestTodo) openPlanningRequestModal(planningRequestTodo.task);
   return openAvailabilityModal();
}

function openAvailabilityModal() {
   const nextWeekFormatted = dayjs().startOf('isoWeek').add(1, 'week').format('YYYY-MM-DD');

   const hash = `${GlobalModalKey.InputAvailablities}?date=${nextWeekFormatted}`;
   router.push(`${route.fullPath}${hash}`);
}

function openPlanningRequestModal(planningRequest: any) {
   const hash = `${GlobalModalKey.PlanningRequest}?id=${planningRequest.sync_id}`;
   router.push(`${route.fullPath}${hash}`);
}
// #endregion

// #region Pull to refresh
function onRefresh() {
   if (showPositions.value) {
      return Promise.all([
         myWeekStore.refreshMyWeek(),
         myWeekStore.refreshMyWeekSchedule(),
         positionStore.refetchMyPositions(),
         fetchApplications(),
      ]);
   }
   return Promise.all([myWeekStore.refreshMyWeek(), myWeekStore.refreshMyWeekSchedule()]);
}
// #endregion

// #region Jobs
const positionStore = usePositionStore();
const { myPositions: jobs, loadingMyPositions: loadingJobs } = storeToRefs(positionStore);

function removeJob(job: Position) {
   const index = jobs.value?.data?.findIndex((j) => j.sync_id === job.sync_id);
   if (index !== -1) jobs.value?.data?.splice(index, 1);
}
// #endregion

// #region Applied Positions
const { data: appliedPositions, execute: fetchApplications } = useAuthFetch<MyWeekJobApplication>(POSITION_ENDPOINTS.APPLIED_POSITIONS(), {
   immediate: false,
});

onActivated(() => {
   if (showPositions.value) {
      positionStore.refetchMyPositions();
      fetchApplications();
   }
});
// #endregion
</script>

<template>
   <div class="my-week">
      <div class="my-week__schedule">
         <ScheduleWeekCalendar
            :show-days-of-week="true"
            :schedule="myWeekSchedule"
            :week-start-date="$dayjs().startOf('isoWeek')"
            :week-end-date="$dayjs().endOf('isoWeek')"
            @on-change-active-date="$router.push('/schedule?active_date=' + $event)"
         />
      </div>

      <InteractablesPullToRefresh class="my-week__content" :on-refresh="onRefresh">
         <div class="my-week__sections">
            <MyWeekList
               :title="t('schedules.my-schedule')"
               :list-items="todaysSchedule"
               :count="todaysSchedule.length"
               :direction="'horizontal'"
               :loading="loadingMyWeek"
            >
               <template #default="{ listItem }">
                  <LazyMyWeekApprovedCard :planning-request="listItem" />
               </template>
               <template #no-results>
                  <LazyMyWeekNoPlanningCard :has-tasks="hasTasks" />
               </template>
            </MyWeekList>

            <MyWeekList
               :show-count="true"
               :title="t('my-week.my-tasks')"
               :list-items="myWeek?.my_tasks ?? []"
               :count="myWeek?.my_tasks?.length"
               :unique-key="'task.sync_id'"
               :direction="tenantKey === 'nxt-plan' ? 'vertical' : 'horizontal'"
               :loading="loadingMyWeek"
            >
               <template #default="{ listItem }">
                  <LazyMyWeekSentCard
                     v-if="listItem.task_type_key === TaskTypeKey.PlanningRequest"
                     :planning-request="listItem.task as PlanningRequest"
                  />

                  <LazyAvailabilityTodoCard
                     v-if="listItem.task_type_key === TaskTypeKey.AvailabilityTodo"
                     :class="'my-week__availability-todo-card'"
                     :availability-todo="listItem.task as AvailabilityTodo"
                  />

                  <LazyMyWeekPersonTodoCard
                     v-if="listItem.task_type_key === TaskTypeKey.PersonTodo"
                     :person-todo="listItem.task as PersonTodo"
                  />
               </template>
               <template #no-results>
                  <LazyMyWeekNoTasksCard @open-availabilities="openAvailabilities" />
               </template>
            </MyWeekList>

            <MyWeekList
               v-if="showPositions"
               :list-items="jobs?.data ?? []"
               :title="$t('jobs.interesting-jobs')"
               :unique-key="'sync_id'"
               :direction="'horizontal'"
               :show-count="true"
               :count="jobs?.data?.length"
               :loading="loadingJobs"
            >
               <template #default="{ listItem }">
                  <LazyMyWeekJobCard
                     :job="listItem"
                     :has-applied="!!appliedPositions?.[listItem.sync_id]"
                     @disliked="removeJob(listItem)"
                  />
               </template>

               <template #long-loading-message>
                  <span>{{ $t('my-week.refetching-my-positions') }}</span>
               </template>

               <template #no-results>
                  <LazyMyWeekNoJobsCard />
               </template>
            </MyWeekList>
         </div>
      </InteractablesPullToRefresh>
   </div>
</template>

<style lang="scss" scoped>
.my-week {
   height: 100%;
   width: 100%;
   overflow: auto;
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}
.my-week__content {
   display: flex;
   width: 100%;
   height: 100%;
   flex-direction: column;
   gap: var(--nxt-gutter);
   max-width: toRem(600);
   margin: 0 auto;
   padding-bottom: var(--nxt-navigation-height);
}

.my-week__schedule {
   position: sticky;
   top: 0;
   z-index: var(--zindex-sticky);
   box-shadow: 0px 3px 4px 0px #00000026;
   background-color: var(--nxt-white);
   padding: var(--nxt-gutter-small) var(--nxt-gutter);
   > * {
      max-width: toRem(600);
      margin: 0 auto;
   }
}

.my-week__my-schedule-title {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: var(--nxt-gutter);
   width: 100%;
}

.my-week__sections {
   display: flex;
   flex-direction: column;
   width: 100%;
   padding: 0 var(--nxt-gutter);
   gap: var(--nxt-gutter);
}

.my-week__availability-todo-card {
   height: 100%;
}

.my-week__quick-actions {
   position: fixed;
   bottom: calc(var(--nxt-navigation-height) + var(--nxt-gutter));
   left: var(--nxt-gutter);
   right: var(--nxt-gutter);
   z-index: var(--zindex-fixed);
   box-shadow: 0px 3px 4px 0px #0000001a;
}
</style>
