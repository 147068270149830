<script lang="ts" setup>
// #region Globals
defineExpose({ add: () => (showAddJobTypeModal.value = true) });
// #endregion

// #region Props & Emits
const modelValue = defineModel<Array<PersonProfileJobType>>('modelValue', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region JobTypes
function removeJobType(job_type_index: number) {
   modelValue.value.splice(job_type_index, 1);

   validate();
   touch();
}

function removeContractType(job_type_index: number, contract_type_index: number) {
   modelValue.value[job_type_index].contract_types?.splice(contract_type_index, 1);

   if (modelValue.value[job_type_index].contract_types!.length <= 0) {
      modelValue.value.splice(job_type_index, 1);
   }
   validate();
   touch();
}
// #endregion

// #region Create JobType
const showAddJobTypeModal = ref(false);

function createJobType(jobType: PersonProfileJobType) {
   // Check if job type already exists
   if (modelValue.value?.find((jt) => jt.job_type_id === jobType.job_type_id)) {
      showAddJobTypeModal.value = false;
      return;
   }
   modelValue.value.push(jobType);
   showAddJobTypeModal.value = false;

   validate();
   touch();
}
// #endregion

// #region Edit JobType
const showEditJobTypeModal = ref(false);
const jobTypeIndexToEdit = ref<number>(-1);

function changeJobType(jobType: PersonProfileJobType) {
   modelValue.value[jobTypeIndexToEdit.value] = jobType;
   showEditJobTypeModal.value = false;

   validate();
   touch();
}
// #endregion
</script>

<template>
   <div v-auto-animate class="person-todo-job-types">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <div v-if="modelValue.length <= 0" :class="'desired-job-types-modal__no-desired_job-types'">
         <p>
            {{ $t('generic.no-type-add-type', { type: $t('profile.job-preferences.job-types', 2)?.toLowerCase() }) }}
         </p>
      </div>

      <div v-else v-auto-animate class="person-todo-job-types__job-types">
         <div
            v-for="(profileJobType, jobTypeIndex) in modelValue || []"
            :key="profileJobType.job_type_id"
            class="person-todo-job-types__job-type"
         >
            <strong class="person-todo-job-types__job-type__category">{{ profileJobType.job_type?.name }}</strong>
            <ButtonIcon
               class="person-todo-job-types__job-type__delete"
               :icon-name="'trash'"
               :icon-style="{ color: 'var(--nxt-red)' }"
               :icon-label="$t('actions.delete-type', { type: $t('profile.job-preferences.job-types') })"
               @on-click="removeJobType(jobTypeIndex)"
            />
            <ButtonIcon
               class="person-todo-job-types__job-type__edit"
               :icon-name="'pen'"
               :icon-label="$t('actions.change-type', { type: $t('profile.job-preferences.job-types') })"
               @on-click="
                  () => {
                     jobTypeIndexToEdit = jobTypeIndex;
                     showEditJobTypeModal = true;
                  }
               "
            />
            <TransitionGroup
               v-if="profileJobType.contract_types?.length"
               name="list"
               tag="div"
               class="person-todo-job-types__job-type__tags"
            >
               <div
                  v-for="(contractType, contractTypeIndex) in profileJobType.contract_types || []"
                  :key="contractType.id"
                  class="person-todo-job-types__job-type__tag"
               >
                  <span>{{ contractType.name }}</span>
                  <ButtonIcon
                     :icon-name="'times'"
                     :icon-label="$t('actions.delete-type', { type: $t('profile.job-preferences.contract-types') })"
                     @on-click="removeContractType(jobTypeIndex, contractTypeIndex)"
                  />
               </div>
            </TransitionGroup>
            <div v-else class="person-todo-job-types__job-type__tags">
               <small>{{ $t('profile.job-preferences.no-contract-type-applicable') }}</small>
            </div>
         </div>
      </div>

      <ClientOnly>
         <!-- Create -->
         <Teleport to=".modal-side__card__content">
            <ProfileJobTypeForm v-if="showAddJobTypeModal" @on-save="createJobType" @on-cancel="showAddJobTypeModal = false" />
         </Teleport>

         <!-- Edit -->
         <Teleport to=".modal-side__card__content">
            <ProfileJobTypeForm
               v-if="showEditJobTypeModal"
               :initial-job-type="modelValue[jobTypeIndexToEdit]"
               @on-save="changeJobType"
               @on-cancel="
                  () => {
                     showEditJobTypeModal = false;
                     jobTypeIndexToEdit = -1;
                  }
               "
            />
         </Teleport>
      </ClientOnly>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-job-types {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}
.desired-job-types-modal__no-desired_job-types {
   text-align: center;
}

.person-todo-job-types__job-types {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);

   list-style: none;
   padding: 0;
   width: 100%;
}

.person-todo-job-types__job-type {
   display: grid;
   grid-template-columns: 1fr auto auto;
   grid-template-rows: auto 1fr;
   grid-template-areas: 'category edit delete' 'tags tags tags';

   &__category {
      grid-area: category;
   }

   &__delete {
      grid-area: delete;
   }

   &__edit {
      grid-area: edit;
   }

   &__tags {
      grid-area: tags;
      display: flex;
      flex-wrap: wrap;
      gap: toRem(4);
   }
}

.person-todo-job-types__job-type__tag {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
   border-radius: var(--nxt-radius);
   padding: 0 var(--nxt-gutter-small);
   background: var(--nxt-main-highlight);
}
</style>
