<script lang="ts" setup>
import { useTotalPerformedHours } from '~/pages/schedule/composables/useTotalPerformedHours';

// #region Globals
const dayjs = useDayjs();
// #endregion

// #region Props & Emits
const props = defineProps<{ planningRequestSlot: Slot; showApprovedHours?: boolean; showDeleteTimeRegistration?: boolean }>();
defineEmits<{
   onRemoveTimeRegistration: [];
}>();
const slot = toRef(props, 'planningRequestSlot');
// #endregion

// #region Slot Hours
const hours = computed(() => {
   const slotHours = slot.value.slot_hours ?? [];
   const performedHours = slot.value.performed_hours ?? [];
   const approvedHours = slot.value.approved_hours ?? [];

   // @ts-ignore
   const totalHours = slot.value.seconds_planned ? dayjs.duration(slot.value.seconds_planned, 'seconds').format('HH:mm:ss') : '';

   // @ts-ignore
   const totalApprovedHours = slot.value.seconds_approved ? dayjs.duration(slot.value.seconds_approved, 'seconds').format('HH:mm:ss') : '';

   const hasEndend = slot.value.performed_hours?.length
      ? !!slot.value.performed_hours[slot.value.performed_hours?.length - 1]?.end_time
      : true;

   return {
      slotHours,
      totalHours,
      performedHours,
      approvedHours,
      totalApprovedHours,
      hasEndend,
   };
});
const { totalPerformedHours } = useTotalPerformedHours(slot);
// #endregion
</script>

<template>
   <div class="slot">
      <PlanningRequestHours v-if="hours.slotHours.length" :icon="'fa-clock'" :hours="hours.slotHours" :total-hours="hours.totalHours" />

      <PlanningRequestHours
         v-if="hours.performedHours.length"
         :icon="'fa-stopwatch'"
         :hours="hours.performedHours"
         :total-hours="totalPerformedHours"
         :show-performed-hours="!hours.hasEndend"
         :show-delete-time-registration
         @on-remove-time-registration="$emit('onRemoveTimeRegistration')"
      />

      <PlanningRequestHours
         v-if="hours.approvedHours.length && showApprovedHours"
         :icon="'fa-check'"
         :total-hours="hours.totalApprovedHours"
         :hours="hours.approvedHours"
      />

      <i class="slot__date fa-solid fa-calendar-days"></i>
      <span class="slot__date">{{ $dayjs(slot.date).format('ddd DD MMM') }}</span>
      <span></span>
   </div>
</template>

<style lang="scss" scoped>
.slot {
   display: grid;
   grid-template-columns: auto 1fr auto;
   align-items: center;
   gap: toRem(4) var(--nxt-gutter-small);
}

.fa-calendar-days {
   display: flex;
   align-items: center;
   justify-content: center;
}

.slot__date {
   font-size: toRem(12);
   width: unset;
}
</style>
