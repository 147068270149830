import type { GlobalConfig, ValidationArgs } from '@vuelidate/core';
import useVuelidate from '@vuelidate/core';

export type UseFormParams<T> = {
   rules?: (data: T) => ValidationArgs<Partial<T>>;
   config?: GlobalConfig & {
      initialValues?: Partial<T>;
   };
};

export function useForm<T extends {}>(params: UseFormParams<T> = {}) {
   const { rules = () => ({}), config = {} } = params;

   // form data
   const data = ref({ ...config.initialValues }) as Ref<T>;
   provide('data', data);

   // form rules
   const computedRules = computed<{ [key: string]: ValidationArgs<T> }>(() => rules(data.value));
   const validation = useVuelidate(computedRules, data, config);
   provide('validation', validation);

   return { data, validation };
}
