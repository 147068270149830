import { defineStore } from 'pinia';

export const useAppStatusStore = defineStore('app-status', () => {
   const appStatuses = ref<Array<AppStatus>>([]);

   const hasAppStatus = computed(() => appStatuses.value.length > 0);

   function addAppStatus(status: AppStatus) {
      const oldStatusIndex = appStatuses.value.findIndex((s) => s.key === status.key);
      if (oldStatusIndex > -1) {
         appStatuses.value.splice(oldStatusIndex, 1);
      }

      const randomId = Math.random().toString(36).substring(7);
      const newStatus = { ...status, id: randomId };
      appStatuses.value.push(newStatus);

      return newStatus;
   }

   function removeAppStatus(id?: string) {
      appStatuses.value = appStatuses.value.filter((status) => status.id !== id);
   }

   function removeAppStatusesByKey(key: string) {
      appStatuses.value = appStatuses.value.filter((status) => status.key !== key);
   }

   function clearAppStatuses() {
      appStatuses.value = [];
   }

   return {
      appStatuses,
      hasAppStatus,
      removeAppStatus,
      removeAppStatusesByKey,
      addAppStatus,
      clearAppStatuses,
   };
});
