<script setup lang="ts">
import CircularLogo from './CircularLogo.vue';

type Logos = string | Array<string> | { logo: string; name: string }[];

const props = defineProps({
   logos: { type: [String, Array] as PropType<Logos>, default: null },
   alt: { type: String, default: null },
   size: { type: Number, default: 28 },
   loading: { type: Boolean, default: false },
   background: { type: String, default: null },
});

const allLogos = computed(() => {
   if (!props.logos) return [];
   if (typeof props.logos === 'string') return [props.logos];
   if (Array.isArray(props.logos)) return props.logos;
   return [];
});
</script>

<template>
   <div>
      <template v-for="(logo, index) in allLogos">
         <CircularLogo
            v-if="typeof logo === 'string'"
            :key="`${logo}_${index}`"
            :class="'layout-customer__logo'"
            :name="alt"
            :logo="logo"
            :width="size"
            :height="size"
            :background="background ? background : logo ? 'var(--nxt-white)' : 'var(--nxt-main)'"
            :color="logo ? 'var(--nxt-dark)' : 'var(--nxt-white)'"
            :border="logo ? '1px solid var(--nxt-light-grey)' : 'none'"
         />
         <CircularLogo
            v-else
            :key="`${logo?.logo}_${index}`"
            :class="'layout-customer__logo'"
            :name="logo?.name"
            :logo="logo?.logo"
            :width="size"
            :height="size"
            :background="background ? background : logo?.logo ? 'var(--nxt-white)' : 'var(--nxt-main)'"
            :color="logo?.logo ? 'var(--nxt-dark)' : 'var(--nxt-white)'"
            :border="logo?.logo ? '1px solid var(--nxt-light-grey)' : 'none'"
         />
      </template>
   </div>
</template>

<style lang="scss" scoped>
.layout-customer__logo {
   border-radius: 50%;
}

div {
   display: flex;
   align-items: center;

   > :nth-child(2) {
      margin-left: toRem(-10);
   }
}
</style>
