<script lang="ts" setup>
// #region Props & Emits
const props = defineProps<{
   question: PersonTodoQuestion;
}>();

const modelValue = defineModel<any>('modelValue', { default: null });

// #endregion

// #region Question Key
const questionKey = computed(() => props.question.key.split('.').pop());

if (['official_address', 'residential_address'].includes(questionKey.value as string) && !modelValue.value) {
   modelValue.value = {};
} else if (!modelValue.value && !['retired_since', 'profile_picture_url'].includes(questionKey.value as string)) {
   modelValue.value = modelValue.value = [];
}
// #endregion
</script>

<template>
   <!-- Social Security Number -->
   <PrecognitionFormElement v-if="questionKey === 'social_security_numbers'" :name="question.name" :label="question.name">
      <PrecognitionFormSsnInput v-model="modelValue" />
   </PrecognitionFormElement>

   <!-- Retirement -->
   <PersonTodoFormRetirement v-if="questionKey === 'retired_since'" v-model="modelValue" :question="question" />

   <!-- Address -->
   <PrecognitionFormAddress
      v-if="['official_address', 'residential_address'].includes(questionKey || '')"
      v-model="modelValue"
      :base-key="question.key"
   />

   <!-- Dependants -->
   <PersonTodoFormDependants v-if="questionKey === 'dependants'" v-model="modelValue" :base-key="question.key" />

   <!-- Transport methods -->
   <PersonTodoFormTransportMethods v-if="questionKey === 'transport_methods'" v-model="modelValue" :base-key="question.key" />

   <!-- Timetables -->
   <PersonTodoFormTimetables v-if="questionKey === 'timetables'" v-model="modelValue" :base-key="question.key" />

   <!-- Communication preferences -->
   <PersonTodoFormCommunicationPreferences
      v-if="questionKey === 'communication_preferences'"
      v-model="modelValue"
      :base-key="question.key"
   />

   <!-- Avatar -->
   <PersonTodoFormAvatar v-if="questionKey === 'profile_picture_url'" v-model="modelValue" />
</template>

<style lang="scss" scoped>
.address {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}
</style>
