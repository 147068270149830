<script lang="ts" setup>
defineProps<{ question: PersonTodoQuestion; resource?: any }>();
const modelValue = defineModel<any>('modelValue');
</script>

<template>
   <PrecognitionFormElement
      v-if="question.form_type !== PersonTodoFormType.Custom"
      :name="question.key"
      :label="question.name"
      :label-position="[PersonTodoFormType.Switch, PersonTodoFormType.Checkbox].includes(question.form_type) ? 'left' : 'top'"
   >
      <PrecognitionFormInput v-if="question.form_type === PersonTodoFormType.Text" v-model="modelValue" />

      <PrecognitionFormToggle v-if="question.form_type === PersonTodoFormType.Checkbox" v-model="modelValue" />

      <PrecognitionFormSwitch v-if="question.form_type === PersonTodoFormType.Switch" v-model="modelValue" />

      <PrecognitionFormDatepicker v-if="question.form_type === PersonTodoFormType.Date" v-model="modelValue" />

      <PrecognitionFormSelect
         v-if="question.form_type === PersonTodoFormType.Dropdown && question.resource_endpoint"
         v-model="modelValue"
         :options="resource"
         :search-type="'internal'"
      />

      <PrecognitionFormSelect
         v-if="question.form_type === PersonTodoFormType.DropdownSearch && question.resource_endpoint"
         v-model="modelValue"
         :options-to-load-initially="25"
         :search-type="'custom'"
         :search-url="`/${question.resource_endpoint}`"
      />

      <PrecognitionFormPhoneNumberInput v-if="question.form_type === PersonTodoFormType.PhoneNumber" v-model="modelValue" />
   </PrecognitionFormElement>

   <PersonTodoCustomQuestion v-if="question.form_type === PersonTodoFormType.Custom" v-model="modelValue" :question="question" />
</template>
