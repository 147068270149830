<script lang="ts" setup>
// #region Props
defineProps({
   height: { type: String, default: 'auto' },
   width: { type: String, default: '100%' },
   maxWidth: { type: String, default: '100%' },
   minWidth: { type: String, default: '0%' },

   darker: { type: Boolean, default: false },

   randomWidth: { type: Boolean, default: false },

   lines: { type: Number, default: 1 },
});
// #endregion

// #region Random Width
const localRandomWidth = useState<string>(`${Math.floor(Math.random() * (90 - 30)) + 30}`);
// #endregion
</script>

<template>
   <span
      v-for="line in lines"
      :key="line"
      :style="{ width: randomWidth ? localRandomWidth : width, maxWidth, minWidth, height }"
      :class="['skeleton', 'shine', { darker }]"
   >
      &nbsp;
   </span>
</template>

<style lang="scss">
.skeleton {
   border-radius: var(--nxt-radius-small);
   display: block;
   transition: width 0.2s ease-in-out;

   & + .skeleton {
      margin-top: toRem(4);
   }
}

.shine {
   background: #f6f7f8;
   background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
   background-repeat: no-repeat;
   background-size: 800px 104px;
   position: relative;

   animation-duration: 1.5s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-name: shimmer;
   animation-timing-function: linear;

   &.darker {
      background: #ecedee;
      background-image: linear-gradient(to right, #ecedee 0%, #dfdede 20%, #ecedee 40%, #ecedee 100%);
      background-repeat: no-repeat;
      background-size: 800px 104px;
   }
}

@keyframes shimmer {
   0% {
      background-position: -468px 0;
   }

   100% {
      background-position: 468px 0;
   }
}
</style>
