<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
const dayjs = useDayjs();
const { confirmDeleteType } = useModalStore();

defineExpose({ add: () => (showAddDriversLicenseModal.value = true) });
// #endregion

// #region Props & Emits
const profileDriversLicenses = defineModel<Array<PersonProfileDriversLicense>>('profileDriversLicenses', { default: [] });
const profileTransportMethods = defineModel<Array<PersonProfileTransportMethod>>('profileTransportMethods', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();

// #endregion

// #region Modals
const showAddDriversLicenseModal = ref(false);
const showChangeDriversLicenseModal = ref(false);
const driversLicenseIndexToChange = ref<number>(-1);

function openChangeDriversLicenseModal(index: number) {
   driversLicenseIndexToChange.value = index;
   showChangeDriversLicenseModal.value = true;
}

function closeChangeDriversLicenseModal() {
   driversLicenseIndexToChange.value = -1;
   showChangeDriversLicenseModal.value = false;
}

// #endregion

// #region Drivers Licenses
function addDriversLicense(driversLicense: PersonProfileDriversLicense) {
   const driversLicenseMatch = profileDriversLicenses.value?.find((l) => l.drivers_license_id === driversLicense.drivers_license_id);
   if (driversLicenseMatch) {
      const updatedDriverLicense = { ...driversLicenseMatch, ...driversLicense };
      const index = profileDriversLicenses.value?.findIndex((dl) => dl.drivers_license_id === driversLicense.drivers_license_id);
      if (!index) return;
      profileDriversLicenses.value?.splice(index, 1, updatedDriverLicense);
   } else {
      profileDriversLicenses.value?.push(driversLicense);
   }
   showAddDriversLicenseModal.value = false;
   validate();
   touch();
}

function changeDriversLicense(driversLicense: PersonProfileDriversLicense) {
   if (driversLicenseIndexToChange.value === -1) return;
   profileDriversLicenses.value![driversLicenseIndexToChange.value] = driversLicense;
   closeChangeDriversLicenseModal();
   validate();
   touch();
}

function removeDriversLicense(index: number) {
   confirmDeleteType(t('profile.skills.drivers-license'), () => {
      profileDriversLicenses.value?.splice(index, 1);
      validate();
      touch();
   });
}
// #endregion

// #region Transport Methods
const { data: transportMethods } = useAuthFetch<Array<TransportMethod>>(TRANSPORT_METHODS_ENDPOINTS.GET(), {});

const carTransportMethod = computed(() => transportMethods.value?.find((tm) => tm.key === TransportMethodKey.Car));

function toggleCarTransportMethod(value: boolean) {
   if (!carTransportMethod.value) return;
   if (value) {
      profileTransportMethods.value?.push({
         transport_method_id: carTransportMethod.value.id,
         transport_method: carTransportMethod.value,
      });
   } else {
      const index = profileTransportMethods.value?.findIndex((tm) => tm.transport_method_id === carTransportMethod.value?.id);
      profileTransportMethods.value?.splice(index, 1);
   }
}
// #endregion
</script>

<template>
   <div v-auto-animate class="person-todo-drivers-licenses">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <div v-if="profileDriversLicenses.length <= 0" :class="'person-todo-drivers-licenses__no-drivers-licenses'">
         <p>
            {{ $t('generic.no-type-add-type', { type: $t('profile.skills.drivers-license', 2)?.toLowerCase() }) }}
         </p>
      </div>

      <div v-else>
         <FormElement :name="'transport_methods'" :label="$t('profile.skills.i-have-own-car')" :label-position="'left'">
            <FormSwitch
               :model-value="!!profileTransportMethods.find((tm) => tm.transport_method?.key === TransportMethodKey.Car)"
               @update:model-value="toggleCarTransportMethod"
            />
         </FormElement>

         <div v-auto-animate class="person-todo-drivers-licenses__drivers-licenses">
            <LayoutCard
               v-for="(driversLicense, index) in profileDriversLicenses"
               :key="driversLicense.sync_id || driversLicense.drivers_license_id || index"
               class="person-todo-drivers-licenses__drivers-license"
            >
               <p class="person-todo-drivers-licenses__drivers-license__dates">
                  <small> {{ dayjs(driversLicense.valid_till).format('DD/MM/YYYY') }}</small>
               </p>

               <p class="person-todo-drivers-licenses__drivers-license__title">
                  <strong>{{ $t('profile.skills.drivers-license') }} {{ driversLicense.drivers_license?.name }}</strong>
               </p>

               <div class="person-todo-drivers-licenses__drivers-license__actions">
                  <ButtonIcon
                     :icon-name="'pen'"
                     :icon-label="$t('actions.change-type', { type: $t('profile.skills.drivers-license') })"
                     @on-click="openChangeDriversLicenseModal(index)"
                  />
                  <ButtonIcon
                     :icon-name="'trash'"
                     :icon-label="$t('actions.delete-type', { type: $t('profile.skills.drivers-license') })"
                     @on-click="removeDriversLicense(index)"
                  />
               </div>
            </LayoutCard>
         </div>
      </div>

      <ClientOnly>
         <!-- Create -->
         <Teleport to=".modal-side__card__content">
            <ProfileDriversLicenseForm
               v-if="showAddDriversLicenseModal"
               @on-save="addDriversLicense"
               @on-cancel="showAddDriversLicenseModal = false"
            />
         </Teleport>

         <!-- Change -->
         <Teleport to=".modal-side__card__content">
            <ProfileDriversLicenseForm
               v-if="showChangeDriversLicenseModal && driversLicenseIndexToChange > -1"
               :drivers-license="profileDriversLicenses[driversLicenseIndexToChange]"
               @on-save="changeDriversLicense"
               @on-cancel="closeChangeDriversLicenseModal"
            />
         </Teleport>
      </ClientOnly>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-drivers-licenses {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}

.person-todo-drivers-licenses__no-drivers-licenses {
   text-align: center;
}

.person-todo-drivers-licenses__drivers-licenses {
   margin-top: var(--nxt-gutter-large);
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);

   list-style: none;
   padding: 0;
}

.person-todo-drivers-licenses__drivers-license {
   display: grid;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto auto;
   grid-template-areas: 'dates actions' 'title actions';
   gap: var(--nxt-gutter-small) var(--nxt-gutter);

   &__dates {
      grid-area: dates;
      margin: 0;
   }

   &__title {
      grid-area: title;
      margin: 0;

      strong {
         margin-right: var(--nxt-gutter-small);
      }
   }

   &__actions {
      grid-area: actions;
      display: flex;
      gap: var(--nxt-gutter-small);
      justify-content: flex-end;
   }
}
</style>
