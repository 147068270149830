<script lang="ts" setup>
// #region Globals
const { getUserInfo } = useUserInfoStore();
const { userInfo } = storeToRefs(useUserInfoStore());
const person = computed(() => userInfo.value?.person);
const { patchPerson } = usePersonStore();
// #endregion

// #region person
const initials = computed(() => {
   const firstInitial = person.value?.first_name?.charAt(0) || '';
   const lastInitial = person.value?.last_name?.charAt(0) || '';

   return `${firstInitial}${lastInitial}`;
});
// #endregion

// #region Upload profile picture
const showCropper = ref(false);
const loading = ref(false);

function openCropper() {
   showCropper.value = true;
}

async function uploadProfilePicture(file: Blob) {
   const formData = new FormData();
   formData.append('profile_picture', file);

   await authFetch('/profile-picture', {
      method: 'post',
      body: formData,
      headers: {
         'Content-Type': 'multipart/form-data',
      },
   });
}

async function setProfilePicture(src: string | null) {
   try {
      loading.value = true;
      if (src) {
         const blob = await fetch(src).then((res) => res.blob());
         const file = new File([blob], 'profile_picture.png', { type: 'image/png' });
         const compressedFile = await changeImageResolution(file);
         await uploadProfilePicture(compressedFile);
      } else {
         await patchPerson({ profile_picture_url: null });
      }

      await getUserInfo();

      showCropper.value = false;
   } catch {
      console.warn('Failed to set profile picture');
   } finally {
      loading.value = false;
   }
}

defineExpose({ openCropper });
// #endregion
</script>

<template>
   <div class="avatar">
      <button class="avatar__image" @click="showCropper = true">
         <NuxtImg v-if="person?.profile_picture_url" :src="person.profile_picture_url" :height="100" :width="100" :alt="initials" />
         <div v-else>{{ initials }}</div>
      </button>

      <button class="avatar__edit" @click="showCropper = true">
         <i class="fa-solid fa-pen"></i>
      </button>

      <Teleport v-if="showCropper" to=".app">
         <ModalImageCropper
            :src="person?.profile_picture_url"
            :loading="loading"
            @on-close="showCropper = false"
            @on-save="setProfilePicture"
         />
      </Teleport>
   </div>
</template>

<style lang="scss" scoped>
.avatar {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   align-self: center;
   justify-self: center;
}
.avatar__input {
   height: 0;
   width: 0;
   border: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1;
}
.avatar__image {
   padding: 0;
   margin: 0;
   border: none;
   outline: none;
   height: toRem(100);
   width: toRem(100);
   border-radius: 50%;
   background-color: var(--nxt-light-grey);
   box-shadow: var(--nxt-shadow-small);

   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--nxt-dark);

   img {
      border-radius: 50%;
      object-fit: cover;
   }
}

.avatar__edit {
   position: absolute;
   bottom: 0;
   right: 0;
   background-color: var(--nxt-main);
   color: var(--nxt-white);
   border: none;
   outline: none;
   padding: var(--nxt-gutter-small);
   border-radius: 50%;
   cursor: pointer;
   box-shadow: var(--nxt-shadow-small);
   padding: 0;
   height: toRem(25);
   width: toRem(25);

   &:deep(i) {
      font-size: 0.8rem;
   }
}
</style>
