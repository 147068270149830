import { GlobalModalKey } from '~/resources/modal/modal.interface';
import { useModalStore } from '~/resources/modal/modal.store';

export default defineNuxtRouteMiddleware((to, from) => {
   if (process.server) return;

   const { addGlobalModal, deleteGlobalModal } = useModalStore();

   const hasMultipleHashes = to.hash.split('#').filter((hash) => !!hash);
   if (hasMultipleHashes.length > 1) {
      return navigateTo({ ...to, hash: `#${hasMultipleHashes[1]}` });
   }

   const [modalType, params] = to.hash.split('?');
   const [oldModalType] = from.hash.split('?');

   if (Object.values(GlobalModalKey).includes(oldModalType as GlobalModalKey)) {
      deleteGlobalModal();
   }

   if (Object.values(GlobalModalKey).includes(modalType as GlobalModalKey)) {
      const paramParts = params?.split('&')?.map((part) => part.split('='));

      addGlobalModal({
         key: modalType as GlobalModalKey,
         params: (paramParts || []).reduce(
            // TODO: Fix this
            // (acc, cur) => ({ ...acc, [cur[0]]: Number(cur[1]) !== undefined ? Number(cur[1]) : cur[1] }),
            (acc, cur) => ({ ...acc, [cur[0]]: cur[1] }),
            {},
         ),
      });
   }
});
