<script lang="ts" setup>
// #region Props & Emits
defineProps<{ baseKey: string }>();
const modelValue = defineModel<Array<PersonProfileTransportMethod>>('modelValue', { default: [] });
// #endregion

// #region TransportMethods
const { data: transportMethods } = await useAuthFetch<Array<TransportMethod>>(TRANSPORT_METHODS_ENDPOINTS.GET(), cacheConfig);
// #endregion

// #region Person profile transport methods
function toggleTransportMethod(transportMethod: TransportMethod) {
   const match = modelValue.value.find((ptm) => ptm.transport_method_id === transportMethod.id);
   if (match) {
      modelValue.value = modelValue.value.filter((ptm) => ptm.transport_method_id !== transportMethod.id);
   } else {
      modelValue.value.push({
         transport_method_id: transportMethod.id,
      });
   }
}

// #endregion
</script>

<template>
   <div class="person-todo-form-transport-methods">
      <PrecognitionFormElement
         v-for="(transportMethod, index) in transportMethods"
         :key="transportMethod.id"
         :name="`${baseKey}.${index}`"
         :label="transportMethod.name"
         :label-position="'left'"
      >
         <PrecognitionFormSwitch
            :model-value="!!modelValue.find((ptm) => ptm.transport_method_id === transportMethod.id)"
            @update:model-value="toggleTransportMethod(transportMethod)"
         />
      </PrecognitionFormElement>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-form-transport-methods {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}
</style>
