<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
const { confirmDeleteType } = useModalStore();

defineExpose({ add: () => (showAddJobDistanceModal.value = true) });
// #endregion
// #region Props & Emits
const modelValue = defineModel<Array<PersonJobDistance>>('modelValue', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region Add Job Distance Modal
const showAddJobDistanceModal = ref(false);
const showChangeJobDistanceModal = ref(false);
const jobDistanceIndexToChange = ref<number>();

function addJobDistance(jobDistance: PersonJobDistance) {
   modelValue.value.push({ ...jobDistance, radius: 35 });
   showAddJobDistanceModal.value = false;
   validate();
   touch();
}

function changeJobDistance(jobDistance: PersonJobDistance) {
   const oldJobDistance = modelValue.value[jobDistanceIndexToChange.value!];
   if (oldJobDistance.city_id !== jobDistance.city_id) {
      jobDistance.person_address_id = null;
   }
   if (jobDistanceIndexToChange.value !== -1) modelValue.value[jobDistanceIndexToChange.value!] = jobDistance;
   showChangeJobDistanceModal.value = false;
   validate();
   touch();
}

function deleteJobDistance(index: number) {
   confirmDeleteType(t('profile.job-preferences.work-region'), () => {
      modelValue.value?.splice(index, 1);
      validate();
      touch();
   });
}
// #endregion
</script>

<template>
   <div class="person-todo-form-job-distances">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <p>{{ $t('profile.job-preferences.where-would-you-like-to-work') }}</p>

      <div v-auto-animate class="person-todo-form-job-distances__job-distances">
         <div
            v-for="(jobDistance, index) in modelValue"
            :key="jobDistance.sync_id || jobDistance.city_id || index"
            class="person-todo-form-job-distances__job-distances__job-distance"
         >
            <div class="person-todo-form-job-distances__job-distances__tag">
               <span v-if="jobDistance.person_address?.person_address_type?.key === 'residential'">
                  {{ $t('profile.residential-address') }} ({{ jobDistance.city?.name }})
               </span>
               <span v-else-if="jobDistance.person_address?.person_address_type?.key === 'official'">
                  {{ $t('profile.official-address') }} ({{ jobDistance.city?.name }})
               </span>
               <span v-else>{{ jobDistance.city?.name }}</span>
               <ButtonIcon
                  :icon-name="'pen'"
                  :icon-label="$t('actions.change-type', { type: $t('profile.city') })"
                  @on-click="
                     () => {
                        jobDistanceIndexToChange = index;
                        showChangeJobDistanceModal = true;
                     }
                  "
               />
               <ButtonIcon
                  :icon-name="'trash'"
                  :icon-label="$t('actions.delete-type', { type: $t('profile.city') })"
                  @on-click="deleteJobDistance(index)"
               />
            </div>

            <PrecognitionFormElement :name="`profile.job_distances.${index}.radius`">
               <PrecognitionFormSlider v-model="jobDistance.radius" :min="5" :max="75" :unit="'km'" />
            </PrecognitionFormElement>
         </div>
      </div>

      <ClientOnly>
         <!-- Create -->
         <Teleport to=".modal-side__card__content">
            <ProfileJobDistanceForm v-if="showAddJobDistanceModal" @on-save="addJobDistance" @on-cancel="showAddJobDistanceModal = false" />
         </Teleport>

         <!-- Change -->
         <Teleport to=".modal-side__card__content">
            <ProfileJobDistanceForm
               v-if="showChangeJobDistanceModal"
               :initial-job-distance="modelValue[jobDistanceIndexToChange!]"
               @on-save="changeJobDistance"
               @on-cancel="
                  () => {
                     showChangeJobDistanceModal = false;
                     jobDistanceIndexToChange = undefined;
                  }
               "
            />
         </Teleport>
      </ClientOnly>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-form-job-distances__job-distances {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-large);
   margin-top: var(--nxt-gutter-large);
}

.person-todo-form-job-distances__job-distances__job-distance {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}

.person-todo-form-job-distances__job-distances__tag {
   background-color: var(--nxt-extralight-grey);
   display: grid;
   grid-template-columns: 1fr auto auto;
   align-items: center;
   gap: 0.5rem;
   padding: var(--nxt-gutter-small) var(--nxt-gutter);
   border-radius: var(--nxt-radius);
}
</style>
