<script lang="ts" setup>
const background = computed(() => defaultLayoutProps.value?.background);
const color = computed(() => defaultLayoutProps.value?.color);
const hideNavigation = computed(() => defaultLayoutProps.value?.hideNavigation);
const headerBackground = computed(() => defaultLayoutProps.value?.headerBackground);
const headerColor = computed(() => defaultLayoutProps.value?.headerColor);
const fullNavigation = computed(() => defaultLayoutProps.value?.fullNavigation);

// #region App status
const appStatusStore = useAppStatusStore();
const { hasAppStatus } = storeToRefs(appStatusStore);
// #endregion
</script>

<template>
   <div :class="['default-layout', { hideNavigation, hasAppStatus }]">
      <div class="default-layout__header-wrapper">
         <LayoutHeader :class="'default-layout__header'" v-bind="defaultLayoutProps">
            <template #pre-header>
               <div class="pre-header"></div>
            </template>
         </LayoutHeader>

         <div class="sub-header"></div>
      </div>

      <div class="default-layout__content">
         <slot></slot>
      </div>

      <LayoutNavigation v-show="!hideNavigation" :full-navigation="fullNavigation" class="default-layout__navigation" />
   </div>
</template>

<style lang="scss" scoped>
.default-layout {
   height: 100%;

   display: grid;
   grid-template-columns: 100%;
   grid-template-rows: auto 1fr auto;
   grid-template-areas:
      'header'
      'content'
      'navigation';

   background: v-bind(background);
   color: v-bind(color);

   @include tablet {
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr;
      grid-template-areas:
         'navigation header'
         'navigation content';
   }

   &:not(.hasAppStatus) {
      .default-layout__header {
         border-top: var(--device-padding-top) solid v-bind(headerBackground);
      }
   }
}

.default-layout__header-wrapper {
   grid-area: header;
   width: 100%;
   z-index: var(--zindex-sticky);
}

.default-layout__header {
   background: v-bind(headerBackground);

   color: v-bind(headerColor);
   width: 100%;

   transition: background-color 0.1s ease-in-out;

   @include tablet {
      background-color: v-bind(headerBackground);
      color: v-bind(headerColor);
   }
}

.default-layout__content {
   grid-area: content;

   height: 100%;
   width: 100%;
   overflow: hidden;
   margin: 0 auto;
}

.default-layout__navigation {
   grid-area: navigation;

   position: fixed;
   bottom: 0;
   padding-bottom: env(safe-area-inset-bottom, 0);
   left: 0;
   right: 0;
   z-index: var(--zindex-sticky);

   @include tablet {
      position: unset;
   }
}
</style>
