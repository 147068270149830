export const useModalStore = defineStore('modal', () => {
   const { $i18n } = useNuxtApp();
   const globalModal = ref<GlobalModalData>();
   const confirmModal = ref<ConfirmModal>();

   // #region Global Modal
   function addGlobalModal(globalModalData: GlobalModalData) {
      globalModal.value = { ...globalModalData };
   }

   function deleteGlobalModal() {
      globalModal.value = undefined;
   }

   function addGlobalModalCallback(key: string, callback: (data?: any) => void) {
      if (globalModal.value?.key === key) {
         globalModal.value.callback = callback;
      }
   }

   function deleteGlobalModalCallback(key: string) {
      if (globalModal.value?.key === key) {
         globalModal.value.callback = undefined;
      }
   }
   // #endregion

   // #region Confirm Modal
   function promptConfirmation(confirmModalData: ConfirmModal) {
      confirmModal.value = { ...confirmModalData };
   }

   function confirmDeleteType(type: string, onConfirm: () => void, onCancel?: () => void) {
      promptConfirmation({
         title: $i18n.t('actions.delete-type', { type }),
         message: $i18n.t('actions.delete-type-message', { type: type.toLowerCase() }),
         confirmText: $i18n.t('actions.yes-delete'),
         confirmIcon: 'trash',
         confirmCallback: onConfirm,
         cancelCallback: onCancel,
      });
   }

   function deleteConfirmModal() {
      confirmModal.value = undefined;
   }
   // #endregion

   return {
      globalModal,
      confirmModal,
      addGlobalModal,
      addGlobalModalCallback,
      deleteGlobalModal,
      deleteGlobalModalCallback,
      promptConfirmation,
      confirmDeleteType,
      deleteConfirmModal,
   };
});
