<script lang="ts" setup generic="T">
// #region props & emits
const props = defineProps({
   title: { type: String, default: '' },
   listItems: { type: Array as PropType<Array<T>>, default: null },
   uniqueKey: { type: String, default: 'task.sync_id' },
   loading: { type: Boolean, default: false },
   showCount: { type: Boolean, default: false },
   count: { type: Number, default: 0 },
   showMoreLink: { type: String, default: null },
   direction: { type: String as PropType<'horizontal' | 'vertical'>, default: 'vertical' },
});
// #endregion

// #region Long Loading Message
const showLongLoadingMessage = ref(false);
const longLoadingMessageTimeout = ref<NodeJS.Timeout>();

function activateLongLoadingMessage(loading: boolean) {
   if (longLoadingMessageTimeout.value) {
      clearTimeout(longLoadingMessageTimeout.value);
   }

   if (!loading) {
      showLongLoadingMessage.value = false;
      return;
   }

   longLoadingMessageTimeout.value = setTimeout(() => {
      showLongLoadingMessage.value = true;
   }, 1000);
}

watch(() => props.loading, activateLongLoadingMessage, { immediate: true });
// #endregion
</script>

<template>
   <div :class="['my-week-list', { '-has-results': !!count || loading }]">
      <div class="my-week-list__header">
         <div class="my-week-list__header__title">
            <slot name="title">
               <h2>{{ title }}</h2>
            </slot>
            <StatusBadge v-if="showCount && count" :badge-content="count" class="my-week-list__header__badge" />
         </div>
         <NuxtLink v-if="showMoreLink" :to="showMoreLink">{{ $t('actions.show-more') }}</NuxtLink>
      </div>

      <Transition name="fade">
         <slot v-if="showLongLoadingMessage" name="long-loading-message"> </slot>
      </Transition>

      <TransitionGroup name="list" tag="ul" :class="['my-week-list__list', direction]" mode="out-in">
         <template v-if="loading && !count">
            <li v-for="i in 2" :key="`loading-${i}`" class="my-week-list__list__item -loading">
               <StatusSkeleton :width="'100%'" :height="'100px'" />
            </li>
         </template>

         <template v-else-if="count">
            <li
               v-for="(listItem, index) in listItems"
               :key="getPropertyByPath(listItem, uniqueKey) || index"
               class="my-week-list__list__item"
            >
               <slot v-bind="{ listItem }"></slot>
            </li>
         </template>

         <template v-else>
            <li class="my-week-list__list__item">
               <slot name="no-results"></slot>
            </li>
         </template>
      </TransitionGroup>
   </div>
</template>

<style lang="scss">
.my-week-list {
   position: relative;
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}

.my-week-list__header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.my-week-list__header__title {
   display: flex;
   align-items: center;
   position: relative;
   gap: var(--nxt-gutter-small);
   width: 100%;

   h2 {
      margin: 0;
   }
}

.my-week-list__header__show-all {
   padding-right: 0;
   text-transform: lowercase;
}

.my-week-list__list {
   padding: 0;
   margin: 0;
   list-style: none;

   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);

   &.horizontal {
      flex-direction: row;
      overflow: auto;
      margin-left: var(--nxt-gutter--negative);
      margin-right: var(--nxt-gutter--negative);
      padding-left: var(--nxt-gutter);
      padding-right: var(--nxt-gutter);
      padding-bottom: 0;

      > * {
         width: 100%;
         min-width: 90%;
      }

      > * + * {
         max-width: 90%;
      }
   }

   &::-webkit-scrollbar {
      display: block;
      height: 0;
   }
}
.my-week-list__list__item {
   &.-loading {
      background-color: var(--nxt-extralight-grey);
      border-radius: var(--nxt-radius);
   }

   > * {
      height: 100%;
   }
}
</style>
