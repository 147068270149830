<script lang="ts" setup>
// #region Props & Emits
defineProps<{ baseKey: string }>();
const modelValue = defineModel<Array<PersonCommunicationPreference>>('modelValue', { default: [] });
// #endregion

// #region Communication preference types
const { data: communicationSubjects } = useAuthFetch<Array<CommunicationSubject>>(COMMUNICATION_SUBJECT_ENDPOINTS.GET());

const { data: communicationPreferenceTypes } = useAuthFetch<Array<CommunicationPreferenceType>>(
   COMMUNICATION_PREFERENCE_TYPE_ENDPOINTS.GET(),
);
// #endregion

// #region Methods
function toggleCommunicationPreference(communicationPreferenceType: CommunicationPreferenceType) {
   const communicationPreference = modelValue.value?.find((cp) => cp.communication_preference_type_id === communicationPreferenceType.id);
   if (communicationPreference) {
      modelValue.value = modelValue.value?.filter((cp) => cp.communication_preference_type_id !== communicationPreferenceType.id);
   } else {
      modelValue.value.push({
         communication_preference_type_id: communicationPreferenceType.id,
      });
   }
}
// #endregion
</script>

<template>
   <div :class="'person-todo-form-communication-preferences'">
      <section
         v-for="subject in communicationSubjects || []"
         :key="subject.key"
         class="person-todo-form-communication-preferences__section"
      >
         <strong v-if="subject.key !== 'job-alert'">
            {{ $t('profile.communication.preferred-communication-type', { type: subject.name?.toLowerCase() }) }}
         </strong>

         <strong v-else>{{ $t('profile.communication.preferred-job-alert') }}</strong>

         <PrecognitionFormElement
            v-for="communicationPreferenceType in communicationPreferenceTypes?.filter(
               (cpt) => cpt.communication_subject_id === subject.id,
            )"
            :key="communicationPreferenceType.id"
            :name="`communication-preference-${communicationPreferenceType.key}`"
            :label="communicationPreferenceType.communication_type?.name"
            :label-position="'left'"
         >
            <PrecognitionFormSwitch
               :model-value="!!modelValue.find((cp) => cp.communication_preference_type_id === communicationPreferenceType.id)"
               @update:model-value="toggleCommunicationPreference(communicationPreferenceType)"
            />
         </PrecognitionFormElement>
      </section>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-form-communication-preferences {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-large);
}

.person-todo-form-communication-preferences__section {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);

   > strong {
      margin-bottom: 1rem;
   }
}
</style>
