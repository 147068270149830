export function usePerformanceCodes(slotOrSlots: Ref<undefined | PlanningRequestSlot | Array<PlanningRequestSlot>>) {
   const allSlots = computed(() => {
      return Array.isArray(slotOrSlots.value) ? slotOrSlots.value : [slotOrSlots.value];
   });

   const allSlotHours = computed(() => {
      return allSlots.value.flatMap((s) => s?.slot_hours ?? []);
   });

   const allPerformedHours = computed(() => {
      return allSlots.value.flatMap((s) => s?.performed_hours ?? []);
   });

   const allApprovedHours = computed(() => {
      return allSlots.value.flatMap((s) => s?.approved_hours ?? []);
   });

   const slotHoursWithPerformanceCode = computed(() => {
      return allSlotHours.value.filter((sh) => sh.performance_code && sh.performance_code.key !== 'werkuren');
   });

   const performedHoursWithPerformanceCode = computed(() => {
      return allPerformedHours.value.filter((ph) => ph.performance_code && ph.performance_code?.key !== 'werkuren');
   });

   const approvedHoursWithPerformanceCode = computed(() => {
      return allApprovedHours.value.filter((ah) => ah.performance_code && ah.performance_code?.key !== 'werkuren');
   });

   const performanceCodes = computed(() => {
      const performanceCodes = [
         ...slotHoursWithPerformanceCode.value.map((sh) => sh.performance_code),
         ...performedHoursWithPerformanceCode.value.map((ph) => ph.performance_code),
         ...approvedHoursWithPerformanceCode.value.map((ah) => ah.performance_code),
      ];

      return (performanceCodes.filter((pc) => !!pc) as PerformanceCode[]).reduce<Array<PerformanceCode>>((acc, cur) => {
         if (acc.some((pc) => pc.key === cur.key)) {
            return acc;
         }

         return [...acc, cur];
      }, []);
   });

   return {
      slotHoursWithPerformanceCode,
      performedHoursWithPerformanceCode,
      approvedHoursWithPerformanceCode,
      performanceCodes,
   };
}
