<script lang="ts" setup>
import { UNAVAILABILITY_TYPE_ENDPOINTS } from '~/resources/unavailability-type/unavailability-type.endpoints';

// #region Props & Emits
defineEmits<{ (e: 'onCancel'): void; (e: 'onSave', data: Availability): void }>();

const props = defineProps({
   availability: { type: Object as PropType<Availability>, default: null },
});
// #endregion

// #region Unavailability Types
const { data: unavailabilityTypes } = useAuthFetch<Array<UnavailabilityType>>(UNAVAILABILITY_TYPE_ENDPOINTS.GET(), cacheConfig);
// #endregion

// #region Form Control
const { required } = useFormValidators();

const { data, validation } = useForm<Availability>({
   config: {
      initialValues: {
         ...props.availability,
      },
   },
   rules: () => ({
      unavailability_type_id: { required },
      unavailability_reason: {},
   }),
});

function setUnavailabilityType(unavailabilityType?: UnavailabilityType) {
   data.value.unavailability_type_id = unavailabilityType?.id;
   data.value.unavailability_type = unavailabilityType;

   if (unavailabilityType?.key !== UnavailabilityTypeKey.OTHER) {
      data.value.unavailability_reason = '';
   }
}
// #endregion
</script>

<template>
   <FormContainer :data="data" :validation="validation" @on-valid-submit="$emit('onSave', $event)">
      <ModalSide
         :title="$t('schedules.you-indicate-that-you-cant-work')"
         :action-background-color="'var(--nxt-white)'"
         :has-unsaved-changes="validation.$anyDirty"
         @on-close="$emit('onCancel')"
      >
         <div :class="'unavailable-form'">
            <FormElement :name="'unavailability_type_id'" :label="$t('schedules.unavailability-type-description')">
               <FormRadioButtonGroup
                  :model-value="data.unavailability_type_id"
                  :options="unavailabilityTypes || []"
                  :emit-object="true"
                  @update:model-value="setUnavailabilityType"
               />
            </FormElement>

            <FormElement
               v-if="data.unavailability_type?.key === UnavailabilityTypeKey.OTHER"
               :name="'unavailability_reason'"
               :label="$t('generic.reason')"
            >
               <FormTextarea v-model="data.unavailability_reason" :max-length="255" />
            </FormElement>
         </div>

         <template #actions>
            <ButtonMain :background-color="'var(--nxt-grey)'" :color="'var(--nxt-dark)'" :type="'button'" @on-click="$emit('onCancel')">
               {{ $t('actions.cancel') }}
            </ButtonMain>
            <ButtonMain :background-color="'var(--nxt-red)'" :color="'var(--nxt-white)'" :type="'submit'" :disabled="!validation.$anyDirty">
               {{ $t('actions.save') }}
            </ButtonMain>
         </template>
      </ModalSide>
   </FormContainer>
</template>

<style lang="scss" scoped>
.unavailable-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}
</style>
