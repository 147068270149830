<script lang="ts" setup>
import { vOnClickOutside } from '@vueuse/components';
import { FontColorContraster as vContrast } from '~/directives/color-contrast.directive';

// #region Props & Emits
const props = defineProps({
   backgroundColor: { type: String, default: 'var(--nxt-dark)' },
   overlap: { type: Boolean, default: true },
   outsideClick: { type: Boolean, default: true },
   clickable: { type: Boolean, default: true },
   initialVisible: { type: Boolean, default: false },
});

// #endregion

// #region show hover content
const isVisible = ref(props.initialVisible);

function hide() {
   isVisible.value = false;
}
function toggle() {
   isVisible.value = !isVisible.value;
}
// #endregion

// #region hover content position
const hoverCardItemRef = ref<HTMLDivElement | null>(null);
const contentRef = ref<HTMLDivElement | null>(null);

const position = ref<'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'>('bottom-right');

async function calculatePosition(isVisible: boolean) {
   if (!isVisible) return;

   await nextTick();
   if (!hoverCardItemRef.value || !contentRef.value) return;

   const { width, height, left, top } = hoverCardItemRef.value.getBoundingClientRect();
   const { width: contentWidth, height: contentHeight } = contentRef.value.getBoundingClientRect();
   const { innerHeight, innerWidth } = window;

   const spaceAvailable = {
      left: innerWidth - width - left,
      right: left + width,
      top,
      bottom: innerHeight - top - height,
   };

   const fits = {
      top: spaceAvailable.top > contentHeight,
      bottom: spaceAvailable.bottom > contentHeight,
      left: spaceAvailable.left > contentWidth,
      right: spaceAvailable.right > contentWidth,
   };

   if (fits.bottom && fits.right) {
      position.value = 'bottom-right';
   } else if (fits.bottom && fits.left) {
      position.value = 'bottom-left';
   } else if (fits.top && fits.right) {
      position.value = 'top-right';
   } else if (fits.top && fits.left) {
      position.value = 'top-left';
   } else {
      position.value = 'bottom-right';
   }
}

watch(isVisible, calculatePosition, { immediate: true });
// #endregion
</script>

<template>
   <div v-on-click-outside="() => outsideClick && hide()" :class="['hover-card', { visible: isVisible, '-no-overlap': !overlap }]">
      <div ref="hoverCardItemRef" v-contrast class="hover-card__hover-item" @click.stop="() => clickable && toggle()">
         <slot></slot>
      </div>

      <div v-if="overlap" v-show="isVisible" ref="contentRef" v-contrast :class="['hover-card__content', position]">
         <slot name="content"> </slot>
      </div>

      <div v-else-if="isVisible" ref="contentRef" v-contrast :class="['hover-card__content -no-overlap']">
         <slot name="content"> </slot>
      </div>
   </div>
</template>

<style lang="scss" scoped>
.hover-card {
   position: relative;
   display: inline-block;
   border-radius: var(--nxt-radius);
   display: flex;
   align-items: center;
   width: fit-content;
   background-color: v-bind(backgroundColor);
   color: var(--nxt-dark);

   &.visible:not(.-no-overlap) {
      z-index: var(--zindex-popover);

      .hover-card__hover-item {
         z-index: var(--zindex-popover);
      }
      .hover-card__content {
         box-shadow: var(--nxt-shadow);
      }
   }
}

.hover-card__hover-item {
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   color: var(--nxt-white);
   padding: toRem(4);

   &:deep(i) {
      width: unset;
   }
}

.hover-card__content {
   position: absolute;
   display: flex;
   align-items: center;
   background-color: v-bind(backgroundColor);
   border-radius: var(--nxt-radius);
   max-width: toRem(150);
   padding: toRem(10);

   &.bottom-left {
      top: 0;
      left: 0;
      padding-left: toRem(50);
   }

   &.bottom-right {
      top: 0;
      right: 0;
      padding-right: toRem(50);
   }

   &.top-left {
      bottom: 0;
      right: 0;
      padding-right: toRem(50);
   }

   &.top-right {
      bottom: 0;
      left: 0;
      padding-left: toRem(50);
   }

   &.-no-overlap {
      padding: toRem(4);
      position: relative;
   }
}
</style>
