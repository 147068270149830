import dayjs from 'dayjs';
import { PlanningKey } from '~/resources/schedule/planning/planning.interface';

export function useColorCode(planningRequest: Ref<PlanningRequest>, date: string = dayjs().format('YYYY-MM-DD')) {
   const planningRequestSlot = computed(() => {
      if (!date) return null;

      return planningRequest.value?.slots?.find((slot) => slot?.date === date);
   });

   const planningKey = computed(() => {
      if (planningRequestSlot.value) {
         return planningRequestSlot.value.slot_state?.key as PlanningKey;
      }

      return `${planningRequest.value.planning_request_type.key}-${planningRequest.value.planning_request_state.key}` as PlanningKey;
   });

   const colorCode = computed(() => getPlanningRequestColorCodes(planningKey.value));

   return {
      color: colorCode.value.color,
   };
}
