<script lang="ts" setup></script>

<template>
   <LayoutCard :class="'toggle-day-card'">
      <div class="toggle-day-card__day">
         <StatusSkeleton :width="'3rem'" />
         <StatusSkeleton :width="'3rem'" />
         <StatusSkeleton :width="'3rem'" />
      </div>
      <div class="toggle-day-card__hours">
         <StatusSkeleton :width="'1rem'" />
         <StatusSkeleton :width="'18rem'" />
      </div>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.toggle-day-card {
   font-weight: var(--nxt-font-light);
   display: grid;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto 1fr;
   grid-template-areas:
      'day switch'
      'hours switch';
   gap: var(--nxt-gutter-small);
}
.toggle-day-card__day {
   grid-area: day;
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}
.toggle-day-card__hours {
   grid-area: hours;
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}
</style>
