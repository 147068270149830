<script lang="ts" setup>
import type { LngLatLike } from 'maplibre-gl';
import { usePerformanceCodes } from '../../composables/usePerformanceCodes';

// #region Globals
const { openPhone, openMaps } = useLinks();
const dayjs = useDayjs();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();
// #endregion

// #region Props & Emits
const props = defineProps({
   planningRequest: { type: Object as PropType<PlanningRequest>, default: null },
   mode: { type: String as PropType<'full' | 'compact' | 'tiny'>, default: 'full' },
   danger: { type: Boolean, default: false },
   loading: { type: Boolean, default: false },
   date: { type: String, default: null },
   backgroundColor: { type: String, default: null },
});
// #endregion

// #region Styling
const planningRequest = toRef(props, 'planningRequest');
const { color } = useColorCode(planningRequest, props?.date || dayjs().format('YYYY-MM-DD'));

const contentRef = ref<HTMLDivElement | null>(null);
const heightInPx = ref('auto');

async function calculateHeight() {
   await nextTick();
   if (contentRef.value) {
      heightInPx.value = `calc(${contentRef.value.clientHeight}px + var(--nxt-gutter))`;
   }
}

watch(() => props.mode, calculateHeight, { immediate: true });
// #endregion

// #region Performance Code
const activeDateSlot = computed(() => {
   const activeDate = route.query.active_date as string;
   return planningRequest.value?.slots?.find((slot) => slot?.date === activeDate);
});
const todaySlot = computed(() => planningRequest.value?.slots?.find((slot) => slot?.date === dayjs().format('YYYY-MM-DD')));
const slot = computed(() => activeDateSlot.value || todaySlot.value);

const { performanceCodes } = usePerformanceCodes(slot);
// #endregion

// #region Show Time Registration
const showTimeRegistration = computed(() => {
   if (activeDateSlot.value) return activeDateSlot.value.date === dayjs().format('YYYY-MM-DD');
   return props.planningRequest?.slots?.find((s) => s?.date === dayjs().format('YYYY-MM-DD'));
});
// #endregion

// #region Actions
const maps = computed<null | { coords?: LngLatLike; address?: string }>(() => {
   if (!planningRequest.value.squad?.street) return null;

   const { longitude, latitude } = planningRequest.value.squad;

   return {
      coords: longitude && latitude ? { lat: latitude, lng: longitude } : undefined,
      address: `${planningRequest.value.squad.street} ${planningRequest.value.squad.house_number}, ${planningRequest.value.squad.city?.name}`,
   };
});

const actions = computed(() =>
   [
      {
         key: 'phone',
         icon: 'phone',
         label: t('actions.call'),
         action: () => openPhone(planningRequest.value.team_phone_number!),
         enabled: !!planningRequest.value?.team_phone_number,
      },
      {
         key: 'maps',
         icon: 'location-dot',
         label: t('actions.navigate'),
         action: () => openMaps(maps.value!),
         enabled: !!maps.value,
      },
      {
         key: 'time-registration',
         icon: 'qrcode',
         label: t('schedules.register-time'),
         action: () => router.push('/time-registration'),
         enabled: showTimeRegistration.value,
      },
   ].filter((a) => a.enabled),
);

// #endregion
</script>

<template>
   <LayoutCard
      v-if="loading"
      :class="['planning-approved-card', mode, { '-danger': danger, '-loading': loading }]"
      :accent-color="color"
      :background-color="backgroundColor"
   >
   </LayoutCard>
   <LayoutCard
      v-else-if="planningRequest"
      :class="['planning-approved-card', mode, { '-danger': danger, '-loading': loading }]"
      :accent-color="color"
      :background-color="backgroundColor"
   >
      <div ref="contentRef" class="planning-approved-card__content">
         <div class="planning-approved-card__heading">
            <small v-if="planningRequest.period_summary" class="planning-approved-card__greet">{{ planningRequest.period_summary }}.</small>

            <div v-if="performanceCodes?.length" class="planning-approved-card__status">
               <template v-for="(performanceCode, index) in performanceCodes.slice(0, 1)" :key="index">
                  <StatusHoverCard
                     v-if="performanceCode"
                     :overlap="false"
                     :background-color="performanceCode?.color"
                     :initial-visible="index === 0"
                     :outside-click="true"
                     :clickable="true"
                  >
                     <i :class="performanceCode.icon_key"></i>

                     <template #content>
                        {{ performanceCode.name }}
                     </template>
                  </StatusHoverCard>
               </template>
            </div>
         </div>

         <div class="planning-approved-card__header">
            <PlanningRequestHeader :planning-request="planningRequest" :date="$props.date" />
         </div>

         <div class="planning-approved-card__info">
            <small>{{ planningRequest.period }}</small>
            <small v-if="!planningRequest.is_dirty">
               <i class="fa-solid fa-clock"></i>
               <span>
                  {{ planningRequest.timetable_name }}
               </span>
            </small>

            <small class="planning-approved-card__info__properties">
               <span v-for="(property, index) in planningRequest.properties || []" :key="index">
                  <i :class="`fa-solid ${property.icon}`"></i>
                  <span>{{ property.name }}</span>
               </span>
            </small>

            <small v-if="planningRequest.job_address">
               <i class="fa-solid fa-location-dot"></i>
               <span>
                  {{ planningRequest.job_address }}
               </span>
            </small>
         </div>

         <div :class="['planning-approved-card__actions', { today: showTimeRegistration }]">
            <template v-for="action in actions" :key="action.key">
               <ButtonMain
                  v-if="actions.length <= 2"
                  :class="'planning-request-modal__actions__action'"
                  :background-color="'var(--nxt-dark)'"
                  :color="'var(--nxt-white)'"
                  :icon-name="action.icon"
                  :icon-position="'left'"
                  :icon-label="'navigeer'"
                  :icon-style="{ fontSize: '1.5rem' }"
                  @click.stop="action.action"
               >
                  {{ action.label }}
               </ButtonMain>
               <ButtonMain
                  v-else
                  :class="'planning-request-modal__actions__action'"
                  :background-color="'var(--nxt-dark)'"
                  :color="'var(--nxt-white)'"
                  :icon-name="action.icon"
                  :icon-position="'left'"
                  :icon-label="'navigeer'"
                  :icon-style="{ fontSize: '1.5rem' }"
                  @click.stop="action.action"
               />
            </template>
         </div>
      </div>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.planning-approved-card {
   color: var(--nxt-dark);
   position: relative;

   &.-danger {
      color: var(--nxt-dark);
   }
}

.planning-approved-card__content {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}

.planning-approved-card.compact {
   .planning-approved-card__actions,
   .planning-approved-card__heading {
      display: none;
   }
}

.planning-approved-card.tiny {
   .planning-approved-card__actions,
   .planning-approved-card__heading,
   .planning-approved-card__info p:not(:nth-child(1)) {
      display: none;
   }
}

.planning-approved-card__heading {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   gap: var(--nxt-gutter-small);
   width: 100%;
}

.planning-approved-card__header {
   display: grid;
   grid-template-columns: auto 1fr auto;
   grid-template-rows: auto auto;
   grid-template-areas:
      'logos customer wage'
      'logos customer wage-type';
   align-items: center;
   gap: 0 var(--nxt-gutter-small);

   &__logos {
      grid-area: logos;
   }
   &__customer {
      grid-area: customer;
   }
   &__wage {
      grid-area: wage;
   }
   &__wage-type {
      grid-area: wage-type;
      margin-top: -0.5rem;
      text-align: end;
   }
}

.planning-approved-card__greet {
   margin-bottom: var(--nxt-gutter-small);
   margin-right: toRem(32);
}

.planning-approved-card__status {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}

.planning-approved-card__info {
   display: flex;
   flex-direction: column;
   gap: toRem(4);

   p {
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
   }
   i {
      margin-right: toRem(4);
      width: toRem(16);
      text-align: center;
      font-size: var(--nxt-font-small);
   }
}

.planning-approved-card__info__properties {
   display: flex;
   flex-wrap: wrap;
   gap: var(--nxt-gutter-small);
}

.planning-approved-card__actions {
   display: flex;

   gap: var(--nxt-gutter-small);
}
</style>
