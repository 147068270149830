import type { Endpoints } from '~/models/endpoints.interface';

interface AvailabilityEndpoints extends Endpoints {
   POST_BATCH: () => string;
}

export const AVAILABILITY_ENDPOINTS: Pick<AvailabilityEndpoints, 'POST' | 'POST_BATCH'> = {
   POST: () => '/availabilities',
   POST_BATCH: () => '/availabilities-batch',
};
