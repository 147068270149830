import { useKeycloakStore } from '~/resources/keycloak.store';

export default defineNuxtPlugin(async () => {
   const appStore = useAppStore();

   const { online } = await appStore.checkIsOnline();

   if (online) {
      await useKeycloakStore().init();
   }
});
