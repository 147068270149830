<script lang="ts" setup>
import MultiSelect from '~/components/form/MultiSelect.vue';
import type { PersonProfileJobType } from '~/resources/person/person.interface';

// #region Props & Emits
defineEmits<{
   onSave: [data: PersonProfileJobType];
   onCancel: [];
}>();
const props = defineProps<{ initialJobType?: PersonProfileJobType }>();
// #endregion

// #region Form
const { required } = useFormValidators();

const { data, validation } = useForm<PersonProfileJobType>({
   config: {
      initialValues: props.initialJobType ? { ...props.initialJobType } : undefined,
   },
   rules: () => ({
      job_type_id: { required },
      job_type: {},
      contract_type_id: {},
   }),
});
// #endregion

// #region Methods
function updateJobType(jobType: JobType) {
   data.value.job_type_id = jobType.id;
   data.value.job_type = jobType;
}
// #endregion

// #region JobTypes
const jobTypeId = computed<number | undefined>(() => data.value.job_type_id);
const { data: jobTypes } = useAuthFetch<Array<JobType>>(JOB_TYPE_ENDPOINTS.GET());
// #endregion

// #region ContractTypes
const contractTypes = ref<Array<ContractType>>([]);

function clearContractTypes() {
   data.value.contract_types = [];
   contractTypes.value = [];
}

async function getJobTypes(jobTypeId?: number) {
   if (!props.initialJobType) clearContractTypes();

   if (!jobTypeId) return;
   const response = await authFetch<Array<ContractType>>(CONTRACT_TYPE_ENDPOINTS.GET(), { query: { job_type_id: jobTypeId } });
   contractTypes.value = response || [];
}

watch(jobTypeId, getJobTypes, { immediate: true });
// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="'add'"
      :data-type="$t('profile.job-preferences.desired-job-types', 1)"
      :data="data"
      :validation="validation"
      :height="'var(--nxt-modal-layer-3)'"
      @on-save="$emit('onSave', data)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-job-type-form'">
         <FormElement
            :name="'job_type_id'"
            :label="$t('actions.select-type', { type: $t('profile.job-preferences.job-types', 1) })"
            :label-style="'capitalize'"
            :disabled="!!initialJobType"
         >
            <FormSelect
               :model-value="data.job_type_id"
               :options="jobTypes || []"
               :search-type="'internal'"
               :emit-object="true"
               :label="$t('profile.job-preferences.job-types', 1)"
               @update:model-value="updateJobType"
            />
         </FormElement>

         <FormElement
            v-if="contractTypes.length"
            :name="'contract_type_id'"
            :label="$t('actions.select-type', { type: $t('profile.job-preferences.contract-types') })"
         >
            <MultiSelect
               v-model="data.contract_types"
               :options="contractTypes || []"
               :label="$t('profile.job-preferences.contract-types')"
            />
         </FormElement>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-job-type-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-job-type-form {
   display: flex;
   justify-content: space-between;
   gap: var(--nxt-gutter);
}
</style>
