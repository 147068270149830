import type { FetchContext } from 'ofetch';
import { useKeycloakStore } from '~/resources/keycloak.store';

type UseFetchType<T> = typeof $fetch<T>;
type UseFetchParams<T> = Parameters<UseFetchType<T>>;
type UseFetchOptions<T> = UseFetchParams<T>[1] & {
   // fix for bug: https://github.com/nuxt/nuxt/issues/19077
   method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'get' | 'post' | 'put' | 'delete' | 'patch';
   useCache?: boolean;
};

// #region helpers
function getErrorMessage(data: any) {
   const { $i18n } = useNuxtApp();
   if (data?.message?.message) return data.message.message;
   if (data?.message) return data.message;
   return $i18n.t('generic.server-error');
}

function checkApiVersion(newApiVersion: string) {
   const oldApiVersion = localStorage.getItem('api-version');

   if (oldApiVersion !== newApiVersion) {
      localStorage.setItem('api-version', newApiVersion);
      caches.delete('nxt-api-cache');
   }
}

function getIsStandaloneApp() {
   // @ts-ignore
   if (navigator.standalone !== undefined) return navigator.standalone;

   return window.matchMedia('(display-mode: standalone)').matches;
}

export function appendHeaders(context: FetchContext) {
   const { userInfo } = useUserInfoStore();
   const { tenantKey } = useBranding();

   const { $i18n } = useNuxtApp();

   const isFormData = (context?.options?.headers as Record<string, string>)?.['Content-Type'] === 'multipart/form-data';

   const headers: HeadersInit = {
      'Content-Type': 'application/json',
      'App-Domain': tenantKey.value,
      Accept: 'application/json',
      'Accept-Language': userInfo?.person?.communication_language?.iso_639_1?.toLowerCase() || $i18n?.locale?.value || 'nl',
      'Display-Mode': getIsStandaloneApp() ? 'standalone' : 'browser',
   };

   context.options.headers = { ...(context.options.headers as Record<string, string>), ...headers };

   if (isFormData) {
      delete (context?.options?.headers as Record<string, string>)?.['Content-Type'];
   }
}

export async function appendToken(context: FetchContext) {
   const keycloakStore = useKeycloakStore();
   const appStore = useAppStore();

   if (!keycloakStore.keycloak?.authenticated || appStore.onlineStatus.offline) return;

   try {
      const { getToken, waitOnKeycloak } = useKeycloakStore();
      await waitOnKeycloak();
      const token = await getToken();
      context.options.headers = { ...context.options.headers, Authorization: `Bearer ${token}` };
   } catch (e) {
      window.location.reload();
   }
}

// #endregion

// #region Cache Config
export const cacheConfig = { headers: { 'Cache-Control': 'force-cache' } };
export const noCacheConfig = { headers: { 'Cache-Control': 'no-cache' } };
// #endregion

// #region Fetch Utils
export function authFetch<T>(url: string, fetchOptions?: UseFetchOptions<T>) {
   if (process.server) return Promise.resolve(null);
   const { public: config } = useRuntimeConfig();

   const baseOptions = { baseURL: config.apiBaseUrl, lazy: true, server: false };
   const customOptions = {
      ...fetchOptions,
      onResponseError,
      onResponse,
      onRequest: async (context: FetchContext) => {
         await onRequest({ context, auth: true });
         await fetchOptions?.onRequest?.(context);
      },
   };
   const mergedOptions = { ...baseOptions, ...customOptions };

   return $fetch<T>(url, { ...mergedOptions });
}

export function publicFetch<T>(url: string, fetchOptions?: UseFetchOptions<T>) {
   if (process.server) return Promise.resolve(null);
   const { public: config } = useRuntimeConfig();

   const baseOptions = { baseURL: config.apiBaseUrl, lazy: true, server: false };
   const customOptions = { ...fetchOptions, onResponseError, onRequest: (context: FetchContext) => onRequest({ context, auth: false }) };
   const mergedOptions = { ...baseOptions, ...customOptions };

   return $fetch<T>(url, { ...mergedOptions });
}
// #endregion

// #region Interceptors
export async function onRequest(params: { context: FetchContext; auth?: boolean }) {
   const { context, auth = false } = params;

   // add basic headers
   appendHeaders(context);

   // add auth token
   if (auth) {
      await appendToken(context);
   }
}

export function onResponse(context: FetchContext) {
   const xApiVersion = context.response?.headers?.get('x-api-version');

   if (xApiVersion) {
      checkApiVersion(xApiVersion);
   }
}

export function onResponseError(context: FetchContext) {
   const { push } = useRouter();
   const { response } = context;
   const { addAppStatus } = useAppStatusStore();
   const { $i18n } = useNuxtApp();

   const unauthorized = response?.status === 401;
   const forbidden = response?.status === 403;
   const showErrorMessage = [422, 400, 409].includes(response?.status as number);

   if (unauthorized) {
      useKeycloakStore().logout();
   } else if (forbidden) {
      push('/not-allowed');
   } else if (showErrorMessage) {
      addAppStatus({
         type: 'error',
         title: getErrorMessage(context?.response?._data),
         delay: 10000,
         canClose: true,
         key: response?.status?.toString(),
      });
   } else {
      addAppStatus({ type: 'error', title: $i18n.t('generic.server-error'), delay: 5000, canClose: true, key: '500' });
   }
}
// #endregion
