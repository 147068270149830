<script lang="ts" setup>
import type { PersonProfileCertificate } from '~/resources/person/person.interface';

// #region Props & Emits
defineEmits<{
   (e: 'onSave', data: PersonProfileCertificate): void;
   (e: 'onCancel'): void;
}>();

const props = defineProps<{ certificate?: PersonProfileCertificate }>();
// #endregion

// #region Options
const { data: certificates } = useAuthFetch<Array<Certificate>>(CERTIFICATE_ENDPOINTS.GET());

const certificatesByCountry = computed(() => {
   const countries: Array<Country & { certificates: Array<Certificate> }> = [];
   certificates.value?.forEach((certificate) => {
      if (countries.find((c) => c.id === certificate.country_id)) {
         countries.find((c) => c.id === certificate.country_id)?.certificates.push(certificate);
      } else countries.push({ ...certificate.country, certificates: [certificate] });
   });
   return countries.sort((a) => {
      if (a.iso_code_2 === 'BE') return -1;
      return 1;
   });
});
// #endregion

// #region Form
const { required } = useFormValidators();

const { data, validation } = useForm<PersonProfileCertificate>({
   config: { initialValues: JSON.parse(JSON.stringify({ ...(props.certificate || {}) })) },
   rules: () => ({
      certificate_id: { required },
      from: {},
      till: {},
   }),
});

function setCertificate(certificate?: Certificate) {
   data.value.certificate_id = certificate?.id;
   data.value.certificate = certificate;
}
// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="certificate ? 'edit' : 'add'"
      :data-type="$t('profile.skills.certificates')"
      :data="data"
      :validation="validation"
      :height="'var(--nxt-modal-layer-3)'"
      @on-save="$emit('onSave', $event)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-certificate-form'">
         <FormElement :name="`certificate_id`" :label="$t('profile.skills.certificates')">
            <FormSelect
               :model-value="data.certificate_id"
               :options="certificatesByCountry || []"
               :search-type="'internal'"
               :group="{
                  groupValueKey: 'id',
                  groupLabelKey: 'name',
                  groupListKey: 'certificates',
               }"
               :emit-object="true"
               @update:model-value="setCertificate"
            />
         </FormElement>

         <div class="profile-certificate-form__dates">
            <FormElement :name="`from`" :label="$t('generic.from')">
               <FormDatepicker v-model="data.from" />
            </FormElement>
            <FormElement :name="`till`" :label="$t('generic.till')">
               <FormDatepicker v-model="data.till" />
            </FormElement>
         </div>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-certificate-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-certificate-form__dates {
   display: flex;
   gap: var(--nxt-gutter);

   > * {
      flex: 1;
   }
}
</style>
