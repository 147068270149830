<script lang="ts" setup>
// #region Globals
const dayjs = useDayjs();
// #endregion

// #region Props & emits
const emits = defineEmits<{
   (e: 'onCancel'): void;
   (e: 'onConfirm', value: Availability): void;
}>();
const props = defineProps({
   day: { type: Object as PropType<Availability>, default: null },
   hasPlanningRequest: { type: Boolean, default: false },
});
// #endregion

// #region Form
const { required, requiredIf, isUnique } = useFormValidators({ baseValidationPath: 'schedules.availabilities' });

const { data, validation } = useForm<Availability>({
   rules: (data) => ({
      is_available: { required },
      start_time: { requiredIf: requiredIf(() => !allDay.value && !!data.is_available), isUnique: isUnique(data.end_time) },
      end_time: { requiredIf: requiredIf(() => !allDay.value && !!data.is_available), isUnique: isUnique(data.start_time) },
      repetition_frequency: {
         is_two_weekly: { requiredIf: requiredIf(() => !!data.repeat) },
         end_date: { requiredIf: requiredIf(() => !!data.repeat) },
      },
   }),
   config: {
      initialValues: {
         ...props.day,
         is_available: props.day?.is_available,
         start_time: props.day?.start_time || undefined,
         end_time: props.day?.end_time || undefined,
         repetition_frequency: props.day?.repetition_frequency || undefined,
      },
   },
});
// #endregion

// #region All day<
const allDay = ref(!(props.day?.start_time && props.day?.end_time));

watch(allDay, handleAllDayChange);

function handleAllDayChange(allDay: boolean) {
   if (allDay) {
      data.value.start_time = undefined;
      data.value.end_time = undefined;
   }
}
// #endregion

// #region Repeat

watch(() => data.value.repeat, handleRepetitionChange);

function handleRepetitionChange(repeat?: boolean) {
   if (repeat) {
      data.value.repetition_frequency = {
         end_date: dayjs(props.day?.date).add(1, 'week').format('YYYY-MM-DD'),
         is_two_weekly: false,
      };
   } else {
      data.value.repetition_frequency = undefined;
   }
}
// #endregion

// #region Availability Change
const showUnavailabilityModal = ref(false);

function setAvailability(value?: boolean) {
   if (value === false && props.hasPlanningRequest) {
      return (showUnavailabilityModal.value = true);
   }

   if (value === false) {
      allDay.value = true;
   }

   data.value = {
      ...data.value,
      is_available: value,
      unavailability_reason: undefined,
      unavailability_type_id: undefined,
      unavailability_type: undefined,
      start_time: value === false ? undefined : data.value?.start_time,
      end_time: value === false ? undefined : data.value?.end_time,
   };
}

function applyUnavailability(availability: Availability) {
   data.value = {
      ...availability,
      is_available: false,
      start_time: undefined,
      end_time: undefined,
   };
   showUnavailabilityModal.value = false;
}

// #endregion
</script>

<template>
   <div>
      <FormContainer :data="data" :validation="validation" @on-valid-submit="emits('onConfirm', $event)">
         <ModalSide :title="$t('schedules.change-your-availability')" :width="'35rem'" @on-close="emits('onCancel')">
            <LayoutCard :background-color="'var(--nxt-extralight-grey)'">
               <div class="day-availability-form__date">
                  <p>
                     <span>{{ dayjs(day.date).format('dd DD/MM/YY') }}</span>
                     <i class="fa-solid fa-calander-day"></i>
                  </p>

                  <FormElement class="day-availability-form__date__toggle" :name="'available'">
                     <FormButtonToggle
                        :model-value="data?.is_available"
                        :partial="!!(data?.start_time && data?.end_time)"
                        @update:model-value="setAvailability"
                     />
                  </FormElement>
               </div>
            </LayoutCard>

            <div class="day-availability-form__main">
               <Transition name="fade">
                  <StatusNotification v-if="data.is_available === undefined" :type="'warning'">
                     {{ $t('schedules.indicate-ability-to-work') }}
                  </StatusNotification>
               </Transition>

               <FormElement :name="'all_day'" :label="$t('schedules.all-day')" label-position="left" :disabled="!data.is_available">
                  <FormSwitch v-model="allDay" />
               </FormElement>

               <div v-if="!allDay" class="day-availability-form__hours">
                  <FormElement name="start_time" :label="$t('schedules.start-hour')" class="day-availability-form__hour">
                     <FormTimepicker v-model="data.start_time" type="time" />
                  </FormElement>

                  <FormElement name="end_time" :label="$t('schedules.end-hour')" class="day-availability-form__hour">
                     <FormTimepicker v-model="data.end_time" type="time" />
                  </FormElement>
               </div>

               <FormElement name="repeat" :label="$t('schedules.repeat')" label-position="left" :disabled="data.is_available === undefined">
                  <FormSwitch :model-value="!!data.repeat" @update:model-value="data.repeat = $event" />
               </FormElement>

               <div v-if="!!data.repetition_frequency" class="day-availability-form__repetition">
                  <FormElement name="repetition_frequency.is_two_weekly" class="day-availability-form__repetition__dropdown">
                     <FormSelect
                        v-model="data.repetition_frequency.is_two_weekly"
                        :options="[
                           { name: $t('schedules.weekly'), value: false },
                           { name: $t('schedules.two-weekly'), value: true },
                        ]"
                        :value-key="'value'"
                     />
                  </FormElement>
                  <FormElement name="repetition_frequency.end_date" :label="$t('schedules.untill-till')" label-position="left">
                     <FormDatepicker
                        v-model="data.repetition_frequency.end_date"
                        :min="dayjs(day.date).add(1, 'day').format('YYYY-MM-DD')"
                     />
                  </FormElement>
               </div>
               <ClientOnly>
                  <ScheduleUnavailableForm
                     v-if="showUnavailabilityModal"
                     :availability="data"
                     @on-cancel="showUnavailabilityModal = false"
                     @on-save="applyUnavailability"
                  />
               </ClientOnly>
            </div>

            <template #actions>
               <ButtonMain background-color="var(--nxt-light-grey)" color="var(--nxt-dark)" @on-click="emits('onCancel')">
                  {{ $t('actions.cancel') }}
               </ButtonMain>
               <ButtonMain :icon-name="'check'" :background-color="'var(--nxt-plan-main)'" type="submit">
                  {{ $t('actions.apply') }}
               </ButtonMain>
            </template>
         </ModalSide>
      </FormContainer>
   </div>
</template>

<style lang="scss" scoped>
.day-availability-form__date {
   display: flex;
   align-items: center;
   justify-content: space-between;

   p {
      margin: unset;
   }
}

.day-availability-form__date__toggle {
   flex: 0;
}

.day-availability-form__main {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
   margin-top: var(--nxt-gutter);
   padding-right: var(--nxt-gutter);
   margin-right: var(--nxt-gutter-small--negative);
}

.day-availability-form__hours {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter);
}

.day-availability-form__hour {
   flex-grow: 1;
}
.day-availability-form__repetition {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter);
}

.day-availability-form__repetition__dropdown {
   flex-grow: 1;
}
</style>
