<script lang="ts" setup>
import type { SwiperWeek } from '~/models/swiper-schedule.interface';
import { useAvailabilityTodoStore } from '~/resources/availability-todo/availability-todo.store';

// #region globals
const { resetState } = useResetState();
const dayjs = useDayjs();
const scheduleStore = useScheduleStore();
const availabilityTodoStore = useAvailabilityTodoStore();
// #endregion

// #region Props & emits
const emits = defineEmits<{ (e: 'onClose'): void }>();

const props = defineProps({
   availabilityTodoId: { type: String, default: null },
   date: { type: String, default: null },
});
// #endregion

// #region Availability Todo
const loadingAvailabilityTodo = ref<boolean>(false);
const availabilityTodo = ref<AvailabilityTodo>();
const availabilityTodoPeriod = ref<SwiperWeek | null>(null);

async function initAvailabilityTodo() {
   if (!props.availabilityTodoId) return;

   loadingAvailabilityTodo.value = true;
   try {
      const response = await availabilityTodoStore.getAvailabilityTodo(props.availabilityTodoId);
      if (response) availabilityTodo.value = response;
   } catch {
      emits('onClose');
   } finally {
      setTimeout(() => {
         loadingAvailabilityTodo.value = false;
      }, 200);
   }
}

async function getAvailabilityTodoPeriod(availabilityTodo?: AvailabilityTodo) {
   if (!availabilityTodo) {
      return (availabilityTodoPeriod.value = null);
   }

   const start = dayjs(availabilityTodo?.start_date);
   const end = dayjs(availabilityTodo?.end_date);
   let schedule: undefined | Schedule;
   try {
      schedule = (await scheduleStore.getScheduleForPeriod(start, end)) ?? undefined;
   } catch {
      schedule = undefined;
   }

   availabilityTodoPeriod.value = { start, end, schedule };
}

watch(availabilityTodo, getAvailabilityTodoPeriod, { immediate: true });

onMounted(initAvailabilityTodo);
// #endregion

// #region weeks
const startDate = computed(() => dayjs(props.date));

const weeks = ref<Array<SwiperWeek>>(
   props.availabilityTodoId
      ? []
      : [
           { start: startDate.value.startOf('isoWeek').subtract(1, 'week'), end: startDate.value.endOf('isoWeek').subtract(1, 'week') },
           { start: startDate.value.startOf('isoWeek'), end: startDate.value.endOf('isoWeek') },
           { start: startDate.value.startOf('isoWeek').add(1, 'week'), end: startDate.value.endOf('isoWeek').add(1, 'week') },
        ],
);

provide('weeks', weeks);

async function getWeekSchedules(weeks: Array<SwiperWeek>) {
   const weeksToFetch = weeks.filter((week) => !week.loading && !week.schedule);
   return await Promise.all(
      weeksToFetch.map(async (week) => {
         try {
            week.loading = true;
            const schedule = await scheduleStore.getScheduleForPeriod(week.start, week.end);
            if (schedule) week.schedule = schedule;
         } finally {
            week.loading = false;
         }
      }),
   );
}

watch(weeks, getWeekSchedules, { immediate: true });
// #endregion

// #region Availability Todo Modal
function closeAvailabilityTodoModal() {
   resetState();
   emits('onClose');
}
// #endregion

// #region Update other weeks
function updateOtherWeeks() {
   weeks.value = weeks.value.map((week) => {
      return { ...week, schedule: undefined };
   });
}
// #endregion
</script>

<template>
   <ModalSide
      :class="'week-availabilities'"
      :title="availabilityTodo ? availabilityTodo.availability_question : $t('schedules.are-you-available-question')"
      :title-icon-name="'calendar-check'"
      :height="'fit-content'"
      :width="'40rem'"
      @on-close="closeAvailabilityTodoModal"
   >
      <Transition name="fade" :mode="'out-in'">
         <ScheduleAvailabilitySwiper
            v-if="!availabilityTodoId"
            @on-cancel="closeAvailabilityTodoModal"
            @update-other-weeks="updateOtherWeeks"
         />

         <StatusSpinner v-else-if="loadingAvailabilityTodo" class="week-availabilities__spinner" :size="'giant'" />

         <!-- Availability todo -->
         <ScheduleWeekAvailabilitiesForm
            v-else-if="availabilityTodoPeriod && availabilityTodo"
            :availability-todo="availabilityTodo"
            :period="availabilityTodoPeriod"
            :is-active-week="true"
            @on-close="closeAvailabilityTodoModal"
         />
      </Transition>
      <template #actions>
         <div class="week-availabilities__modal__actions"></div>
      </template>
   </ModalSide>
</template>

<style lang="scss" scoped>
.week-availabilities__modal__actions {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   gap: var(--nxt-gutter);
   width: 100%;
}

.week-availabilities__spinner {
   margin: 0 auto;
   margin-top: toRem(30);
   margin-bottom: toRem(30);
}
</style>
