<script lang="ts" setup>
// #region Globals
const { duoTone } = useBranding();
// #endregion

// #region Props & emits
defineProps<{
   to: string;
   iconName?: string;
   iconStyle?: string;
   label?: string;
   badgeCount?: number;
   dontHighlight?: boolean;
}>();

defineEmits<{ (e: 'onClick'): void }>();
// #endregion
</script>

<template>
   <NuxtLink :to="to" :class="'navigation-item'" :active-class="dontHighlight ? '' : '-active'">
      <div :class="['navigation-item__content', { '-duotone': duoTone }]">
         <slot>
            <i :class="`${iconStyle || 'fa-solid'} fa-${iconName}`"></i>
         </slot>
         <StatusBadge v-if="badgeCount" :badge-content="badgeCount" class="navigation-item__badge" />
      </div>

      <span :class="['navigation-item__label', { '-duotone': duoTone }]">{{ label }}</span>
   </NuxtLink>
</template>

<style lang="scss" scoped>
.navigation-item {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: var(--nxt-gutter-small);
}

.navigation-item.-active {
   .navigation-item__content {
      background-color: var(--nxt-main);
      border-color: var(--nxt-main);

      i {
         color: var(--nxt-white);
      }
   }

   .navigation-item__content.-duotone {
      background-color: var(--nxt-secondary);
      border-color: var(--nxt-secondary);

      i {
         color: var(--nxt-secondary-text);
      }
   }
}

.navigation-item__content {
   /* Box Model */
   width: toRem(45);
   height: toRem(45);
   padding: var(--nxt-gutter-small);
   border: 2px solid;
   border-color: var(--nxt-dark);
   border-radius: 50%;
   position: relative;

   /* Layout */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: var(--nxt-gutter-small);

   /* Color */
   background-color: var(--nxt-dark);
   color: var(--nxt-dark);

   /* Text */
   text-align: center;
   font-size: var(--nxt-font-small);

   transition: all 0.2s ease-in-out;

   &.-duotone {
      background-color: var(--nxt-main);
      border-color: var(--nxt-main);

      @include tablet {
         background-color: var(--nxt-white);
         border-color: var(--nxt-white);

         i {
            color: var(--nxt-dark);
         }
      }
   }

   i {
      font-size: 1.2rem;
      width: unset;
      color: var(--nxt-white);
      transition: all 0.2s ease-in-out;
   }
}

.navigation-item__label {
   font-size: toRem(10);
   margin: 0;
   text-decoration: none;
   text-align: center;
   line-height: 0.8rem;
   color: var(--nxt-dark);

   @include tablet {
      color: var(--nxt-dark);
      font-weight: bold;
   }

   &.-duotone {
      @include tablet {
         color: var(--nxt-white);
      }
   }
}

.navigation-item__badge {
   position: absolute;
   top: toRem(-2);
   right: toRem(-2);
   z-index: 1;
}
</style>
