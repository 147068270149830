import type { Endpoints } from '~/models/endpoints.interface';

export interface PositionEndpoints extends Pick<Endpoints, 'GET' | 'GET_ALL'> {
   APPLY: (positionId: string) => string;
   LIKE: (positionId: string) => string;
   UNLIKE: (positionId: string) => string;
   DISLIKE: (positionId: string) => string;
   GET_LIKED: () => string;

   MY_POSITIONS: () => string;
   APPLIED_POSITIONS: () => string;
}

export const POSITION_ENDPOINTS: PositionEndpoints = {
   GET_ALL: () => `/positions`,
   GET: (positionId: string) => `/positions/${positionId}`,
   APPLY: (positionId: string) => `/positions/${positionId}/apply`,
   LIKE: (positionId: string) => `/positions/${positionId}/like`,
   UNLIKE: (positionId: string) => `/positions/${positionId}/unlike`,
   DISLIKE: (positionId: string) => `/positions/${positionId}/dislike`,
   GET_LIKED: () => `/liked-positions`,

   MY_POSITIONS: () => `/my-positions`,
   APPLIED_POSITIONS: () => `/applied-positions`,
};
