import dayjs from 'dayjs';
import { createEvents, type EventAttributes } from 'ics';

export function useAddToCalendar(planningRequest: Ref<PlanningRequest>) {
   const { t } = useI18n();

   function handleDownload(filename: string, eventString: string) {
      const file = new File([eventString], filename, { type: 'text/calendar' });
      const url = URL.createObjectURL(file);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = filename;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(url);
   }

   function addToCalendar() {
      try {
         const slots = planningRequest.value?.slots ?? [];

         const isReserve = planningRequest.value.planning_request_type.key === PlanningRequestTypeKey.PlanningReserve;
         const title = `${isReserve ? `${t('schedules.reserve')}: ` : ''}${planningRequest.value.squad?.name || planningRequest.value.company_name}`;

         const allEvents = slots
            .map((slot) =>
               slot.slot_hours.map((sh) => ({ date: slot.date, ...sh })).filter((sh) => sh?.performance_code?.key === 'werkuren'),
            )
            .flat();

         const mappedEvents = allEvents.map((event) => {
            const [startHour, startMinute] = event.start_time.split(':');
            const [endHour, endMinute] = event.end_time!.split(':');

            const start = new Date(event.date);
            start.setHours(Number(startHour));
            start.setMinutes(Number(startMinute));

            const end = new Date(event.date);
            end.setHours(Number(endHour));
            end.setMinutes(Number(endMinute));

            const url = `${window.location.origin}/schedule?active_date=${event.date}#planning-request?id=${planningRequest.value.sync_id}`;

            const description = `${planningRequest.value.company_name}\n\n${planningRequest.value.properties
               .map((p) => `- ${p.name}`)
               .join('\n')}\n\n${t('actions.see-latest-status')}: ${url}`;

            const location = planningRequest.value.squad?.street
               ? `${planningRequest.value.squad.street} ${planningRequest.value.squad.house_number}, ${planningRequest.value.squad.city?.postal_code} ${planningRequest.value.squad.city?.name}`
               : undefined;

            const geo = planningRequest.value.squad?.latitude
               ? { lat: planningRequest.value.squad?.latitude || 0, lon: planningRequest.value.squad?.longitude || 0 }
               : undefined;

            return {
               start: [start.getFullYear(), start.getMonth() + 1, start.getDate(), start.getHours(), start.getMinutes()],
               end: [end.getFullYear(), end.getMonth() + 1, end.getDate(), end.getHours(), end.getMinutes()],
               title,

               description,
               location,
               geo,
               categories: ['NxtPlan', planningRequest.value.planning_request_type.name],
               url,
               uid: `${planningRequest.value.sync_id} - ${event.slot_id} - ${event.start_time} - ${event.end_time}`,
               calName: 'NxtPlan',
               sequence: dayjs().unix(),
            } as EventAttributes;
         });

         const events = createEvents(mappedEvents);
         if (!events.value) return;

         const filename = `NxtPlan - ${title} - ${dayjs().format('YYYY-MM-DD HH-mm-ss')}`;
         handleDownload(`${filename}.ics`, events.value);
      } catch (error) {
         useAppStatusStore().addAppStatus({ title: t('generic.unable-to-add-to-calendar'), type: 'error', canClose: true });
      }
   }

   return { addToCalendar };
}
