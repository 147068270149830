<script lang="ts" setup>
import { Vue3Lottie } from 'vue3-lottie';

const lottieRef = ref<InstanceType<typeof Vue3Lottie>>();

function play() {
   lottieRef.value?.play();
}

function stop() {
   lottieRef.value?.stop();
}

function goToAndStop(frame: number) {
   lottieRef.value?.goToAndStop(frame);
}

defineExpose({
   play,
   stop,
   goToAndStop,
});
</script>

<template>
   <Vue3Lottie ref="lottieRef" v-bind="$attrs" />
</template>
