<script lang="ts" setup>
const props = defineProps({
   planningRequest: { type: Object as PropType<PlanningRequest>, required: true },
});

const planningRequest = toRef(props, 'planningRequest');
</script>

<template>
   <LayoutLogoHeader
      :logos="planningRequest?.tenant?.logo"
      :title="planningRequest?.company_name"
      :sub-title="planningRequest?.job_title"
   />
</template>
