<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { SYSTEM_NOTIFICATIONS_ENDPOINTS } from './resources/system-notification/system-notification.endpoints';
import { useUserInfoStore } from './resources/user-info/user-info.store';

// #region Globals
const { locale, t, setLocale } = useI18n();
const route = useRoute();

const keycloakStore = useKeycloakStore();
const { keycloak } = storeToRefs(keycloakStore);
// #endregion

// #region Meta
const appStore = useAppStore();
const brandingStore = useBrandingStore();
const { onlineStatus } = storeToRefs(appStore);
const { fontUrl, tenantKey, appImageBasePath, tenantName } = useBranding();

watch([tenantKey, locale], brandingStore.updateStyling);

useHead({
   link: [
      { rel: 'manifest', href: `/api/manifest/${tenantKey.value}` },
      { rel: 'stylesheet', href: fontUrl.value },
      { rel: 'stylesheet', href: 'https://nxtpeople-cdn.s3.eu-central-1.amazonaws.com/fonts/RobotoMono_NXT/RobotoMono_NXT.css' },
      { rel: 'icon', href: `/${appImageBasePath.value}/favicon.ico` },
      { rel: 'apple-touch-icon', href: `/${appImageBasePath.value}/apple-touch-icon.png`, sizes: '180x180' },
      { rel: 'icon', href: `/${appImageBasePath.value}/favicon-32x32.png`, sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: `/${appImageBasePath.value}/favicon-16x16.png`, sizes: '16x16', type: 'image/png' },
      { rel: 'stylesheet', href: 'https://unpkg.com/maplibre-gl@latest/dist/maplibre-gl.css', fetchpriority: 'low' },
   ],
   meta: [
      { name: 'msapplication-TileColor', content: '#ffffff' },
      { name: 'msapplication-config', content: `/${appImageBasePath.value}/browserconfig.xml` },
   ],
   script: [
      { src: 'https://kit.fontawesome.com/71a3024446.js', crossorigin: 'anonymous', fetchpriority: 'high' },
      { src: 'https://unpkg.com/maplibre-gl@latest/dist/maplibre-gl.js', crossorigin: 'anonymous', fetchpriority: 'low' },
   ],
   titleTemplate: `%s - ${tenantName.value}`,
   htmlAttrs: { lang: locale.value },
});

useSeoMeta({
   viewport: `width=device-width, user-scalable=no, viewport-fit=cover, initial-scale=1`,
   title: `%s - ${tenantName.value}`,
   ogTitle: `%s - ${tenantName.value}`,
   twitterTitle: `%s - ${tenantName.value}`,
   description: t('generic.app-description', { tenantName: tenantName.value }),
   ogDescription: t('generic.app-description', { tenantName: tenantName.value }),
   twitterDescription: t('generic.app-description', { tenantName: tenantName.value }),
   ogImage: `/${appImageBasePath.value}/emblem.png`,
   twitterImage: `/${appImageBasePath.value}/emblem.png`,
   mobileWebAppCapable: 'yes',
   appleMobileWebAppCapable: 'yes',
});
// #endregion

// #region Locale
const dayjs = useDayjs();
const { planBaseUrl } = useRuntimeConfig().public;

const { userInfo } = storeToRefs(useUserInfoStore());

function userInfoChanged(userInfo: UserInfo | null) {
   if (!userInfo) return;

   if (userInfo.is_candidate_user === false) {
      window.location.replace(planBaseUrl);
   }

   let userLocale = userInfo?.person?.communication_language?.iso_639_1?.toLowerCase() || 'nl';
   const validLocales = ['nl', 'en', 'fr', 'de'];
   if (!validLocales.includes(userLocale)) {
      userLocale = 'en';
   }
   // @ts-ignore
   dayjs.locale(userLocale);
   // @ts-ignore
   dayjs.updateLocale(userLocale, { weekStart: 1 });
   setLocale(userLocale);
}

watch(userInfo, userInfoChanged);
// #endregion

// #region Status app
const appStatusStore = useAppStatusStore();

const { data: systemNotifications } = useAuthFetch<SystemNotification>(SYSTEM_NOTIFICATIONS_ENDPOINTS.GET());

watchEffect(() => {
   if (!systemNotifications.value || Object.keys(systemNotifications.value).length === 0) {
      appStatusStore.removeAppStatusesByKey('system-notification');
   } else {
      appStatusStore.addAppStatus({
         title: systemNotifications.value?.message,
         type: 'info',
         key: 'system-notification',
         canClose: false,
      });
   }
});
// #endregion

// #region Offline
function toggleNetworkBanner(isOnline: boolean) {
   if (isOnline) {
      appStatusStore.removeAppStatusesByKey('no-internet');
   } else {
      appStatusStore.addAppStatus({
         title: t('generic.status.offline'),
         type: 'warning',
         key: 'no-internet',
         canClose: false,
      });
   }
}

const { isOnline } = useNetwork();

async function handleNetworkStatusChange(online: boolean) {
   onlineStatus.value = { online, offline: !online };

   toggleNetworkBanner(online);

   // If we are online and not authenticated, we need to re-authenticate
   const isPublicPath = PUBLIC_ROUTE_NAMES.includes(route.name as string);
   if (online && !keycloak.value?.authenticated && !isPublicPath) {
      await keycloakStore.init();
      keycloakStore.login();
      resetState();
   }
}

watch(isOnline, handleNetworkStatusChange, { immediate: true });

// #endregion

// #region Focus Change
const visibilityState = useDocumentVisibility();
const { resetState } = useResetState();

function handleVisibilityChange(visibilityState: DocumentVisibilityState) {
   if (visibilityState === 'visible' && !PUBLIC_ROUTE_NAMES.includes(route.name as string)) {
      resetState();
   }
}

watch(visibilityState, handleVisibilityChange);
// #endregion

// #region App Status
const { appStatuses } = storeToRefs(appStatusStore);

const lastStatus = computed(() => {
   const pwaRefreshStatus = appStatuses.value.find((status: AppStatus) => status.key === 'pwa-needs-refresh');
   if (pwaRefreshStatus) {
      return pwaRefreshStatus;
   }
   return appStatuses.value[appStatuses.value.length - 1];
});
// #endregion

// #region Refresh content channel
if (typeof BroadcastChannel !== 'undefined') {
   const refreshContentChannel = new BroadcastChannel('refresh-content');

   refreshContentChannel.onmessage = (event) => {
      if (event.data.type === 'refresh-all') {
         resetState();
      }
   };
}
// #endregion
</script>

<template>
   <div class="app-wrapper">
      <StatusBanner
         v-if="lastStatus"
         :can-close="lastStatus?.canClose"
         :banner-click-action="lastStatus?.bannerClickAction"
         :title="lastStatus?.title"
         :delay="lastStatus?.delay"
         :type="lastStatus?.type"
         @on-close="appStatusStore.removeAppStatus(lastStatus?.id)"
      />
      <div class="app">
         <NuxtPwaManifest />
         <NuxtLayout>
            <NuxtPage />
         </NuxtLayout>
         <ModalGlobals v-if="userInfo" />
      </div>
   </div>
</template>

<style lang="scss">
:root {
   --device-padding-top: env(safe-area-inset-top);
   --device-padding-bottom: env(safe-area-inset-bottom);
}
.app-wrapper {
   height: 100%;
   width: 100vw;
   display: flex;
   flex-direction: column;
   overflow: hidden;
}
.app {
   position: relative;
   height: 100%;
   overflow: hidden;
}
</style>
