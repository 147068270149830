<script lang="ts" setup>
import { COMPETENCE_ENDPOINTS } from '~/resources/competence/competence.endpoints';
import { SCORE_ENDPOINTS } from '~/resources/score/score.endpoints';

// #region Props & Emits
defineEmits<{
   (e: 'onSave', data: PersonProfileCompetence): void;
   (e: 'onCancel'): void;
}>();

const props = defineProps<{ competence?: PersonProfileCompetence }>();
// #endregion

// #region Score
const { data: scores } = await useAuthFetch<Array<Score>>(SCORE_ENDPOINTS.GET(), { lazy: false });
// #endregion

// #region competences
const { data: competences } = await useAuthFetch<Array<Competence>>(COMPETENCE_ENDPOINTS.GET(), { lazy: false });

const competenceCategories = computed(() => {
   const categories: Array<CompetenceCategory & { competences: Array<Competence> }> = [];

   competences.value.forEach((competence) => {
      const match = categories.find((category) => category.id === competence.competence_category_id);
      if (match) {
         match.competences.push(competence);
      } else {
         categories.push({ ...competence.competence_category, competences: [competence] });
      }
   });

   return categories;
});
// #endregion

// #region Form
const { required } = useFormValidators();

const { data, validation } = useForm<PersonProfileCompetence>({
   config: { initialValues: JSON.parse(JSON.stringify({ ...(props.competence || { score_id: scores.value?.at(2)?.id }) })) },
   rules: () => ({
      competence_id: { required },
      score_id: { required },
   }),
});
// #endregion

// #region Methods
function updateScore(value: number) {
   const score = scores.value?.find((s) => s.value === value);
   if (!score) return;
   data.value.score_id = score.id;
   data.value.score = score;
}

// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="competence ? 'edit' : 'add'"
      :data-type="$t('profile.skills.skill')"
      :data="data"
      :validation="validation"
      :height="'var(--nxt-modal-layer-3)'"
      @on-save="$emit('onSave', data)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-competence-form'">
         <FormElement :name="'competence_id'" :label="$t('actions.select-type', { type: $t('profile.skills.skill') })">
            <FormSelect
               :model-value="data.competence_id"
               :emit-object="true"
               :options="competenceCategories"
               :search-type="'internal'"
               :group="{ groupLabelKey: 'name', groupListKey: 'competences', groupValueKey: 'id' }"
               @update:model-value="
                  (competence) => {
                     data.competence_id = competence.id;
                     data.competence = competence;
                  }
               "
            />
         </FormElement>

         <FormElement :name="'level'" :label="$t('actions.decide-level')">
            <FormSlider
               :model-value="scores?.find((s) => s.id === data.score_id)?.value"
               :min="0"
               :max="4"
               :step="1"
               :show-value="true"
               @update:local-value="updateScore"
            />
         </FormElement>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-competence-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-competence-form {
   display: flex;
   justify-content: space-between;
   gap: var(--nxt-gutter);
}
</style>
