export enum TaskTypeKey {
   PlanningRequest = 'planning_request',
   AvailabilityTodo = 'availability_todo',
   PersonTodo = 'person_todo',
}
export interface Task {
   task_type_key: TaskTypeKey;
   task: PlanningRequest | AvailabilityTodo | PersonTodo;
}
export interface MyWeek {
   my_planning: PlanningRequest[];
   my_tasks: Array<Task>;
}
