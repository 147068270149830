export enum PlanningRequestStateKey {
   SENT = 'sent',
   APPROVED = 'approved',
   REFUSED = 'refused',
   PARTIALLY = 'partially',
   UNANSWERED = 'unanswered',
   OUTDATED = 'outdated',
}

export interface PlanningRequestState {
   id: number;
   key: PlanningRequestStateKey;
   name: string;
   description: string;
   is_handled: boolean;
}
