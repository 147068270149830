import * as validators from '@vuelidate/validators';

export function useFormValidators(formValidatorParams: { baseValidationPath?: string } = {}) {
   const { baseValidationPath } = formValidatorParams;
   const i18n = useI18n();
   const dayjs = useDayjs();

   // #region Helpers
   const messageParams = (data: any) => {
      const propertyValidation = i18n.te(`${baseValidationPath}.${data.property}`)
         ? i18n.t(`${baseValidationPath}.${data.property}`)
         : null;

      const translation = i18n.te(`validations.${data.validator}`)
         ? i18n.t(`validations.${data.validator}`, { ...data, property: propertyValidation || data.property || '' })
         : null;

      if (translation) return { ...data, property: i18n.t(`${baseValidationPath}.${data.property}`) };

      return { ...data };
   };
   // #endregion

   // #region Custom validators
   const containsNumber = (value: string) => !!value.match(/\d/u);
   const containsLowercase = (value: string) => !!value.match(/\p{Ll}/u);
   const containsUppercase = (value: string) => !!value.match(/\p{Lu}/u);
   const containsSymbol = (value: string) => !!value.match(/[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/u);

   const vailidEmail = {
      $validator: (value: string) => {
         if (!validators.helpers.req(value)) return true;
         return !!value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      },
      $message: () => i18n.t('validations.email'),
   };
   const unique = (compareTo?: string) =>
      validators.helpers.withParams({ compareTo }, (value: string) => {
         if (!validators.helpers.req(value)) return true;
         return value !== compareTo;
      });

   const isAfterMinDate = (minDate: string) =>
      validators.helpers.withParams({ minDate }, (value: string) => {
         if (!validators.helpers.req(value)) return true;
         const min = dayjs(minDate);
         const date = dayjs(value);
         return !!date.isSameOrAfter(min, 'day');
      });

   const isBeforeMaxDate = (maxDate: string) =>
      validators.helpers.withParams({ maxDate }, (value: string) => {
         if (!validators.helpers.req(value)) return true;
         const max = dayjs(maxDate);
         const date = dayjs(value);

         return !!date.isSameOrBefore(max, 'day');
      });

   const isAfterMinTime = (minTime: string = '') =>
      validators.helpers.withParams({ minTime }, (value: string) => {
         if (!validators.helpers.req(value)) return true;
         const [hours, minutes] = minTime?.split(':');
         const [valueHours, valueMinutes] = value.split(':');
         return valueHours > hours || (valueHours === hours && valueMinutes >= minutes);
      });

   const isBeforeMaxTime = (maxTime: string = '') =>
      validators.helpers.withParams({ maxTime }, (value: string) => {
         if (!validators.helpers.req(value)) return true;
         const [hours, minutes] = maxTime?.split(':');
         const [valueHours, valueMinutes] = value.split(':');
         return valueHours < hours || (valueHours === hours && valueMinutes <= minutes);
      });

   const emailUsedValidator = (endpoint: string) =>
      validators.helpers.withAsync(
         validators.helpers.withParams({ endpoint }, (email: string) => {
            if (!validators.helpers.req(email)) return true;
            if (email === '') return Promise.resolve(true);
            if (!vailidEmail.$validator(email)) return Promise.resolve(true);

            return publicFetch<boolean>(endpoint, { method: 'post', body: { email } })
               .then(() => true)
               .catch((e) => e.status !== 422);
         }),
      );

   // #endregion

   // #region Translation helpers
   const withI18nMessage = validators.createI18nMessage({ t: i18n.t.bind(i18n) });

   const email = withI18nMessage(vailidEmail, { messageParams, withArguments: true });
   const emailUsed = withI18nMessage(emailUsedValidator, { withArguments: true });
   const lowercase = withI18nMessage(containsLowercase, { messageParams });
   const maxDate = withI18nMessage(isBeforeMaxDate, { messageParams, withArguments: true });
   const maxLength = withI18nMessage(validators.maxLength, { messageParams, withArguments: true });
   const minDate = withI18nMessage(isAfterMinDate, { messageParams, withArguments: true });
   const minLength = withI18nMessage(validators.minLength, { messageParams, withArguments: true });
   const numbers = withI18nMessage(containsNumber, { messageParams });
   const required = withI18nMessage(validators.required, { messageParams });
   const requiredIf = withI18nMessage(validators.requiredIf, { messageParams, withArguments: true });
   const sameAs = withI18nMessage(validators.sameAs, { messageParams, withArguments: true });
   const samePasswords = withI18nMessage(validators.sameAs, { withArguments: true });
   const symbols = withI18nMessage(containsSymbol, { messageParams });
   const uppercase = withI18nMessage(containsUppercase, { messageParams });
   const maxTime = withI18nMessage(isBeforeMaxTime, { messageParams, withArguments: true });
   const minTime = withI18nMessage(isAfterMinTime, { messageParams, withArguments: true });
   const minValue = withI18nMessage(validators.minValue, { messageParams, withArguments: true });
   const maxValue = withI18nMessage(validators.maxValue, { messageParams, withArguments: true });
   const isUnique = withI18nMessage(unique, { messageParams, withArguments: true });
   // #endregion

   return {
      email,
      emailUsed,
      lowercase,
      maxDate,
      maxLength,
      minDate,
      minLength,
      numbers,
      required,
      requiredIf,
      sameAs,
      samePasswords,
      symbols,
      uppercase,
      maxTime,
      minTime,
      minValue,
      maxValue,
      isUnique,
   };
}
