<script lang="ts" setup>
import { PlanningRequestStateKey } from '~/resources/planning-request-state/planning-request-state.interface';
import { usePlanningRequestStore } from '~/resources/planning-request/planning-request.store';

// #region Globals
const { resetState } = useResetState();
const dayjs = useDayjs();
// #endregion

// #region Props & Emits
const props = defineProps({
   planningRequest: { type: Object as PropType<PlanningRequest>, default: null },
   mode: { type: String as PropType<'full' | 'compact' | 'tiny'>, default: 'full' },
   loading: { type: Boolean, default: false },
   date: { type: String, default: null },
   isPassedDeadline: { type: Boolean, default: false },
});

const emits = defineEmits(['on-close']);

defineExpose({
   confirm,
   refuse,
});
// #endregion

// #region Planning Request Status
const showCantWorkModal = ref(false);
const planningRequestStore = usePlanningRequestStore();

const showActions = computed(() => {
   if (props.isPassedDeadline) return false;
   return !dayjs().isAfter(dayjs(props.planningRequest.must_be_handled_at), 'day');
});

async function refusePlanningRequest() {
   try {
      await planningRequestStore.changePlanningRequestState(props.planningRequest.sync_id, PlanningRequestStateKey.REFUSED);
      resetState();
      showCantWorkModal.value = false;
      emits('on-close');
   } catch {
      showCantWorkModal.value = false;
   }
}

function refuse() {
   showCantWorkModal.value = true;
}

async function confirm() {
   try {
      await planningRequestStore.changePlanningRequestState(props.planningRequest.sync_id, PlanningRequestStateKey.APPROVED);
      resetState();
      emits('on-close');
   } catch {
      console.info(`Could not confirm planning request`);
   }
}
// #endregion

// #region Styling
const planningRequest = toRef(props, 'planningRequest');
const { color } = useColorCode(planningRequest, props.date);

const contentRef = ref<HTMLDivElement | null>(null);
const heightInPx = ref('auto');

async function calculateHeight() {
   await nextTick();
   if (contentRef.value) {
      heightInPx.value = `calc(${contentRef.value.clientHeight}px + var(--nxt-gutter))`;
   }
}

watch(() => props.mode, calculateHeight, { immediate: true });
// #endregion
</script>

<template>
   <LayoutCard v-if="loading" :class="['planning-sent-card', mode, { '-loading': loading }]" :accent-color="color" />
   <LayoutCard v-else-if="planningRequest" :class="['planning-sent-card', mode]" :accent-color="color">
      <div ref="contentRef" class="planning-sent-card__content">
         <small v-if="planningRequest.period_summary" class="planning-sent-card__greet">
            {{ showActions ? planningRequest.period_summary : $t('schedules.planning-request-past-deadline') }}
         </small>

         <div class="planning-sent-card__header">
            <PlanningRequestHeader :planning-request="planningRequest" />
         </div>

         <div class="planning-sent-card__info">
            <small>{{ planningRequest.period }}</small>

            <small v-if="!planningRequest.is_dirty">
               <i class="fa-solid fa-clock"></i>
               <span>
                  {{ planningRequest.timetable_name }}
               </span>
            </small>

            <small class="planning-sent-card__info__properties">
               <span v-for="(property, index) in planningRequest.properties || []" :key="index">
                  <i :class="`fa-solid ${property.icon}`"></i>
                  <span>{{ property.name }}</span>
               </span>
            </small>

            <small v-if="planningRequest.job_address">
               <i class="fa-solid fa-location-dot"></i>
               <span>
                  {{ planningRequest.job_address }}
               </span>
            </small>
         </div>

         <div v-if="showActions" class="planning-sent-card__actions">
            <ButtonMain
               :background-color="'var(--nxt-red)'"
               :color="'var(--nxt-white)'"
               :icon-name="'times'"
               :icon-position="'left'"
               @on-click="showCantWorkModal = true"
            >
               {{ $t('actions.i-cannot') }}
            </ButtonMain>
            <ButtonMain
               :background-color="'var(--nxt-plan-main)'"
               :color="'var(--nxt-white)'"
               :icon-name="'check'"
               :icon-position="'left'"
               @on-click="confirm"
            >
               {{ $t('actions.i-confirm') }}
            </ButtonMain>
         </div>
      </div>

      <Teleport to=".app">
         <ScheduleCantWorkModal
            v-if="showCantWorkModal"
            :planning-request="planningRequest"
            :ask-for-reason="false"
            @on-close="showCantWorkModal = false"
            @on-confirm="refusePlanningRequest"
         />
      </Teleport>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.planning-sent-card {
   color: var(--nxt-dark);
   transition: height 0.2s ease-in-out;
   height: v-bind(heightInPx);

   &:not(.compact) {
      cursor: pointer;
   }
}

.planning-sent-card__content {
   display: flex;
   flex-direction: column;
}

.planning-sent-card.compact {
   .planning-sent-card__actions,
   .planning-sent-card__greet {
      display: none;
   }
}

.planning-sent-card.tiny {
   .planning-sent-card__actions,
   .planning-sent-card__greet,
   .planning-sent-card__info p:not(:nth-child(1)) {
      display: none;
   }
}

.planning-sent-card__greet {
   margin-bottom: var(--nxt-gutter-small);
}

.planning-sent-card__header {
   display: grid;
   grid-template-columns: auto 1fr auto;
   grid-template-rows: auto 0.4rem;
   grid-template-areas:
      'logos customer wage'
      'logos customer wage-type';
   align-items: center;
   gap: 0 var(--nxt-gutter-small);

   &__logos {
      grid-area: logos;
   }
   &__customer {
      grid-area: customer;
   }
   &__wage {
      grid-area: wage;
   }
   &__wage-type {
      font-weight: var(--nxt-font-light);
      grid-area: wage-type;
      margin-top: -0.2rem;
      text-align: end;
   }
}

.planning-sent-card__info {
   display: flex;
   flex-direction: column;
   font-weight: var(--nxt-font-light);
   gap: toRem(4);

   p {
      margin: 0;
   }
   i {
      margin-right: toRem(4);
      width: toRem(16);
      text-align: center;
      font-size: var(--nxt-font-small);
   }
}

.planning-sent-card__info__properties {
   display: flex;
   flex-wrap: wrap;
   gap: var(--nxt-gutter-small);
}

.planning-sent-card__actions {
   width: 100%;
   display: grid;
   grid-template-columns: auto 1fr;
   gap: var(--nxt-gutter-small);
   margin-top: var(--nxt-gutter);
}
</style>
