<script lang="ts" setup>
// #region Globals
const { disabled, name, required, invalid, touch, validate } = usePrecognitionFormElement();
const i18n = useI18n();
// #endregion

// #region Props & Emits
defineProps({
   modelValue: { type: Boolean as PropType<any>, default: null },
   label: { type: String, default: '' },
   options: {
      type: Array as PropType<{ label: string; value?: string | number | boolean; disabled?: boolean }[]>,
      default: null,
   },
});

const emits = defineEmits(['update:modelValue']);
// #endregion

// #region ModelValue
function toggle(value?: string | number | boolean) {
   if (disabled.value) return;
   emits('update:modelValue', value);

   validate();
   touch();
}
// #endregion
</script>

<template>
   <div :class="['precognition-toggle', { '-invalid': invalid }]">
      <input
         :id="name"
         :class="'hide-input'"
         type="checkbox"
         :name="name"
         :checked="!!modelValue"
         :disabled="disabled"
         :required="required"
      />
      <div class="precognition-toggle__buttons">
         <ButtonMain
            v-for="(option, index) in options || [
               { label: i18n.t('generic.yes'), value: true },
               { label: i18n.t('generic.no'), value: false },
            ]"
            :key="index"
            :font-contrast="false"
            :disabled="disabled || option.disabled"
            :type="'button'"
            :class="['precognition-toggle__button']"
            :title="option.label"
            :background-color="modelValue === option.value ? 'var(--nxt-main)' : 'transparent'"
            :color="modelValue === option.value ? 'var(--nxt-white)' : 'var(--nxt-dark)'"
            @click.stop="toggle(option.value)"
         >
            <slot name="option" v-bind="{ option, index }">
               <span> {{ option.label }} </span>
            </slot>
         </ButtonMain>
      </div>
   </div>
</template>

<style lang="scss">
.precognition-toggle {
   &.-invalid {
      .precognition-toggle__buttons {
         border: 1px solid var(--nxt-red);
      }
   }
}

.precognition-toggle__buttons {
   background-color: var(--nxt-white);
   box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
   border-radius: var(--nxt-radius);
   display: flex;
}

.precognition-toggle__button {
   border: none;
   outline: none;
   width: 100%;
   height: auto;
   min-height: var(--nxt-button-height);
   min-width: toRem(60);
}
</style>
