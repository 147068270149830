<script setup lang="ts">
import { parsePhoneNumber, type ParsedPhoneNumber } from 'awesome-phonenumber';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import countries from '~/assets/countries';

type Country = (typeof countries)[0];
const { touch } = usePrecognitionFormElement();

// #region Props & Emits
const props = defineProps({
   name: { type: String, default: null },
   required: { type: Boolean, default: false },
   placeholder: { type: String, default: null },
   description: { type: String, default: null },
   defaultCountryIso2: { type: String as PropType<string>, default: 'BE' },
   autoDetectCountry: { type: Boolean, default: true },
});
// #endregion

// #region modelValue
const modelValue = defineModel<string | null>('modelValue', { default: '' });
const parsedModelValue = computed(() => parsePhoneNumber(modelValue.value ?? ''));
// #endregion

// #region region
const region = ref<string>(parsedModelValue.value?.regionCode ?? props.defaultCountryIso2);
// #endregion

// #region Phone Number
const input = ref<string>(parsedModelValue.value.number?.national || '');
const inputParsed = computed(() => parsePhoneNumber(input.value, { regionCode: region.value }));

function updateInput(value = '') {
   const parsed = parsePhoneNumber(value, { regionCode: region.value });

   // no need to update if the significant part of the number is the same
   if (parsed.number?.input === inputParsed.value.number?.input) return;

   // if region code is different, update it
   if (props.autoDetectCountry && parsed.regionCode !== region.value) region.value = parsed.regionCode ?? props.defaultCountryIso2;

   input.value = parsed.number?.national ?? value ?? '';
   touch();
}

function updateCountry(value: string) {
   region.value = value;

   const parsed = parsePhoneNumber(input.value, { regionCode: region.value });
   input.value = parsed.number?.national ?? '';
}

function updateModelValue(inputParsed: ParsedPhoneNumber) {
   if (!inputParsed.valid) return;

   if (inputParsed.number?.input !== parsedModelValue.value.number?.input) {
      modelValue.value = inputParsed.number?.e164 ?? '';
   }
}

function overrideLocalValue(parsedModelValue: ParsedPhoneNumber) {
   if (!parsedModelValue.valid) return;

   const regionHasChanged = parsedModelValue.regionCode !== region.value;
   if (regionHasChanged) region.value = parsedModelValue.regionCode ?? props.defaultCountryIso2;

   const hasChanged = parsedModelValue.number?.input !== inputParsed.value.number?.input;
   if (hasChanged) input.value = parsePhoneNumber(modelValue.value ?? '', { regionCode: region.value }).number?.national ?? '';
}

watch(inputParsed, updateModelValue, { immediate: true });
watch(parsedModelValue, overrideLocalValue, { immediate: true });
// #endregion

// #region Countries
const allCountries = computed(() => (countries || []) as Array<Country>);
const filteredCountries = ref(allCountries.value);

function searchCountries(query = '') {
   filteredCountries.value = allCountries.value.filter((country) => {
      const codeMatch = country.iso2.toLowerCase().includes(query.toLowerCase());
      const dialCodeMatch = country.dialCode.toLowerCase().includes(query.toLowerCase());
      const nameMatch = country.name.toLowerCase().includes(query.toLowerCase());
      return nameMatch || codeMatch || dialCodeMatch;
   });
}
// #endregion
</script>

<template>
   <div class="precognition-phone-input">
      <!-- Country -->
      <PrecognitionFormSelect
         :options="filteredCountries"
         :search-type="'custom'"
         :value-key="'iso2'"
         :label-key="'iso2'"
         :model-value="region"
         :tabindex="-1"
         :placeholder="$t('actions.choose-an-option', { type: $t('generic.country')?.toLowerCase() })"
         class="precognition-phone-input__country"
         @update:model-value="updateCountry($event)"
         @on-search="searchCountries"
      >
         <template #displaySelected="{ option }: { option: Country }">
            <div v-if="option" class="precognition-phone-input__selected">
               <span> {{ getUnicodeFlagIcon(option?.iso2) }}</span>
               <span>+ {{ option?.dialCode }}</span>
            </div>
         </template>
         <template #displayOption="{ option }: { option: Country }">
            <div class="precognition-phone-input__option">
               <span> {{ getUnicodeFlagIcon(option?.iso2) }}</span>
               <span>+ {{ option?.dialCode }}</span>
            </div>
         </template>
      </PrecognitionFormSelect>

      <!-- Input -->
      <PrecognitionFormInput
         :model-value="input"
         :type="'tel'"
         :placeholder="$t('profile.phone')"
         class="precognition-phone-input__number"
         @update:model-value="updateInput($event as string)"
      />
   </div>
</template>

<style lang="scss" scoped>
.precognition-phone-input {
   display: grid;
   grid-template-columns: minmax(toRem(80), toRem(100)) 1fr;
}

.precognition-phone-input__selected {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}

.precognition-phone-input__option {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}

.precognition-phone-input__country {
   &:deep(.precognition-select) {
      border-radius: var(--nxt-radius) 0 0 var(--nxt-radius);
   }
}

.precognition-phone-input__number {
   &:deep(input) {
      border-radius: 0 var(--nxt-radius) var(--nxt-radius) 0;
      border-left: none;
   }
}
</style>
