<script lang="ts" setup>
import { TERMINATION_TYPE_ENDPOINTS } from '~/resources/termination-type/termination-type.endpoints';
import { WORK_EXPERIENCE_TYPE_ENDPOINTS } from '~/resources/work-experience-type/work-experience-type.endpoints';

// #region Props & Emits
defineEmits<{
   (e: 'onSave', data: PersonWorkExperience): void;
   (e: 'onCancel'): void;
}>();

const props = defineProps<{ workExperience?: PersonWorkExperience }>();
// #endregion

// #region Options
const { data: workExperienceTypes } = useAuthFetch<Array<WorkExcperienceType>>(WORK_EXPERIENCE_TYPE_ENDPOINTS.GET(), cacheConfig);
const { data: terminationTypes } = useAuthFetch<Array<WorkExcperienceType>>(TERMINATION_TYPE_ENDPOINTS.GET(), cacheConfig);
// #endregion

// #region Form
const { required } = useFormValidators();

const { data, validation } = useForm<PersonWorkExperience>({
   config: { initialValues: JSON.parse(JSON.stringify({ ...(props.workExperience || {}) })) },
   rules: () => ({
      from: { required },
      till: {},
      work_experience_type_id: { required },
      company_name: { required },
      title: { required },
   }),
});
// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="workExperience ? 'edit' : 'add'"
      :data-type="$t('profile.skills.work-experience')"
      :data="data"
      :validation="validation"
      @on-save="$emit('onSave', $event)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-work-experience-form'">
         <div class="profile-work-experience-form__dates">
            <FormElement :name="`from`" :label="$t('generic.from')">
               <FormDatepicker v-model="data.from" />
            </FormElement>
            <FormElement :name="`till`" :label="$t('generic.till')">
               <FormDatepicker v-model="data.till" />
            </FormElement>
         </div>

         <FormElement :name="`title`" :label="$t('profile.skills.function')">
            <FormInput v-model="data.title" />
         </FormElement>

         <FormElement :name="`company_name`" :label="$t('generic.employer')">
            <FormInput v-model="data.company_name" />
         </FormElement>

         <FormElement :name="`work_experience_type_id`" :label="$t('generic.type')">
            <FormSelect v-model="data.work_experience_type_id" :options="workExperienceTypes || []" />
         </FormElement>

         <FormElement :name="`termination_type_id`" :label="$t('profile.skills.termination-type')">
            <FormSelect v-model="data.termination_type_id" :options="terminationTypes || []" />
         </FormElement>

         <FormElement :name="`description`" :label="$t('generic.description')">
            <FormTextarea v-model="data.description" />
         </FormElement>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-work-experience-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-work-experience-form__dates {
   display: flex;
   gap: var(--nxt-gutter);

   > * {
      flex: 1;
   }
}
</style>
