export const usePositionStore = defineStore('position', () => {
   // #region My Positions
   const {
      data: myPositions,
      execute: refetchMyPositions,
      pending: loadingMyPositions,
   } = useAuthFetch<ApiResponse<Array<Position>>>(POSITION_ENDPOINTS.MY_POSITIONS(), {
      immediate: false,
   });
   // #endregion

   // #region Positions
   const jobs = ref<Array<Position>>([]);

   function updateJob(job: Position) {
      const index = jobs.value.findIndex((j) => j.sync_id === job.sync_id);
      if (index !== -1) {
         jobs.value[index] = job;
      }
   }
   // #endregion

   return {
      jobs,
      myPositions,
      loadingMyPositions,
      refetchMyPositions,
      updateJob,
   };
});
