<script lang="ts" setup>
// #region Props & Emits
defineEmits<{ (e: 'onClose'): void; (e: 'onConfirm', cantWorkData: any): void }>();
const props = defineProps({
   askForReason: { type: Boolean, default: true },
   planningRequest: { type: Object as PropType<PlanningRequest>, default: null },
});
// #endregion

// #region Form
const { requiredIf } = useFormValidators();
const { data, validation } = useForm<any>({
   rules: (data) => ({
      unemployment_reason: { required: requiredIf(() => props.askForReason) },
      other_reason: { requiredIf: requiredIf(() => props.askForReason && data.unemployment_reason === 'other') },
   }),
});
// #endregion
</script>

<template>
   <FormContainer :data="data" :validation="validation" @on-valid-submit="$emit('onConfirm', $event)">
      <ModalSide
         :title="$t('schedules.you-indicate-that-you-cant-work')"
         class="cant-work"
         :width="'35rem'"
         :height="'fit-content'"
         @on-close="$emit('onClose')"
      >
         <div class="cant-work__form">
            <div class="cant-work__form__contact">
               <strong>{{ $t('schedules.who-to-contact') }}</strong>
               <ScheduleContactCard :planning-request="planningRequest" />
            </div>
         </div>

         <template #actions>
            <ButtonMain :background-color="'var(--nxt-light-grey)'" :color="'var(--nxt-dark)'" @on-click="$emit('onClose')">
               {{ $t('actions.cancel') }}
            </ButtonMain>
            <ButtonMain
               :type="'submit'"
               :icon-name="'times'"
               :icon-position="'left'"
               :icon-label="$t('actions.i-confirm')"
               :background-color="'var(--nxt-red)'"
            >
               {{ $t('actions.i-cant') }}
            </ButtonMain>
         </template>
      </ModalSide>
   </FormContainer>
</template>

<style lang="scss" scoped>
.cant-work {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
   min-height: 100%;
}
.cant-work__form {
   flex: 1;
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-large);
}

.cant-work__form__danger {
   margin-bottom: var(--nxt-gutter-small);
}

.cant-work__form__contact {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}
</style>
