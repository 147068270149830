<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
const dayjs = useDayjs();
const { confirmDeleteType } = useModalStore();

defineExpose({ add: () => (showAddEducationModal.value = true) });
// #endregion

// #region Props & Emits
const modelValue = defineModel<Array<PersonProfileEducation>>('modelValue', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region Education Modals
const showAddEducationModal = ref(false);
const showChangeEducationModal = ref(false);
const educationIndexToChange = ref<number>(-1);

function openChangeEducationModal(index: number) {
   educationIndexToChange.value = index;
   showChangeEducationModal.value = true;
}

function closeChangeEducationModal() {
   educationIndexToChange.value = -1;
   showChangeEducationModal.value = false;
}

// #endregion

// #region Educations
function addEducation(education: PersonProfileEducation) {
   const newEducation = JSON.parse(JSON.stringify(education));

   if (newEducation.major_id === -1) {
      newEducation.major = undefined;
      newEducation.major_id = undefined;
   }
   if (newEducation.degree_id === -1) {
      newEducation.degree = undefined;
      newEducation.degree_id = undefined;
   }

   modelValue.value.push(newEducation);
   showAddEducationModal.value = false;

   validate();
   touch();
}

function changeEducation(education: PersonProfileEducation) {
   if (educationIndexToChange.value === -1) return;
   modelValue.value[educationIndexToChange.value] = education;
   closeChangeEducationModal();

   validate();
   touch();
}

function removeEducation(index: number) {
   confirmDeleteType(t('profile.skills.education'), () => {
      modelValue.value.splice(index, 1);
      validate();
      touch();
   });
}

// #endregion
</script>

<template>
   <div v-auto-animate class="person-todo-educations">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <div v-if="modelValue.length <= 0" :class="'person-todo-educations__no-educations'">
         <p>
            {{ $t('generic.no-type-add-type', { type: $t('profile.skills.education', 2)?.toLowerCase() }) }}
         </p>
      </div>

      <LayoutCard
         v-for="(education, index) in modelValue"
         :key="education.sync_id || `${education.degree_id} - ${education.major_id}` || index"
         class="profile-education-modal__education"
      >
         <p class="profile-education-modal__education__dates">
            <small> {{ dayjs(education.from).format('DD/MM/YYYY') }}</small>
            <small v-if="education.till"> - {{ dayjs(education.till).format('DD/MM/YYYY') }}</small>
         </p>

         <p class="profile-education-modal__education__title">
            <strong>{{ education.degree?.name }} {{ education.major?.name }}</strong>
         </p>

         <p class="profile-education-modal__education__school-name">
            {{ education.school_name }}
         </p>

         <div class="profile-education-modal__education__actions">
            <ButtonIcon
               :icon-name="'pen'"
               :icon-label="$t('actions.change-type', { type: $t('profile.skills.education') })"
               @on-click="openChangeEducationModal(index)"
            />
            <ButtonIcon
               :icon-name="'trash'"
               :icon-label="$t('actions.delete-type', { type: $t('profile.skills.education') })"
               @on-click="removeEducation(index)"
            />
         </div>
      </LayoutCard>

      <ClientOnly>
         <!-- Create -->
         <Teleport to=".modal-side__card__content">
            <ProfileEducationForm v-if="showAddEducationModal" @on-save="addEducation" @on-cancel="showAddEducationModal = false" />
         </Teleport>

         <!-- Change -->
         <Teleport to=".modal-side__card__content">
            <ProfileEducationForm
               v-if="showChangeEducationModal && educationIndexToChange > -1"
               :education="modelValue[educationIndexToChange]"
               @on-save="changeEducation"
               @on-cancel="closeChangeEducationModal"
            />
         </Teleport>
      </ClientOnly>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-educations {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}

.person-todo-educations__no-educations {
   text-align: center;
}
.profile-education-modal__education {
   display: grid;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto auto;
   grid-template-areas: 'dates actions' 'title actions' 'school-name actions';
   gap: toRem(2) var(--nxt-gutter);

   &__dates {
      grid-area: dates;
      margin: 0;
   }

   &__title {
      grid-area: title;
      margin: 0;

      strong {
         margin-right: var(--nxt-gutter-small);
      }
   }

   &__school-name {
      grid-area: school-name;
      margin: 0;
   }

   &__actions {
      grid-area: actions;
      display: flex;
      gap: var(--nxt-gutter-small);
      justify-content: flex-end;
   }
}
</style>
