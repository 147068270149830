<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { DefaultLayoutProps } from '~/composables/useDefaultLayout';

// #region Globals
const router = useRouter();
const localePath = useLocalePath();
const userInfoStore = useUserInfoStore();
const isTablet = useMediaQuery(TABLET_AND_UP);
const { appImageBasePath, tenantName } = useBranding();
// #endregion

// #region Props & Emits
const props = withDefaults(defineProps<DefaultLayoutProps>(), {
   actions: () => [],
   headerBackground: 'var(--nxt-base-header-background)',
   headerColor: 'var(--nxt-base-header-color)',
   logo: true,
   root: false,
   title: 'NxtPlan',
   navigateBackTo: null,
   navigateBackText: null,
   hideProfile: false,
});

// #endregion

// #region User Info
const { userInfo } = storeToRefs(userInfoStore);
// #endregion

// #region Navigate Back
function navigateBack() {
   if (typeof props.navigateBackTo === 'string') {
      return router.push(localePath(props.navigateBackTo));
   }

   if (typeof props.navigateBackTo === 'function') {
      return props.navigateBackTo();
   }

   router.back();
}
// #endregion
</script>

<template>
   <div class="header-wrapper">
      <slot name="contentBefore"></slot>

      <header class="layout-header">
         <div class="layout-header__left">
            <NuxtLink :to="'/'" class="layout-header__logo">
               <img
                  v-if="root && logo && typeof logo === 'boolean'"
                  :class="'layout-header__logo'"
                  :src="`/${appImageBasePath}/emblem.png`"
                  :alt="tenantName"
               />
               <img v-if="root && logo && typeof logo === 'string'" :class="'layout-header__logo'" :src="logo" :alt="'NxtPeople'" />
            </NuxtLink>

            <ButtonLink
               v-if="navigateBackTo && isTablet"
               class="layout-header__navigate-back"
               :style="{ color: headerColor }"
               @on-click="navigateBack"
            >
               <i :class="['fa-solid', 'fa-chevron-left']" />
               <span>
                  {{ navigateBackText }}
               </span>
            </ButtonLink>

            <ButtonLink
               v-else-if="navigateBackTo"
               class="layout-header__navigate-back"
               :style="{ color: headerColor }"
               @on-click="navigateBack"
            >
               <i :class="['fa-solid', 'fa-chevron-left']" />
            </ButtonLink>
         </div>

         <div class="layout-header__title">
            <h2>{{ title }}</h2>
         </div>

         <div class="layout-header__right">
            <template v-for="(action, index) in actions" :key="index">
               <ButtonIcon v-if="action.callback" :icon-style="{ color: headerColor }" v-bind="action" @click="action.callback" />
               <NuxtLink v-else-if="action.url" :class="'layout-header__action'" :to="action.url" :active-class="'-active'" @click.stop>
                  <i :style="action.iconStyle" :class="[action.iconType ?? 'fa-solid', action.iconName ?? 'fa-times']"></i>
               </NuxtLink>
            </template>

            <KeepAlive>
               <NuxtLink
                  v-show="!hideProfile && !isTablet"
                  prefetch
                  :class="'navigation__item -settings'"
                  :active-class="'-active'"
                  :to="localePath('/profile')"
               >
                  <LayoutCircularLogo
                     :logo="userInfo?.person?.profile_picture_url ?? ''"
                     :name="`${userInfo?.person?.first_name} ${userInfo?.person?.last_name}`"
                     :height="32"
                     :width="32"
                     :object-fit="'cover'"
                     :background="'var(--nxt-extralight-grey)'"
                  />
               </NuxtLink>
            </KeepAlive>
         </div>
      </header>
      <slot name="pre-header"></slot>
   </div>
</template>

<style lang="scss" scoped>
.layout-header {
   display: grid;
   grid-template-columns: minmax(toRem(64), auto) 1fr minmax(toRem(64), auto);
   grid-template-rows: auto;
   gap: var(--nxt-gutter);
   padding: 0 var(--nxt-gutter);
   max-width: toRem(600);
   margin: 0 auto;
   align-items: center;
   min-height: var(--nxt-base-header-height);

   @include tablet {
      display: flex;
      justify-content: space-between;
      background-color: transparent;
   }
}
.layout-header__left {
   display: flex;
}

.layout-header__right {
   display: flex;
   justify-content: flex-end;
   gap: var(--nxt-gutter);
}

.layout-header__logo {
   max-height: toRem(32);
   object-fit: contain;
   position: relative;
   border-radius: var(--nxt-radius);

   @include tablet {
      display: none;
   }

   img {
      max-width: toRem(80);
   }

   .xmas-hat {
      position: absolute;
      top: -13px;
      left: 10px;
      z-index: 1;
      transform: rotate(10deg);
   }
}

.layout-header__navigate-back {
   text-decoration: none;
}

.layout-header__title {
   display: flex;
   align-items: center;
   justify-content: center;

   h2 {
      margin: 0;
      font-size: var(--nxt-font-large);
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   @include tablet {
      text-align: start;
   }
}
</style>
