import type { TenantStyle } from '~/models/tenant-branding.interface';

export const NXT_PLAN_BRANDING: TenantStyle = {
   tenantName: 'My NxtPlan',
   themeColor: '#ffffff',
   bannerImage: 'https://nxtpeople-cdn.s3.eu-central-1.amazonaws.com/keycloak/keycloak-banner-video.mp4',
   fontUrl: 'https://nxtpeople-cdn.s3.eu-central-1.amazonaws.com/fonts/Poppins_NXT/Poppins_NXT.css',
   showPositions: false,
   files: {
      termsAndConditionsUrl: (locale) => {
         return `https://www.nxtpeople.com/assets/files/${locale}/NXTPeople_Terms_Conditions-2023.pdf`;
      },
      privacyPolicyUrl: () => {
         return `${window.location.origin}/privacy-policy`;
      },
      cookiePolicyUrl: (locale) => {
         return `https://www.nxtpeople.com/assets/files/${locale}/NXTPeople_Cookie_policy.pdf`;
      },
   },
   cssProperties: {
      '--nxt-main': 'var(--nxt-plan-main)',
      '--nxt-main-highlight': 'var(--nxt-plan-main-highlight)',
      '--nxt-card-background': 'var(--nxt-extralight-grey)',
      '--nxt-base-header-background': 'var(--nxt-white)',
      '--nxt-base-header-color': 'var(--nxt-dark)',
      '--nxt-main-overlay': 'var(--nxt-plan-main-overlay)',
      '--nxt-special-background': "var(--nxt-white) url('/nxt-plan-icons/background.svg') no-repeat bottom",
   },
};
