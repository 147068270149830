import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', () => {
   const { appEnv } = useRuntimeConfig().public;
   const isDev = computed(() => ['dev', 'test'].includes(appEnv));

   // #region Online Status
   const onlineStatus = ref({ online: true, offline: false });

   function setOnlineStatus(isOnline: boolean) {
      onlineStatus.value = {
         online: isOnline,
         offline: !isOnline,
      };
   }

   async function checkIsOnline() {
      const isOnline = await $fetch('/api/online')
         .then(() => true)
         .catch(() => false);

      setOnlineStatus(isOnline);

      return onlineStatus.value;
   }

   // #endregion

   return {
      isDev,

      // online status
      onlineStatus,
      setOnlineStatus,
      checkIsOnline,
   };
});
