<script lang="ts" setup>
// #region Props & Emits
defineEmits<{
   (e: 'onSave', data: PersonCourse): void;
   (e: 'onCancel'): void;
}>();

const props = defineProps<{ course?: PersonCourse }>();
// #endregion

// #region Form
const { required } = useFormValidators();

const { data, validation } = useForm<PersonCourse>({
   config: { initialValues: JSON.parse(JSON.stringify({ ...(props.course || {}) })) },
   rules: () => ({
      from: { required },
      till: { required },
      name: { required },
      organisation: { required },
   }),
});
// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="course ? 'edit' : 'add'"
      :data-type="$t('profile.courses.course')"
      :data="data"
      :validation="validation"
      :height="'var(--nxt-modal-layer-3)'"
      @on-save="$emit('onSave', $event)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-course-form'">
         <div class="profile-course-form__dates">
            <FormElement :name="`from`" :label="$t('generic.from')">
               <FormDatepicker v-model="data.from" />
            </FormElement>
            <FormElement :name="`till`" :label="$t('generic.till')">
               <FormDatepicker v-model="data.till" />
            </FormElement>
         </div>

         <FormElement :name="`name`" :label="$t('profile.courses.course')">
            <FormInput v-model="data.name" />
         </FormElement>

         <FormElement :name="`organisation`" :label="$t('profile.courses.organisation')">
            <FormInput v-model="data.organisation" />
         </FormElement>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-course-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-course-form__dates {
   display: flex;
   gap: var(--nxt-gutter);

   > * {
      flex: 1;
   }
}
</style>
