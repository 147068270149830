<script lang="ts" setup>
import type { PersonJobDistance } from '~/resources/person/person.interface';

// #region Props & Emits

const props = defineProps<{
   initialJobDistance?: PersonJobDistance;
}>();
defineEmits<{
   onSave: [data: PersonJobDistance];
   onCancel: [];
}>();

// #endregion

// #region Form
const { required } = useFormValidators();

const { data, validation } = useForm<PersonJobDistance>({
   config: {
      initialValues: { ...props.initialJobDistance },
   },
   rules: () => ({
      city_id: { required },
   }),
});

function updateCity(city?: City) {
   data.value.city_id = city?.id;
   data.value.city = city;
}
// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="initialJobDistance ? 'edit' : 'add'"
      :data-type="$t('profile.job-preferences.work-region', 1)"
      :data="data"
      :validation="validation"
      :height="'var(--nxt-modal-layer-3)'"
      @on-save="$emit('onSave', data)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-job-distance-form'">
         <FormElement :name="'city_id'" :label="$t('actions.select-type', { type: $t('profile.city', 1) })" :label-style="'capitalize'">
            <FormSelect
               :model-value="data.city_id"
               :search-type="'custom'"
               :emit-object="true"
               :label="$t('profile.city')"
               :options-to-load-initially="25"
               :options="data.city ? [data.city] : []"
               :search-url="CITY_ENDPOINTS.GET()"
               @update:model-value="updateCity"
            />
         </FormElement>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-job-distance-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-job-distance-form {
   display: flex;
   justify-content: space-between;
   gap: var(--nxt-gutter);
}
</style>
