<script lang="ts" setup>
// #region Globals
const { name, invalid, required, disabled, label, validate, touch } = usePrecognitionFormElement();
const dayjs = useDayjs();
// #endregion

// #region Props & Emits
const emits = defineEmits<{
   (e: 'update:modelValue', value: string): void;
}>();
const props = defineProps({
   modelValue: { type: String as PropType<string | null | any>, default: null },
   placeholder: { type: String, default: '' },
   min: { type: String, default: null },
   max: { type: String, default: null },
});
// #endregion

// #region ModelValue
const formattedDate = computed(() => (props.modelValue ? dayjs(props.modelValue).format('DD/MM/YYYY') : null));

function handleInput(e: Event) {
   const target = e.target as HTMLInputElement;
   const value = target.value;
   emits('update:modelValue', value);
   validate();
   touch();
}
// #endregion

// #region Input
const inputRef = ref<HTMLInputElement>();

function showDatePicker() {
   if (!inputRef.value) {
      return;
   }

   try {
      inputRef.value?.click();
      inputRef.value?.showPicker();
      inputRef.value?.focus();
   } catch {}
}
// #endregion
</script>

<template>
   <div :class="['datepicker', { invalid, disabled }]">
      <input
         :id="name"
         ref="inputRef"
         class="datepicker__input"
         :value="modelValue"
         :disabled="disabled"
         :name="name"
         :required="required"
         :type="'date'"
         :min="min"
         :max="max"
         tabindex="-1"
         @change="handleInput"
         @focus="showDatePicker"
      />
      <button
         type="button"
         :disabled="disabled"
         :class="['datepicker__main', { '-placeholder': !formattedDate, '-invalid': invalid, '-disabled': disabled }]"
         @click="showDatePicker"
      >
         <span v-if="!formattedDate">
            {{ placeholder || label || 'Select date' }}
         </span>
         <span v-else>
            {{ formattedDate }}
         </span>
      </button>
   </div>
</template>

<style lang="scss" scoped>
.datepicker {
   position: relative;
   width: 100%;
   height: toRem(40);
   min-width: toRem(130);
}

.datepicker__input {
   width: 0px;
   height: 100%;
   overflow: hidden;
   z-index: -1;
   position: absolute;
   top: 0;
   left: 0;
   border: none;
   outline: none;
   padding: 0;
}

.datepicker__main {
   background-color: var(--nxt-light-grey);
   border: 1px solid var(--nxt-grey);
   border-radius: var(--nxt-radius);
   min-width: toRem(130);
   width: 100%;
   text-align: start;

   &.-placeholder {
      color: var(--nxt-dark-grey);
   }

   &.-invalid {
      border-color: var(--nxt-red);
   }

   &.-disabled {
      background-color: var(--nxt-extralight-grey);
      color: var(--nxt-medium-grey);
      border-color: var(--nxt-light-grey);
      cursor: not-allowed;
   }
}
</style>
