<script lang="ts" setup>
// #region Globals
const { disabled, touch } = useFormElement();
// #endregion

// #region Props & Emits
const emits = defineEmits<{
   (e: 'update:modelValue', value: boolean): void;
}>();
defineProps({
   modelValue: { type: Boolean, default: null },
   mode: { type: String as PropType<'planning' | 'availability'>, default: 'availability' },
   partial: { type: Boolean, default: false },
});
// #endregion

// #region modelValue
function updateModelValue(value: boolean) {
   emits('update:modelValue', value);
   if (touch) touch();
}
// #endregion
</script>

<template>
   <div :class="['button-toggle', mode]">
      <ButtonIcon
         :class="['button-toggle__option -no', { active: modelValue === false }]"
         :icon-name="'times'"
         :icon-label="$t('generic.no')"
         :disabled="disabled"
         @on-click="updateModelValue(false)"
      />
      <ButtonIcon
         :class="['button-toggle__option -yes', { active: modelValue == true, partial }]"
         :icon-name="'check'"
         :icon-label="$t('generic.yes')"
         :disabled="disabled"
         @on-click="updateModelValue(true)"
      />
   </div>
</template>

<style lang="scss" scoped>
.button-toggle {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: var(--nxt-gutter-small);
}

.button-toggle.planning {
   .button-toggle__option {
      &.active {
         &.-no {
            background-color: var(--nxt-red);
            border-color: var(--nxt-red);
            color: var(--nxt-white);
         }
         &.-yes {
            background-color: var(--nxt-green);
            border-color: var(--nxt-green);
            color: var(--nxt-white);
         }
      }
   }
}

.button-toggle.availability {
   .button-toggle__option {
      &.active {
         &.-no {
            background-color: var(--nxt-white);
            border-color: var(--nxt-red);
            color: var(--nxt-red);
         }
         &.-yes {
            background-color: var(--nxt-white);
            border-color: var(--nxt-green);
            color: var(--nxt-green);
            &.partial {
               background-color: var(--nxt-white);
               border-color: var(--nxt-orange);
               color: var(--nxt-orange);
            }
         }
      }
   }
}

.button-toggle__option {
   height: toRem(28);
   width: toRem(28);
   border-radius: 50%;
   background-color: var(--nxt-white);
   border: 1px solid var(--nxt-light-grey);

   &.-no,
   &.-yes {
      background-color: var(--nxt-white);
      border: 1px solid var(--nxt-grey);
      color: var(--nxt-dark);
   }
}
</style>
