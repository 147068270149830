import dayjs from 'dayjs';
import type { PersonTodo } from '../person-todo/person-todo.interface';
import type { Schedule } from '../schedule/schedule.interface';

export const useMyWeekStore = defineStore('myWeek', () => {
   // #region my week
   const {
      data: myWeek,
      pending: loadingMyWeek,
      refresh: refreshMyWeek,
   } = useAuthFetch<MyWeek>(MY_WEEK_ENDPOINTS.GET(), { immediate: false });
   // #endregion

   // #region Task Count
   const taskCount = computed(() => myWeek.value?.my_tasks?.length ?? 0);

   function updateBadge(taskCount: number) {
      if (!myWeek.value) return;

      if (taskCount && 'setAppBadge' in navigator) {
         return navigator.setAppBadge(taskCount);
      }

      if ('clearAppBadge' in navigator) {
         return navigator.clearAppBadge();
      }
   }

   watch(taskCount, updateBadge, { immediate: true });
   // #endregion

   // #region Onboarding
   const tasks = computed(() => myWeek.value?.my_tasks ?? []);
   const onboardingTask = computed(() => {
      return tasks.value.find((task) => {
         const isPersonTodo = task.task_type_key === TaskTypeKey.PersonTodo;
         const isOnboarding = isPersonTodo && (task.task as PersonTodo).chapter.key === 'onboarding';

         return isOnboarding;
      });
   });
   const onboardingTodo = computed(() => onboardingTask.value?.task as PersonTodo | undefined);

   function openOnboarding(onboardingTodo?: PersonTodo) {
      if (!onboardingTodo) return;

      useRouter().push({ ...useRoute(), hash: `${GlobalModalKey.Onboarding}?id=${onboardingTodo.sync_id}` });
   }

   watch(onboardingTodo, openOnboarding, { immediate: true });
   // #endregion

   // #region my week schedule
   const {
      data: myWeekSchedule,
      pending: loadingMyWeekSchedule,
      refresh: refreshMyWeekSchedule,
   } = useAuthFetch<Schedule>(SCHEDULE_ENDPOINTS.GET(), {
      immediate: false,
      params: {
         between: `${dayjs().startOf('week').format('YYYY-MM-DD')},${dayjs().endOf('week').format('YYYY-MM-DD')}`,
      },
   });
   // #endregion

   return { myWeek, loadingMyWeek, refreshMyWeek, myWeekSchedule, loadingMyWeekSchedule, refreshMyWeekSchedule };
});
