import type { FetchContext } from 'ofetch';
import { useKeycloakStore } from '~/resources/keycloak.store';

type UseFetchType<T> = typeof useFetch<T>;
type UseFetchParams<T> = Parameters<UseFetchType<T>>;
export type UseFetchOptions<T> = UseFetchParams<T>[1] & {
   // fix for bug: https://github.com/nuxt/nuxt/issues/19077
   method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'get' | 'post' | 'put' | 'delete' | 'patch' | string;
   useCache?: boolean;
};

export function usePublicFetch<T>(url: string, fetchOptions?: UseFetchOptions<T>) {
   const { public: config } = useRuntimeConfig();

   const baseOptions = { baseURL: config.apiBaseUrl, lazy: true };
   const customOptions = { ...fetchOptions, onResponseError, onRequest: (context: FetchContext) => onRequest({ context }) };
   const mergedOptions = { ...baseOptions, ...customOptions };

   return useFetch<T>(`${url}`, mergedOptions);
}

export function useAuthFetch<T>(url: string, fetchOptions?: UseFetchOptions<T>) {
   const { public: config } = useRuntimeConfig();
   const { keycloakAuthenticated } = useKeycloakStore();
   const baseOptions = { baseURL: config.apiBaseUrl, lazy: true, server: !!keycloakAuthenticated };
   const customOptions = {
      ...fetchOptions,
      onResponseError,
      onRequest: async (context: FetchContext) => {
         if (fetchOptions?.onRequest && typeof fetchOptions.onRequest === 'function') {
            await fetchOptions.onRequest(context);
         }
         return onRequest({ context, auth: true });
      },
      onResponse: async (context: FetchContext) => {
         if (fetchOptions?.onResponse && typeof fetchOptions.onResponse === 'function') {
            await fetchOptions.onResponse(context as any);
         }
         return onResponse(context);
      },
   };
   const mergedOptions: UseFetchOptions<T> = {
      ...baseOptions,
      ...customOptions,
   };

   return useFetch<T>(`${url}`, mergedOptions);
}
