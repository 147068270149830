export interface PrecognitionFormElementData {
   name: Ref<string>;
   label: Ref<string>;
   errors: Ref<string | undefined>;
   invalid: Ref<boolean>;
   required: Ref<boolean>;
   disabled: Ref<boolean>;
   validate: () => void;
   touch: () => void;
}

export function usePrecognitionFormElement() {
   return inject('precognitionFormElement', {} as PrecognitionFormElementData);
}
