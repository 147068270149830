<script lang="ts" setup>
import { DEGREE_ENDPOINTS } from '~/resources/degree/degree.endpoints';

// #region Globals
const { t } = useI18n();
// #endregion

// #region Props & Emits
defineEmits<{
   (e: 'onSave', data: PersonProfileEducation): void;
   (e: 'onCancel'): void;
}>();

const props = defineProps<{ education?: PersonProfileEducation }>();
// #endregion

// #region Form
const { required, requiredIf } = useFormValidators();

const { data, validation } = useForm<PersonProfileEducation>({
   config: { initialValues: JSON.parse(JSON.stringify({ ...(props.education || { is_graduated: false }) })) },
   rules: (data) => ({
      major_id: { requiredIf: requiredIf(() => data.major_id !== null) },
      alternative_major: { requiredIf: requiredIf(() => data.major_id === null) },
      degree_id: { requiredIf: requiredIf(() => data.degree_id !== null) },
      alternative_degree: { requiredIf: requiredIf(() => data.degree_id === null) },
      from: { required },
   }),
});

// #endregion

// #region Majors & Degrees
const { data: degreesOverview } = useAuthFetch<Array<{ country_id: number; country: Country; degrees: Array<Degree> }>>(
   DEGREE_ENDPOINTS.GET(),
   {},
);

const degrees = computed<Array<{ country_id: number; country: Country; degrees: Array<Degree> }>>(() =>
   [...(degreesOverview.value || [])].map((country) => ({
      ...country,
      degrees: [...country.degrees, { id: -1, key: 'other', name: t('generic.other'), majors: [] }].map((degree) => ({
         ...degree,
         majors: [...degree.majors, { id: -1, key: 'other', degree_id: null, name: t('generic.other') }],
      })),
   })),
);

const allDegrees = computed(() => degrees.value.flatMap((d) => d.degrees));

const majors = computed(() => allDegrees.value.find((d) => d.id === data.value.degree_id)?.majors || []);

function updateMajor(major: Major) {
   data.value.major_id = major.id;
   data.value.major = major;
}
function updateDegree(degree: Degree) {
   data.value.degree_id = degree.id;
   data.value.degree = degree;

   data.value.major_id = undefined;
   data.value.major = undefined;
}

// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="education ? 'edit' : 'add'"
      :data-type="$t('profile.skills.education')"
      :data="data"
      :validation="validation"
      :height="'var(--nxt-modal-layer-3)'"
      @on-save="$emit('onSave', data)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-education-form'">
         <FormElement :name="'degree_id'" :label="$t('actions.select-type', { type: $t('profile.skills.degree')?.toLowerCase() })">
            <FormSelect
               :model-value="data.degree_id"
               :emit-object="true"
               :options="degrees"
               :label="$t('profile.skills.degree')"
               :search-type="'internal'"
               :group="{
                  groupValueKey: 'country_id',
                  groupLabelKey: 'country.name',
                  groupListKey: 'degrees',
               }"
               @update:model-value="updateDegree"
            />
         </FormElement>

         <FormElement
            v-if="data.degree_id === -1"
            :name="'alternative_degree'"
            :label="$t('generic.other-type', { type: $t('profile.skills.degree')?.toLowerCase() })"
         >
            <FormInput v-model="data.alternative_degree" />
         </FormElement>

         <FormElement
            :name="'major_id'"
            :label="$t('actions.select-type', { type: $t('profile.skills.major')?.toLowerCase() })"
            :disabled="!data.degree_id && !data.alternative_degree"
         >
            <FormSelect
               :model-value="data.major_id"
               :emit-object="true"
               :options="majors"
               :label="$t('profile.skills.major')"
               :search-type="'internal'"
               @update:model-value="updateMajor"
            />
         </FormElement>

         <FormElement
            v-if="data.major_id === -1"
            :name="'alternative_major'"
            :label="$t('generic.other-type', { type: $t('profile.skills.major')?.toLowerCase() })"
         >
            <FormInput v-model="data.alternative_major" />
         </FormElement>

         <FormElement :name="'school_name'" :label="$t('profile.skills.school')">
            <FormInput v-model="data.school_name" />
         </FormElement>

         <div class="profile-education-form__dates">
            <FormElement :name="'from'" :label="$t('generic.from')">
               <FormDatepicker v-model="data.from" />
            </FormElement>

            <FormElement :name="'till'" :label="$t('generic.till')">
               <FormDatepicker v-model="data.till" />
            </FormElement>
         </div>

         <FormElement :name="'is_graduated'" :label="$t('profile.skills.is_graduated')" label-position="left">
            <FormSwitch v-model="data.is_graduated" />
         </FormElement>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-education-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-education-form__dates {
   display: flex;
   gap: var(--nxt-gutter);

   > * {
      flex: 1;
   }
}
</style>
