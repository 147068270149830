<script lang="ts" setup>
// #region Props
const emits = defineEmits(['onAction']);
const props = defineProps<{
   title: string;
   hasAction?: boolean;
   actionType?: string;
   contentPadding?: string;
   collapsable?: boolean;
}>();
// #endregion

// #region Collapse
const isCollapsed = ref(false);

function toggleCollapse() {
   if (!props.collapsable) return;
   isCollapsed.value = !isCollapsed.value;
}
// #endregion
</script>

<template>
   <div :class="['section']">
      <div class="section__title">
         <strong>{{ title }}</strong>

         <div class="title__action">
            <slot v-if="hasAction" name="action">
               <ButtonIcon
                  :icon-label="$t('actions.add-type', { type: actionType })"
                  :icon-name="'add'"
                  :prevent-double-click="true"
                  @on-click="emits('onAction')"
               />
            </slot>

            <ButtonIcon
               v-if="collapsable"
               :icon-label="isCollapsed ? $t('actions.show') : $t('actions.hide')"
               :icon-name="isCollapsed ? 'chevron-down' : 'chevron-up'"
               :prevent-double-click="false"
               @on-click="toggleCollapse"
            />
         </div>
      </div>

      <div v-show="!isCollapsed" class="section__content" :style="{ padding: contentPadding || '0 var(--nxt-gutter-small)' }">
         <slot></slot>
      </div>
   </div>
</template>

<style lang="scss" scoped>
.section {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.section__title {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: var(--nxt-gutter);
   background-color: var(--nxt-light-grey);
   padding: var(--nxt-gutter-small);
   border-radius: var(--nxt-radius);
}

.title__action {
   display: flex;
   gap: var(--nxt-gutter-small);
   margin: var(--nxt-gutter-small--negative);
}

.section__content {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}
</style>
