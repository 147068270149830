<script lang="ts" setup>
import type { ErrorObject, Validation } from '@vuelidate/core';

// #region Globals
const autoValidation = inject('validation', ref<Validation>());
// #endregion

// #region Props
const props = defineProps({
   name: { type: String, required: true },
   disabled: { type: Boolean, default: false },
   required: { type: Boolean, default: false },

   label: { type: String, default: '' },
   labelStyle: { type: String as PropType<'uppercase' | 'capitalize' | 'lowercase' | 'custom'>, default: 'capitalize' },
   labelPosition: { type: String as PropType<'top' | 'left' | 'right'>, default: 'top' },

   description: { type: String, default: '' },

   customValidation: { type: Object as PropType<Validation>, default: undefined },
});
// #endregion

const validation = computed(() => props.customValidation || autoValidation.value);

const formDisabled = inject('disabled', ref(false));
const validationErrors = computed(() => getPropertyByPath(validation.value, props.name)?.$errors);
const name = computed(() => props.name);
const label = computed(() => props.label);
const localErrors = computed<Array<ErrorObject>>(() => validationErrors.value || []);
const invalid = computed(() => !!localErrors.value.length && !!validation.value?.$dirty);

const required = computed(
   () =>
      props.required ||
      !!getPropertyByPath(validation.value, props.name)?.required ||
      !!getPropertyByPath(validation.value, props.name)?.requiredIf?.$params?.prop?.(),
);
const disabled = computed(() => props.disabled || formDisabled.value);
const dirty = computed(() => getPropertyByPath(validation.value, props.name)?.$dirty);

const touch = () => {
   if (!validation.value || !props.name) return;
   return getPropertyByPath(validation.value, props.name)?.$touch();
};

provide<FormElementParams>('formElement', {
   name,
   label,
   disabled,
   required,
   invalid,
   dirty,
   touch,
});
</script>

<template>
   <div :class="['form-element', { invalid }]">
      <div :class="['form-element__element', labelPosition]">
         <slot name="label">
            <label v-if="label" :class="['form-element__label', { required }, labelStyle]" :for="name"> {{ label }}</label>
         </slot>
         <slot v-bind="{ name, label, localErrors, invalid, required, disabled }"></slot>
      </div>
      <div v-if="invalid" class="form-element__errors">
         <slot v-for="error in localErrors" :key="error.$uid" :name="error?.$validator">
            <small class="form-element__errors__error">
               {{ error.$message }}
            </small>
         </slot>
      </div>

      <div v-else-if="description" class="form-element__description">
         <slot name="description">
            <small>
               {{ description }}
            </small>
         </slot>
      </div>
   </div>
</template>

<style lang="scss" scoped>
.form-element {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.form-element__element {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
   width: 100%;

   &.left {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: var(--nxt-gutter);
   }

   &.right {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      gap: var(--nxt-gutter);
   }
}

.form-element__label {
   position: relative;

   &.capitalize {
      text-transform: lowercase;

      &::first-letter {
         text-transform: uppercase;
      }
   }

   &.uppercase {
      text-transform: uppercase;
   }

   &.lowercase {
      text-transform: lowercase;
   }
}

.form-element__label.required::after {
   position: absolute;
   content: '*';
   color: var(--nxt-red);
}

.form-element__errors {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-tiny);
   align-items: flex-start;
   margin-top: toRem(5);
}
.form-element__errors__error {
   color: var(--nxt-red);
   font-size: toRem(10);
}

.form-element__description {
   color: var(--nxt-grey);
   margin-top: toRem(5);
}
</style>
