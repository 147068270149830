import validate from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/middleware/auth.global.ts";
import keycloak_45params_45global from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/middleware/keycloak-params.global.ts";
import modal_45global from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/middleware/modal.global.ts";
import navigation_45global from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/middleware/navigation.global.ts";
import manifest_45route_45rule from "/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  keycloak_45params_45global,
  modal_45global,
  navigation_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "refresh-user-info": () => import("/builds/future-harry/nxtpeople-app/nxt-people-candidate-app/middleware/refresh-user-info.ts")
}