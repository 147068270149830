<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
const dayjs = useDayjs();
const { confirmDeleteType } = useModalStore();

defineExpose({ add: () => (showAddCourseModal.value = true) });
// #endregion

// #region Props & Emits
const modelValue = defineModel<Array<PersonCourse>>('modelValue', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region Course Modals
const showAddCourseModal = ref(false);
const showChangeCourseModal = ref(false);
const courseIndexToChange = ref<number>(-1);

function openChangeCourseModal(index: number) {
   courseIndexToChange.value = index;
   showChangeCourseModal.value = true;
}

function closeChangeCourseModal() {
   courseIndexToChange.value = -1;
   showChangeCourseModal.value = false;
}

// #endregion

// #region Courses
function addCourse(course: PersonCourse) {
   modelValue.value.push(course);
   showAddCourseModal.value = false;

   validate();
   touch();
}

function changeCourse(course: PersonCourse) {
   if (courseIndexToChange.value === -1) return;
   modelValue.value[courseIndexToChange.value] = course;
   closeChangeCourseModal();

   validate();
   touch();
}

function removeCourse(index: number) {
   confirmDeleteType(t('profile.courses.course'), () => {
      modelValue.value.splice(index, 1);
      validate();
      touch();
   });
}

// #endregion
</script>

<template>
   <div v-auto-animate class="person-todo-courses">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <div v-if="modelValue.length <= 0" :class="'person-todo-courses__no-courses'">
         <p>
            {{ $t('generic.no-type-add-type', { type: $t('profile.skills.education', 2)?.toLowerCase() }) }}
         </p>
      </div>

      <LayoutCard
         v-for="(course, index) in modelValue"
         :key="course.sync_id || `${course.name} - ${course.organisation} - ${course.from}` || index"
         class="person-todo-courses__course"
      >
         <p class="person-todo-courses__course__dates">
            <small> {{ dayjs(course.from).format('DD/MM/YYYY') }}</small>
            <small v-if="course.till"> - {{ dayjs(course.till).format('DD/MM/YYYY') }}</small>
         </p>

         <p class="person-todo-courses__course__title">
            <strong>{{ course.name }}</strong>
         </p>

         <p class="person-todo-courses__course__organisation">{{ course.organisation }}</p>

         <div class="person-todo-courses__course__actions">
            <ButtonIcon
               :icon-name="'pen'"
               :icon-label="$t('actions.change-type', { type: $t('profile.courses.course') })"
               @on-click="openChangeCourseModal(index)"
            />
            <ButtonIcon
               :icon-name="'trash'"
               :icon-label="$t('actions.delete-type', { type: $t('profile.courses.course') })"
               @on-click="removeCourse(index)"
            />
         </div>
      </LayoutCard>

      <ClientOnly>
         <!-- Create -->
         <Teleport to=".modal-side__card__content">
            <ProfileCourseForm v-if="showAddCourseModal" @on-save="addCourse" @on-cancel="showAddCourseModal = false" />
         </Teleport>

         <!-- Change -->
         <Teleport to=".modal-side__card__content">
            <ProfileCourseForm
               v-if="showChangeCourseModal && courseIndexToChange > -1"
               :course="modelValue[courseIndexToChange]"
               @on-save="changeCourse"
               @on-cancel="closeChangeCourseModal"
            />
         </Teleport>
      </ClientOnly>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-courses__courses {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);

   list-style: none;
   padding: 0;
}

.person-todo-courses__no-courses {
   text-align: center;
}

.person-todo-courses__course {
   display: grid;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto auto;
   grid-template-areas: 'dates actions' 'title actions' 'organisation actions';
   gap: toRem(5) var(--nxt-gutter);

   &__dates {
      grid-area: dates;
      margin: 0;
   }

   &__title {
      grid-area: title;
      margin: 0;

      strong {
         margin-right: var(--nxt-gutter-small);
      }
   }

   &__organisation {
      grid-area: organisation;
      margin: 0;
   }

   &__actions {
      grid-area: actions;
      display: flex;
      gap: var(--nxt-gutter-small);
      justify-content: flex-end;
   }
}
</style>
