<script lang="ts" setup generic="T extends {}">
import type { Validation } from '@vuelidate/core';

// #region Globals
const { addAppStatus } = useAppStatusStore();
const { t } = useI18n();
// #endregion

// #region Props & Emits
const emits = defineEmits<{
   (e: 'onInvalidSubmit', data: any): void;
   (e: 'onValidSubmit', data: any): void;
}>();

const props = defineProps({
   data: { type: Object as PropType<T>, required: true },
   disabled: { type: Boolean, default: false },
   validation: { type: Object as PropType<Validation>, required: true },
});

defineExpose({ handleSubmit });
// #endregion

// #region Provide
const { disabled } = toRefs(props);
provide('disabled', disabled);
// #endregion

// #region Submit

function scrollToFirstInvalidField() {
   try {
      if (process.server) return;

      const firstError = props.validation.$errors.at(0);
      if (!firstError) return;

      const propertyName = firstError.$propertyPath;
      const input =
         (document.querySelector(`[id="${propertyName}"]`) as HTMLElement) ||
         (document.querySelector(`[name="${propertyName}"]`) as HTMLElement);

      if (!input) return;

      input.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' });
   } catch (error) {
      console.warn(`Could not find first invalid field`);
      addAppStatus({ title: t('validations.enter-the-required-data-as-instructed') });
   }
}

async function handleSubmit() {
   const isValid = await props.validation.$validate();

   if (isValid) {
      return emits('onValidSubmit', props.data);
   }

   scrollToFirstInvalidField();

   return emits('onInvalidSubmit', props.data);
}
// #endregion
</script>

<template>
   <form :class="'form-container'" novalidate @submit.prevent="handleSubmit">
      <slot v-bind="props"></slot>
   </form>
</template>
