export enum UnavailabilityTypeKey {
   LEAVE = 'leave',
   ILLNESS = 'illness',
   OTHER = 'other',
}

export interface UnavailabilityType {
   id: number;
   name: string;
   key: UnavailabilityTypeKey;
}

export interface UnavailabilityTypeForm {
   unavailability_type_id?: number;
   unavailability_type?: UnavailabilityType;
   unavailability_reason?: string;
}
