import { PlanningKey } from '#imports';

export function colorContrast(hexcolor: string) {
   const r = parseInt(hexcolor.substr(1, 2), 16);
   const g = parseInt(hexcolor.substr(3, 2), 16);
   const b = parseInt(hexcolor.substr(5, 2), 16);
   const yiq = (r * 299 + g * 587 + b * 114) / 1000;
   return yiq >= 128 ? 'var(--nxt-dark)' : 'var(--nxt)';
}

export function getPlanningRequestColorCodes(planningKey: PlanningKey) {
   switch (planningKey) {
      case PlanningKey.PRESENCE_NO_SHOW:
         return { color: 'var(--nxt-plan-no-show-red)', contrastColor: 'var(--nxt-white)' };
      case PlanningKey.PRESENCE_SHOW:
         return { color: 'var(--nxt-plan-show-blue)', contrastColor: 'var(--nxt-white)' };
      case PlanningKey.PLANNING_CONFIRMATION_SENT:
         return { color: 'var(--nxt-plan-verified-green)', contrastColor: 'var(--nxt-dark)' };
      case PlanningKey.PLANNING_CONFIRMATION_APPROVED:
         return { color: 'var(--nxt-plan-main)', contrastColor: 'var(--nxt-white)' };
      case PlanningKey.PLANNING_RESERVE_SENT:
         return { color: 'var(--nxt-plan-reserve-yellow)', contrastColor: 'var(--nxt-dark)' };
      case PlanningKey.PLANNING_RESERVE_APPROVED:
         return { color: 'var(--nxt-yellow)', contrastColor: 'var(--nxt-dark)' };
      default:
         return { color: 'var(--nxt-plan-verified-green)', contrastColor: 'var(--nxt-dark)' };
   }
}
