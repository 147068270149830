<script lang="ts" setup>
// #region Props & Emits
defineProps<{ baseKey: string }>();

const modelValue = defineModel<Array<PersonDependant>>('modelValue', { default: null });
// #endregion

// #region Dependant Types
const { data: dependantTypes } = await useAuthFetch<Array<DependantType>>(DEPENDANT_TYPE_ENDPOINTS.GET(), cacheConfig);

const personDependants = computed(() => {
   return dependantTypes.value?.map((dependantType) => {
      const personDependant = modelValue.value?.find((personDependant) => personDependant.dependant_type_id === dependantType.id);

      return {
         amount_able_bodied: 0,
         amount_disabled: 0,
         ...personDependant,
         dependant_type_id: dependantType.id,
         dependant_type: dependantType,
      };
   });
});

watch(personDependants, (newPersonDependants, oldPersonDependants) => {
   if (!newPersonDependants || newPersonDependants?.length === oldPersonDependants?.length) return;
   modelValue.value = newPersonDependants;
});

function updatePersonDependant(personDependant: PersonDependant, key: 'amount_able_bodied' | 'amount_disabled', value: number) {
   const index = modelValue.value.findIndex((pd) => pd.dependant_type_id === personDependant.dependant_type_id);

   if (index < 0) {
      return modelValue.value.push({ ...personDependant, [key]: +value });
   }

   return (modelValue.value[index][key] = +value);
}
// #endregion

// #region Validation
function getMax(personDependant: PersonDependant) {
   if (personDependant.dependant_type.key === 'uitzendkracht') {
      return {
         amount_able_bodied: 1,
         amount_disabled: 1,
      };
   }
   if (personDependant.dependant_type.key === 'echtgenoot') {
      return {
         amount_able_bodied: personDependant.amount_disabled > 0 ? 0 : 1,
         amount_disabled: personDependant.amount_able_bodied > 0 ? 0 : 1,
      };
   }
   return undefined;
}
// #endregion
</script>

<template>
   <div class="precognition-form-dependants">
      <div class="precognition-form-dependants__heading">
         <div></div>
         <small>{{ $t('profile.fiscal-data.abled') }}</small>
         <small>{{ $t('profile.fiscal-data.disabled') }}</small>
      </div>
      <div
         v-for="(personDependant, index) in personDependants"
         :key="personDependant.sync_id || personDependant.dependant_type_id"
         class="precognition-form-dependants__dependant-type"
      >
         <span>{{ personDependant.dependant_type.name }}</span>

         <PrecognitionFormElement :name="`${baseKey}.${index}.amount_able_bodied`">
            <PrecognitionFormInput
               v-if="personDependant.dependant_type.key !== 'uitzendkracht'"
               :model-value="personDependant.amount_able_bodied"
               :type="'number'"
               :min="0"
               :max="getMax(personDependant)?.amount_able_bodied"
               @update:model-value="(value: number) => updatePersonDependant(personDependant, 'amount_able_bodied', value)"
            />
         </PrecognitionFormElement>

         <PrecognitionFormElement :name="`${baseKey}.${index}.amount_disabled`">
            <PrecognitionFormInput
               :model-value="personDependant.amount_disabled"
               :type="'number'"
               :min="0"
               :max="getMax(personDependant)?.amount_disabled"
               @update:model-value="(value: number) => updatePersonDependant(personDependant, 'amount_disabled', value)"
            />
         </PrecognitionFormElement>
      </div>
   </div>
</template>

<style lang="scss" scoped>
.precognition-form-dependants {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.precognition-form-dependants__heading {
   display: grid;
   grid-template-columns: 1fr minmax(auto, toRem(80)) minmax(auto, toRem(80));
   gap: var(--nxt-gutter);

   > small {
      display: flex;
      align-items: center;
      justify-content: center;
   }
}

.precognition-form-dependants__dependant-type {
   display: grid;
   grid-template-columns: 1fr minmax(auto, toRem(80)) minmax(auto, toRem(80));
   gap: var(--nxt-gutter);

   &:deep(input) {
      text-align: center;
   }
}
</style>
