<script lang="ts" setup>
// #region Props & Emits
defineProps<{ baseKey: string }>();
const modelValue = defineModel<Array<PersonProfileTimetable>>('modelValue', { default: [] });
// #endregion

// #region Timetables
const { data: timetables } = await useAuthFetch<Array<Timetable>>(TIMETABLE_ENDPOINTS.GET(), cacheConfig);
// #endregion

// #region Person profile timetables
function toggleTimetable(timetable: Timetable) {
   const match = modelValue.value.find((ptm) => ptm.timetable_id === timetable.id);
   if (match) {
      modelValue.value = modelValue.value.filter((ptm) => ptm.timetable_id !== timetable.id);
   } else {
      modelValue.value.push({
         timetable_id: timetable.id,
      });
   }
}

// #endregion
</script>

<template>
   <div class="person-todo-form-timetables">
      <PrecognitionFormElement
         v-for="(timetable, index) in timetables"
         :key="timetable.id"
         :name="`${baseKey}.${index}`"
         :label="timetable.name"
         :label-position="'left'"
      >
         <PrecognitionFormSwitch
            :model-value="!!modelValue.find((ptm) => ptm.timetable_id === timetable.id)"
            @update:model-value="toggleTimetable(timetable)"
         />
      </PrecognitionFormElement>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-form-timetables {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}
</style>
