<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
definePageMeta({ layout: 'none' });
useSeoMeta({ title: t('generic.error') });
// #endregion

// #region Reload
function reloadPage() {
   window.location.replace('/');
}
// #endregion
</script>

<template>
   <div class="error">
      <h1 class="error__title">Oops!</h1>
      <p class="error__message">{{ $t('generic.general-error') }}: <a :href="`mailto:info@nxtpeople.com`">info@nxtpeople.com</a></p>

      <ButtonMain :background-color="'var(--nxt-main)'" @on-click="reloadPage">{{ $t('actions.reload-page') }}</ButtonMain>
   </div>
</template>

<style lang="scss" scoped>
.error {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: 100vh;
   min-height: 100dvh;
   text-align: center;
   max-width: toRem(500);
   margin: 0 auto;
   padding: var(--nxt-gutter-large);

   &__title {
      font-size: 3rem;
      margin-bottom: 1rem;
   }

   &__message {
      margin-bottom: 2rem;
   }

   a {
      color: var(--nxt-main);
   }
}
</style>
