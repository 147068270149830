<script lang="ts">
type SpinnerSizes = 'tiny' | 'small' | 'large' | 'giant';

export default defineComponent({
   name: 'NxtSpinner',

   props: {
      iconName: { type: String, default: 'spinner' },
      size: { type: String as PropType<SpinnerSizes>, default: null },
   },
});
</script>

<template>
   <div :class="['spinner spin', `-${size}`]">
      <i :class="['far fa-spin', `fa-${iconName}`]"></i>
   </div>
</template>

<style lang="scss">
.spinner {
   // Positioning

   // Box-model
   display: flex;
   width: fit-content;

   // Typography
   font-size: 2rem;
   color: var(--nxt-main);

   // Visual

   // Misc
   &.-tiny i {
      font-size: 1rem;
   }
   &.-small i {
      font-size: 1.5rem;
   }
   &.-large i {
      font-size: 2rem;
   }
   &.-giant i {
      font-size: 5rem;
   }

   i {
      width: unset;
   }
}
</style>
