import * as Sentry from '@sentry/vue';
import { browserTracingIntegration } from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
   const { vueApp } = nuxtApp;
   const { appEnv, sentryDsn, apiBaseUrl } = useRuntimeConfig().public;

   const browserTracing = browserTracingIntegration({ router: useRouter() });

   Sentry.init({
      app: vueApp,
      dsn: sentryDsn,
      environment: appEnv,
      integrations: [browserTracing],
      tracesSampleRate: 0.1,
      logErrors: true,
      trackComponents: true,
      attachProps: true,
      attachStacktrace: true,
      maxBreadcrumbs: 50,
      release: process.env.APP_VERSION,
      tracePropagationTargets: [window.location.origin, apiBaseUrl],
      allowUrls: [window.location.origin, /^\//],
      ignoreErrors: [
         /Load Failed/,
         /AbortError/,
         /Unable to preload CSS/,
         /Importing a module script failed/,
         /Failed to fetch/,
         /Request failed with status code 422/,
      ],
   });

   return {
      provide: {
         sentrySetContext: Sentry.setContext,
         sentrySetUser: Sentry.setUser,
         sentrySetTag: Sentry.setTag,
         sentryAddBreadcrumb: Sentry.addBreadcrumb,
         sentryCaptureException: Sentry.captureException,
         sentryCaptureEvent: Sentry.captureEvent,
      },
   };
});
