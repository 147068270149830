<script lang="ts" setup>
// #region Globals
const { name, required, disabled, label, invalid, touch } = useFormElement();
// #endregion

// #region Props & Emits
defineProps({
   placeholder: { type: String, default: '' },
   min: { type: String, default: null },
   max: { type: String, default: null },
});
// #endregion

// #region ModelValue

const modelValue = defineModel<string>('modelValue');

function handleInput(e: Event) {
   if (touch) touch();
   const target = e.target as HTMLInputElement;
   const value = target.value;
   modelValue.value = value;
}
// #endregion
</script>

<template>
   <input
      :id="name"
      v-model="modelValue"
      :class="['timepicker', { invalid }]"
      :disabled="disabled"
      :name="name"
      :required="required"
      :type="'time'"
      :min="min"
      :max="max"
      :tabindex="-1"
      :placeholder="placeholder || label || $t('actions.select')"
      @input="handleInput"
   />
</template>

<style lang="scss" scoped>
.timepicker {
   appearance: none;
   text-align: start;
   width: 100%;
   height: toRem(40);
   border-radius: var(--nxt-radius);
}
</style>
