<script lang="ts" setup>
// #region Globals
const dayjs = useDayjs();
// #endregion

// #region Props & Emits
const emits = defineEmits<{
   (e: 'update:modelValue', availability: Availability): void;
}>();
const props = defineProps({
   modelValue: { type: Object as PropType<Availability>, default: null },
   dayPlanning: { type: Object as PropType<Planning>, default: null },
});
// #endregion

// #region Day availability form
const showDayAvailabilityForm = ref(false);

function applyDayAvailability(dayAvailability: Availability) {
   emits('update:modelValue', dayAvailability);
   showDayAvailabilityForm.value = false;
}

// #endregion

// #region Day info
const day = computed(() => {
   const date = props.modelValue?.date;
   return dayjs(date);
});

const classes = computed(() => {
   const availability = props.modelValue;
   const hasStartAndEndTime = availability?.start_time && availability?.end_time;

   const planning = props.dayPlanning;
   return {
      unavailable: availability?.is_available === false,
      partially_available: availability?.is_available === true && hasStartAndEndTime,
      available: availability?.is_available === true && !hasStartAndEndTime,
      presence_show: planning?.key === PlanningKey.PRESENCE_SHOW,
      presence_no_show: planning?.key === PlanningKey.PRESENCE_NO_SHOW,
      planning_confirmation_sent: planning?.key === PlanningKey.PLANNING_CONFIRMATION_SENT,
      planning_confirmation_approved: planning?.key === PlanningKey.PLANNING_CONFIRMATION_APPROVED,
      planning_reserve_sent: planning?.key === PlanningKey.PLANNING_RESERVE_SENT,
      planning_reserve_approved: planning?.key === PlanningKey.PLANNING_RESERVE_APPROVED,
   };
});
// #endregion

// #region Disabled
const isEditable = computed(() => day.value.isAfter(dayjs(), 'day'));
const isPast = computed(() => day.value.isBefore(dayjs(), 'day'));

const hasPlanningRequest = computed(() => {
   const planning = props.dayPlanning;
   const PlanningRequestKeys = [
      PlanningKey.PLANNING_CONFIRMATION_SENT,
      PlanningKey.PLANNING_RESERVE_SENT,
      PlanningKey.PLANNING_RESERVE_APPROVED,
      PlanningKey.PRESENCE_SHOW,
      PlanningKey.PLANNING_CONFIRMATION_APPROVED,
   ];
   return PlanningRequestKeys.includes(planning?.key);
});
// #endregion

// #region Change availability
const showUnavailabilityModal = ref(false);

function changeAvailability(value?: boolean) {
   if (value === false && hasPlanningRequest.value) {
      return (showUnavailabilityModal.value = true);
   }

   return emits('update:modelValue', {
      ...props.modelValue,
      is_available: value,
      unavailability_reason: undefined,
      unavailability_type_id: undefined,
      unavailability_type: undefined,
      start_time: value === false ? undefined : props.modelValue?.start_time,
      end_time: value === false ? undefined : props.modelValue?.end_time,
   });
}

function applyUnavailability(availability: Availability) {
   emits('update:modelValue', {
      ...availability,
      is_available: false,
      start_time: undefined,
      end_time: undefined,
   });
   showUnavailabilityModal.value = false;
}
// #endregion
</script>

<template>
   <div :class="['quick-day-availability', { '-passed': !isEditable }]">
      <div class="quick-day-availability__date">
         <p>{{ day.format('dd') }}</p>
         <LayoutDay :show-day-of-week="false" :status="classes">{{ day.date() }}</LayoutDay>
         <p>{{ day.format('MMM') }}</p>
      </div>

      <div class="quick-day-availability__status">
         <!-- Partially Available -->
         <div v-if="modelValue.is_available === true && modelValue.start_time">
            <span v-if="!isPast">
               {{ $t('schedules.status.you-are-partially-available', { start: modelValue.start_time, end: modelValue.end_time }) }}
            </span>
            <span v-else>
               {{ $t('schedules.status.you-were-partially-available', { start: modelValue.start_time, end: modelValue.end_time }) }}
            </span>
         </div>

         <!-- Available -->
         <div v-else-if="modelValue.is_available === true">
            <span v-if="!isPast">{{ $t('schedules.status.you-are-available') }}</span>
            <span v-else>{{ $t('schedules.status.you-were-available') }}</span>
         </div>

         <!-- Unavailable -->
         <div v-else-if="modelValue.is_available === false">
            <span v-if="!isPast">{{ $t('schedules.status.you-are-unavailable') }}</span>
            <span v-else>{{ $t('schedules.status.you-were-unavailable') }}</span>
         </div>

         <!-- No info -->
         <div v-else>
            <span>{{ $t('schedules.no-info') }}</span>
         </div>
      </div>

      <div class="quick-day-availability__actions">
         <FormElement :name="`day_availability`">
            <FormButtonToggle
               v-if="isEditable"
               :model-value="modelValue?.is_available"
               :partial="!!(modelValue?.start_time && modelValue?.end_time)"
               class="quick-day-availability__availability"
               @update:model-value="changeAvailability"
            />
         </FormElement>

         <ButtonIcon
            v-if="isEditable"
            class="quick-day-availability__change"
            :icon-name="'pen'"
            :icon-label="$t('actions.change')"
            @on-click="showDayAvailabilityForm = true"
         />
      </div>

      <ClientOnly>
         <Teleport to=".modal-side__card__content">
            <ScheduleUnavailableForm
               v-if="showUnavailabilityModal"
               :availability="modelValue"
               @on-cancel="showUnavailabilityModal = false"
               @on-save="applyUnavailability"
            />
         </Teleport>
      </ClientOnly>

      <ClientOnly>
         <Teleport to=".modal-side__card__content">
            <ScheduleDayAvailabilityForm
               v-if="showDayAvailabilityForm"
               :day="modelValue"
               :date="day.date()"
               @on-cancel="showDayAvailabilityForm = false"
               @on-confirm="applyDayAvailability"
            />
         </Teleport>
      </ClientOnly>
   </div>
</template>

<style lang="scss" scoped>
.quick-day-availability {
   display: grid;
   grid-template-areas: 'date status actions';
   grid-template-columns: auto 1fr auto;
   gap: var(--nxt-gutter);

   width: 100%;

   &__date {
      grid-area: date;
   }
   &__status {
      grid-area: status;
   }
   &__actions {
      grid-area: actions;
   }

   &.-passed {
      opacity: 0.4;
   }
}

.quick-day-availability__date {
   display: grid;
   grid-template-columns: toRem(20) auto toRem(28);
   gap: var(--nxt-gutter-small);
   align-items: center;

   > *:first-of-type {
      text-transform: capitalize;
   }
   p {
      margin: 0;
   }
}

.quick-day-availability__actions {
   justify-self: flex-end;
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

.quick-day-availability__status {
   margin: 0;
   display: flex;
   align-items: center;

   > * {
      display: flex;
      align-items: center;
      gap: var(--nxt-gutter-small);
   }
}
</style>
