<script setup lang="ts">
// #region Globals
const { disabled, name, touch } = useFormElement();
// #endregion

// #region Props & Emits
defineProps({
   label: { type: String, default: '' },
});

const modelValue = defineModel<boolean>('modelValue', { default: false });
// #endregion

// #region ModelValue
function toggle() {
   if (disabled.value) return;
   if (touch) touch();
   modelValue.value = !modelValue.value;
}
// #endregion
</script>

<template>
   <div class="switch" :class="{ disabled, checked: !!modelValue }" @click="toggle">
      <div class="switch__handle"></div>
      <input :id="name" :name="name" class="hide-input" :value="modelValue" readonly @input="toggle" />
   </div>
</template>

<style lang="scss" scoped>
.switch {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: toRem(4);
   height: toRem(28);
   border-radius: toRem(20);
   background: var(--nxt-light-grey);
   cursor: pointer;
   width: toRem(50);
   min-width: toRem(50);
   box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.switch.disabled {
   opacity: 0.5;
   cursor: not-allowed;
}

.switch.checked {
   background: var(--nxt-green);

   .switch__handle {
      transform: translateX(toRem(22));
   }
}

.switch__handle {
   display: flex;
   justify-content: center;
   align-items: center;
   width: toRem(21);
   height: toRem(21);
   background: white;
   border-radius: 50%;
   transition: all 0.2s ease-in-out;

   i {
      font-size: 1.2rem;
   }
}
</style>
