<script lang="ts" setup generic="T">
import { FontColorContraster as vContrast } from '~/directives/color-contrast.directive';

// #region Globals
const searchRef = ref<HTMLInputElement>();
const { t } = useI18n();
const { name, invalid, disabled, label } = useFormElement();
// #endregion

// #region Props & Emits
const emits = defineEmits<{ (e: 'onCustomSearch', searchValue: string): void }>();
const props = defineProps({
   options: { type: Array as PropType<Array<T>>, default: () => [] },
   valueKey: { type: String, default: 'id' },
   labelKey: { type: String, default: 'name' },
   searchType: { type: String as PropType<'custom' | 'internal' | 'none'>, default: 'internal' },
   searchUrl: { type: String, default: null },
   optionsToLoadInitially: { type: Number, default: 25 },
   placeholder: { type: String, default: null },
});
// #endregion

// #region ModelValue
const modelValue = defineModel<Array<T>>('modelValue', { default: [] });
const tempModelValue = ref<Array<T>>([]) as Ref<Array<T>>;

const mappedModelValue = computed(() =>
   modelValue.value.map((o) => ({
      ...o,
      labelKey: getLabelKey(o),
      valueKey: getValueKey(o),
   })),
);

const mappedTempModelValue = computed(() =>
   tempModelValue.value.map((o) => ({
      ...o,
      labelKey: getLabelKey(o),
      valueKey: getValueKey(o),
   })),
);

function addOption(option: T, soft?: boolean) {
   if (soft) tempModelValue.value.push(option);
   else modelValue.value.push(option);
}

function removeOption(option: T, soft?: boolean) {
   if (soft) tempModelValue.value = tempModelValue.value.filter((o) => getValueKey(o) !== getValueKey(option));
   else modelValue.value = modelValue.value.filter((o) => getValueKey(o) !== getValueKey(option));
}

function toggleOption(option: T, soft?: boolean) {
   if ((soft ? tempModelValue.value : modelValue.value).find((o) => getValueKey(o) === getValueKey(option))) {
      removeOption(option, soft);
   } else {
      addOption(option, soft);
   }
}

function updateTempModelValue() {
   tempModelValue.value = JSON.parse(JSON.stringify(modelValue.value));
}

function addOptions() {
   modelValue.value = tempModelValue.value;
   isOpen.value = false;
}

watchEffect(updateTempModelValue);
// #endregion

// #region Options
const searchValue = ref('');
const filteredOptions = ref<Array<T>>([]) as Ref<Array<T>>;
watch(filteredOptions, () => searchRef.value?.scrollIntoView({ block: 'nearest' }));

const searchTimout = ref<NodeJS.Timeout>();
const loading = ref(false);

const mappedOptions = computed(() =>
   filteredOptions.value.map((fo) => ({
      ...fo,
      labelKey: getLabelKey(fo),
      valueKey: getValueKey(fo),
   })),
);

function getValueKey(option: T) {
   return getPropertyByPath(option, props.valueKey);
}

function getLabelKey(option: T) {
   return getPropertyByPath(option, props.labelKey);
}

function fetchOptions(searchValue: string, per_page: number = 100) {
   loading.value = true;

   if (searchTimout.value) clearTimeout(searchTimout.value);

   searchTimout.value = setTimeout(async () => {
      try {
         const query = { search: searchValue.trim() || undefined, per_page };
         const response = await authFetch<any>(props.searchUrl, { query });

         if (Array.isArray(response?.data)) {
            filteredOptions.value = response.data;
         }
         if (Array.isArray(response)) {
            filteredOptions.value = response;
         }
      } finally {
         loading.value = false;
      }
   }, 300);
}

function filterOptions(searchValue: string) {
   if (props.searchType === 'internal' && searchValue) {
      filteredOptions.value = props.options.filter((o) => getLabelKey(o).toLowerCase().includes(searchValue.toLowerCase()));
   } else if (props.searchType === 'custom') {
      if (props.searchUrl) fetchOptions(searchValue);
      else emits('onCustomSearch', searchValue);
   } else {
      filteredOptions.value = props.options;
   }
}

watch(searchValue, filterOptions);
onMounted(() => {
   if (props.searchUrl && props.optionsToLoadInitially) {
      fetchOptions(searchValue.value, props.optionsToLoadInitially);
   } else {
      filterOptions(searchValue.value);
   }
});
// #endregion

// #region Dropdown
const isOpen = ref(false);

watch(isOpen, (isOpen, oldValue) => {
   if (isOpen && oldValue !== undefined) {
      setTimeout(async () => {
         await nextTick();
         searchRef.value?.focus();
      }, 300);
   }
});

// #endregion
</script>

<template>
   <div :class="['multi-select', { disabled }]">
      <input type="hidden" :name="name" :value="modelValue" @focus="isOpen = true" />

      <div
         :tabindex="0"
         :class="['multi-select__input', { invalid, disabled, '-open': isOpen }]"
         @click="isOpen = true"
         @keydown.enter="isOpen = true"
      >
         <slot v-if="modelValue.length" name="tags">
            <div class="multi-select__input__tags">
               <slot v-for="option in mappedModelValue" :key="option.valueKey" name="tag">
                  <div class="multi-select__input__tag">
                     {{ option.labelKey }}

                     <ButtonIcon
                        class="multi-select__input__tag__delete"
                        :icon-name="'times'"
                        :icon-style="{ fontSize: '.9rem' }"
                        :icon-label="$t('actions.delete-type', { type: $t('profile.job-preferences.job-types') })"
                        @on-click="removeOption(option)"
                     />
                  </div>
               </slot>
            </div>
         </slot>

         <div v-else class="multi-select__input__placeholder">
            {{ placeholder || label || t('actions.select') }}
         </div>

         <ButtonIcon class="multi-select__input__chevron" :icon-name="'chevron-down'" @on-click="isOpen = !isOpen" />
      </div>

      <Teleport v-if="isOpen" to=".app">
         <ModalSide
            :title="placeholder || label || $t('actions.select')"
            :class="'multi-select__options-modal'"
            :min-height="'var(--nxt-modal-layer-3)'"
            :height="'var(--nxt-modal-layer-3)'"
            :width="'25vw'"
            @on-close="isOpen = false"
         >
            <div class="multi-select__options-modal__search">
               <input ref="searchRef" v-model="searchValue" :placeholder="$t('actions.search')" />
            </div>

            <ul v-if="mappedOptions.length" :class="'multi-select__options'">
               <li
                  v-for="option in mappedOptions"
                  :key="option.valueKey"
                  v-contrast
                  :class="['multi-select__option', { '-selected': !!mappedTempModelValue.find((mv) => mv.valueKey === option.valueKey) }]"
                  @click="toggleOption(option, true)"
               >
                  <slot name="option">{{ option.labelKey }}</slot>
               </li>
            </ul>

            <div v-else class="multi-select__options-modal__no-options">
               <p>{{ $t('generic.no-options') }}.</p>
               <ButtonLink @on-click="searchValue = ''">{{ $t('actions.clear-search') }}.</ButtonLink>
            </div>

            <template #actions="{ onClose }">
               <ButtonLink @on-click="onClose">
                  {{ $t('actions.cancel') }}
               </ButtonLink>

               <ButtonMain :icon-name="'check'" :icon-position="'left'" @on-click="addOptions">
                  {{ $t('actions.select', 2) }}
               </ButtonMain>
            </template>
         </ModalSide>
      </Teleport>
   </div>
</template>

<style lang="scss" scoped>
.multi-select {
   width: 100%;
}

.multi-select__input {
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: var(--nxt-gutter);
   padding: var(--nxt-gutter-small);
   border: 1px solid var(--nxt-grey);
   border-radius: var(--nxt-border-radius);
   background-color: var(--nxt-extralight-grey);
   border-radius: var(--nxt-radius);
   min-height: toRem(40);
   cursor: pointer;

   &.-open {
      .multi-select__input__chevron {
         transform: rotate(180deg);
      }
   }
}

.multi-select__input__placeholder {
   color: var(--nxt-medium-grey);
}

.multi-select__input__chevron {
   margin: var(--nxt-gutter-small--negative);
}

.multi-select__input__tags {
   display: flex;
   flex-wrap: wrap;
   gap: var(--nxt-gutter-small);
}

.multi-select__input__tag {
   display: flex;
   align-items: center;
   padding: toRem(2) var(--nxt-gutter-small);
   border-radius: var(--nxt-radius);
   gap: var(--nxt-gutter);
   background-color: var(--nxt-main);
   color: var(--nxt-white);
   font-size: var(--nxt-font-small);
}

.multi-select__input__tag__delete {
   color: var(--nxt-light-grey);
   padding: 0;
}

.multi-select__options-modal {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.multi-select__options-modal__search {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter);
   position: sticky;
   top: 0;
   background-color: var(--nxt-white);
   z-index: var(--zindex-sticky);
   margin: 0 var(--nxt-gutter--negative);
   padding: var(--nxt-gutter);
}

.multi-select__options {
   display: flex;
   flex-direction: column;
   gap: toRem(2);
   margin: 0;
   padding: 0;
   list-style: none;
}

.multi-select__option {
   padding: var(--nxt-gutter);
   border: 1px solid var(--nxt-color-gray-300);
   border-radius: var(--nxt-border-radius);
   cursor: pointer;

   &.-selected {
      background-color: var(--nxt-main);
      color: var(--nxt-white);
   }
}

.multi-select__option:not(.-selected):hover {
   background-color: var(--nxt-main-highlight);
   color: var(--nxt-dark);
}

.multi-select__options-modal__no-options {
   display: flex;
   align-items: center;
}
</style>
