<script lang="ts" setup>
import { usePerformanceCodes } from '../../composables/usePerformanceCodes';

// #region Globals
const dayjs = useDayjs();
// #endregion

// #region Props & Emits
defineEmits<{
   (e: 'update:modelValue', value: boolean): void;
   (e: 'onRemoveTimeRegistration'): void;
}>();
const props = defineProps({
   planningRequestSlot: { type: Object as PropType<PlanningRequestSlot>, required: true },
   showDeleteTimeRegistration: { type: Boolean, default: false },
   backgroundColor: { type: String, default: 'var(--nxt-extralight-grey)' },
});
// #endregion

// #region Day
const day = computed(() => dayjs(slot.value.date));
const slot = computed(() => props.planningRequestSlot);

const dayStatus = computed(() => {
   const isAvailable = slot.value?.is_available;
   const presence_show = slot.value.slot_state?.key === PlanningKey.PRESENCE_SHOW;
   const presence_no_show = slot.value.slot_state?.key === PlanningKey.PRESENCE_NO_SHOW;
   const planning_confirmation_approved = slot.value.slot_state?.key === PlanningKey.PLANNING_CONFIRMATION_APPROVED;
   const planning_confirmation_sent = slot.value.slot_state?.key === PlanningKey.PLANNING_CONFIRMATION_SENT;
   const planning_reserve_approved = slot.value.slot_state?.key === PlanningKey.PLANNING_RESERVE_APPROVED;
   const planning_reserve_sent = slot.value.slot_state?.key === PlanningKey.PLANNING_RESERVE_SENT;

   return {
      unavailable: isAvailable === false,
      available: isAvailable === true,
      presence_show,
      presence_no_show,
      planning_confirmation_sent,
      planning_confirmation_approved,
      planning_reserve_sent,
      planning_reserve_approved,
   };
});
// #endregion

// #region Performance Code
const { performanceCodes } = usePerformanceCodes(slot);
// #endregion
</script>

<template>
   <LayoutCard :class="'toggle-day-card'" :background-color="backgroundColor">
      <div class="toggle-day-card__content">
         <!-- Header -->
         <div class="toggle-day-card__heading">
            <ScheduleDayHeader class="toggle-day-card__header" :day="day" :day-status="dayStatus" />

            <div class="toggle-day-card__tags">
               <StatusHoverCard v-for="tag in slot.tags ?? []" :key="tag.id" :background-color="tag.tag_type?.color ?? 'var(--nxt-dark)'">
                  <small>{{ tag.abbreviation }}</small>

                  <template #content>
                     <span> {{ tag.name }}</span>
                  </template>
               </StatusHoverCard>
            </div>

            <div v-if="performanceCodes?.length" class="toggle-day-card__status">
               <StatusHoverCard
                  v-for="performanceCode in performanceCodes"
                  :key="performanceCode.key"
                  :overlap="false"
                  :background-color="performanceCode?.color"
                  :initial-visible="true"
                  :outside-click="false"
                  :clickable="false"
               >
                  <i :class="performanceCode.icon_key"></i>

                  <template #content>
                     {{ performanceCode.name }}
                  </template>
               </StatusHoverCard>
            </div>
         </div>

         <!-- Content -->
         <div class="toggle-day-card__rows">
            <PlanningRequestSlot
               :planning-request-slot="slot"
               :show-approved-hours="true"
               :show-delete-time-registration
               @on-remove-time-registration="$emit('onRemoveTimeRegistration')"
            />

            <slot></slot>
         </div>
      </div>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.toggle-day-card__content {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}

.toggle-day-card__heading {
   display: grid;
   grid-template-columns: 1fr auto auto;
   gap: var(--nxt-gutter-small);
}

.toggle-day-card__header {
   width: 100%;
}

.toggle-day-card__tags {
   display: flex;
   gap: var(--nxt-gutter-small);
   align-items: center;
}

.toggle-day-card__status {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}
</style>
