<script lang="ts" setup>
import { Dayjs } from 'dayjs';

// #region Props & Emits
const emits = defineEmits<{ (e: 'onChangeActiveDate', date: string): void }>();
const props = defineProps({
   showDaysOfWeek: { type: Boolean, default: true },
   activeDate: { type: Object as PropType<Dayjs>, default: null },
   activeDateFormatted: { type: String, default: null },
   weekStartDate: { type: Object as PropType<Dayjs>, required: true },
   weekEndDate: { type: Object as PropType<Dayjs>, required: true },
   schedule: { type: Object as PropType<Schedule>, default: null },
});
// #endregion

// #region Days
provide('schedule', toRef(props, 'schedule'));

const days = computed(() =>
   [...Array(7)].map((_, index) => {
      const curDate = props.weekStartDate.add(index, 'days');
      const date = curDate.format('YYYY-MM-DD');
      return { number: curDate.date(), date };
   }),
);
// #endregion
</script>

<template>
   <div class="week-calendar">
      <ScheduleCalendarDay
         v-for="(day, index) in days"
         :key="`day-${index}`"
         :show-day-of-week="showDaysOfWeek"
         :number="day.number"
         :date="day.date"
         :active-date-formatted="activeDateFormatted"
         @on-change-active-date="emits('onChangeActiveDate', $event)"
      />
   </div>
</template>

<style lang="scss">
.week-calendar {
   display: grid;
   grid-template-columns: repeat(7, calc(100% / 7 + 1px));
   gap: toRem(10) 0;
   width: 100%;

   > * {
      margin: 0 -1px;
   }

   * {
      margin: 0 -1px;
   }
   > *:first-of-type {
      margin-left: 1px;
   }

   > *:last-of-type {
      margin-right: 1px;
   }
}
</style>
