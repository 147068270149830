<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
const { confirmDeleteType } = useModalStore();

defineExpose({ add: () => (showAddLanguageModal.value = true) });
// #endregion

// #region Props & Emits
const modelValue = defineModel<Array<PersonProfileLanguage>>('modelValue', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region Modals
const showAddLanguageModal = ref(false);
// #endregion

// #region Form
function addLanguage(language: PersonProfileLanguage) {
   const languageAlreadyExists = modelValue.value.find((l) => l.language_id === language.language_id);
   if (languageAlreadyExists) {
      const updatedLanguage = { ...languageAlreadyExists, ...language };
      const index = modelValue.value.findIndex((l) => l.language_id === language.language_id);
      modelValue.value.splice(index, 1, updatedLanguage);
   } else {
      modelValue.value.push(language);
   }
   showAddLanguageModal.value = false;
   validate();
   touch();
}

function removeLanguage(index: number) {
   confirmDeleteType(t('generic.language'), () => {
      modelValue.value.splice(index, 1);
      validate();
      touch();
   });
}
// #endregion

// #region Score
const { data: scores } = useAuthFetch<Array<Score>>(SCORE_ENDPOINTS.GET(), {});
// #endregion

// #region Methods
function updateScore(index: number, value: number) {
   const score = scores.value?.find((s) => s.value === value);
   if (!score) return;
   modelValue.value[index].score_id = score.id;
   modelValue.value[index].score = score;

   validate();
   touch();
}
// #endregion
</script>

<template>
   <div :class="'person-todo-form-languages'">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <div v-if="modelValue.length <= 0" class="person-todo-form-languages__no-languages">
         <p>
            {{ $t('generic.no-type-add-type', { type: $t('generic.language', 2)?.toLowerCase() }) }}
         </p>
      </div>

      <div v-else v-auto-animate class="person-todo-form-languages__languages">
         <div
            v-for="(personLanguage, index) in modelValue"
            :key="personLanguage.language_id"
            class="person-todo-form-languages__languages__language"
         >
            <p>{{ personLanguage.language?.name }}</p>
            <PrecognitionFormElement :name="`profile.languages.${index}.score_id`">
               <FormSlider
                  :model-value="scores?.find((s) => s.id === personLanguage.score_id)?.value || 0"
                  :min="1"
                  :max="4"
                  :step="1"
                  :show-value="true"
                  @update:model-value="updateScore(index, $event)"
               />
            </PrecognitionFormElement>

            <ButtonIcon
               :icon-name="'times'"
               :icon-label="$t('actions.delete-type', { type: $t('generic.language') })"
               @on-click="removeLanguage(index)"
            />
         </div>
      </div>

      <ProfileLanguageForm v-if="showAddLanguageModal" @on-cancel="showAddLanguageModal = false" @on-save="addLanguage" />
   </div>
</template>

<style lang="scss" scoped>
.person-todo-form-languages {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}
.person-todo-form-languages__no-languages {
   text-align: center;
}

.person-todo-form-languages__languages {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.person-todo-form-languages__languages__language {
   display: grid;
   grid-template-columns: toRem(100) 1fr toRem(30);
   align-items: center;
   gap: var(--nxt-gutter);
}
</style>
