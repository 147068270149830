import { storeToRefs } from 'pinia';

export default defineNuxtRouteMiddleware(async (to) => {
   const isPublicPath = !!PUBLIC_ROUTE_NAMES.find((prn) => to.name?.toString().startsWith(prn));
   const userInfoStore = useUserInfoStore();
   const { loadingUserInfo, userInfo } = storeToRefs(userInfoStore);
   const appStore = useAppStore();
   const { onlineStatus } = storeToRefs(appStore);

   // skip middleware if we are on the server or if we are on a public path
   if (process.server || isPublicPath) return;

   if (onlineStatus.value.offline) {
      if (!userInfo.value) userInfoStore.getUserInfo();

      return;
   }

   const keycloakStore = useKeycloakStore();
   const { keycloakAuthenticated, keycloak } = storeToRefs(keycloakStore);
   if (!keycloakAuthenticated.value && process.server) return;

   await keycloakStore.waitOnKeycloak().then(() => {
      if (!keycloakAuthenticated.value) return keycloak.value?.login();

      // async fetching the user info
      if (!userInfo.value && !loadingUserInfo.value) userInfoStore.getUserInfo();
   });
});
