export enum PlanningKey {
   PLANNING_CONFIRMATION_SENT = 'planning-confirmation-sent',
   PLANNING_CONFIRMATION_APPROVED = 'planning-confirmation-approved',
   PLANNING_RESERVE_APPROVED = 'planning-reserve-approved',
   PLANNING_RESERVE_SENT = 'planning-reserve-sent',
   PRESENCE_SHOW = 'presence-show',
   PRESENCE_NO_SHOW = 'presence-no-show',
}

export interface Planning {
   key: PlanningKey;
   sync_id?: string;
}
