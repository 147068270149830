export function usePersonTodoForm(personTodo: Ref<PersonTodo>) {
   // #region Globals
   const userInfoStore = useUserInfoStore();
   const { userInfo } = storeToRefs(userInfoStore);
   const person = computed(() => userInfo.value?.person);
   // #endregion

   // #region PersonTodo
   const { execute: confirmPersonTodo, error } = useAuthFetch(PERSON_TODO_ENDPOINTS.COMPLETE(personTodo.value.sync_id), {
      lazy: true,
      immediate: false,
      method: 'post',
   });

   const customSections = [
      'courses',
      'work_experiences',
      'job_distances',
      'communication',
      'profile.job_functions',
      'profile.job_types',
      'profile.competences',
      'profile.certificates',
      'profile.educations',
      'profile.work_experiences',
      'profile.languages',
      'profile.drivers_licenses',
   ];

   const tabs = computed(() =>
      (personTodo.value?.chapter.tabs ?? []).map((tab) => ({
         ...tab,
         sections: tab.sections.map((section) => {
            const isCustomSection = !!section.questions.find((question) => customSections.includes(question.key));
            return {
               ...section,
               isCustomSection,
            };
         }),
      })),
   );

   const sections = computed(() => {
      return tabs.value.flatMap((tab) => tab.sections);
   });
   // #endregion

   // #region Form
   const resources = ref<{ [key: string]: Array<any> }>({});

   async function getResource(resource_endpoint: string) {
      const data = await authFetch<any>(`/${resource_endpoint}`, cacheConfig);

      if (Array.isArray(data)) {
         resources.value[resource_endpoint] = data;
      }

      if (Array.isArray(data?.data)) {
         resources.value[resource_endpoint] = data.data;
      }
   }

   const initialValues = computed(() => {
      let values: Partial<Person> = {};
      for (const section of sections.value) {
         for (const question of section.questions) {
            if (question.key.startsWith('profile') && !values.profile) {
               values.profile = {};
            }

            if (question.key.includes('drivers_licenses')) {
               values = { ...values, profile: { transport_methods: person.value?.profile?.transport_methods || [], ...values.profile } };
            }

            if (customSections.includes(question.key)) {
               updatePropertyByPath(values, question.key, getPropertyByPath(person.value, question.key) || []);
            } else {
               updatePropertyByPath(values, question.key, getPropertyByPath(person.value, question.key));
            }

            if (question.resource_endpoint) getResource(question.resource_endpoint);
         }
      }
      return values;
   });

   const { form } = usePrecognitionForm<Partial<Person>>({
      url: PERSON_ENDPOINTS.PATCH(),
      initialValues: { ...initialValues.value, profile_picture_url: undefined },
      auth: true,
   });

   const allFieldKeys = computed(() => sections.value.flatMap((section) => section.questions.map((question) => question.key)));

   const hasChanges = computed(() => allFieldKeys.value.some((key) => form.touched(key)));
   // #endregion

   return {
      person,
      personTodo,
      confirmPersonTodo,
      error,
      tabs,
      sections,
      resources,
      form,
      hasChanges,
   };
}
