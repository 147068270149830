<script lang="ts" setup>
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { Mask } from 'maska';
import type { SocialSecurityNumber } from '~/models/social-security-number';

type CountryType = Pick<Country, 'id' | 'iso_code_2' | 'name' | 'ssn_mask' | 'ssn_label'>;

// #region Props & Emits
const emits = defineEmits<{
   (e: 'update:modelValue', socialSecurityNumbers: Array<SocialSecurityNumber>): void;
   (e: 'onBlur', value?: any): void;
}>();

const props = defineProps({
   modelValue: { type: Array as PropType<Array<SocialSecurityNumber>>, default: () => [] },
});
// #endregion

// #region Countries
const { data } = useAuthFetch<{ data: Array<Country> }>(SSN_COUNTRY_ENDPOINTS.GET(), cacheConfig);
const countries = computed(() => data.value?.data || []);
// #endregion

// #region Active Country
const activeCountryId = ref(countries.value?.[0]?.id);
const activeCountry = computed(() => countries.value?.find((country) => country?.id === activeCountryId.value));

function updateActiveCountry([socialSecurityNumbers, countries]: [Array<SocialSecurityNumber>, Array<CountryType>]) {
   const modelValueCountry = socialSecurityNumbers.find((ssn) => ssn.country_id === activeCountryId.value);
   if (modelValueCountry) return;
   const firstCountryForModelValue = countries?.find((country) => country?.id === socialSecurityNumbers?.[0]?.country_id);
   if (firstCountryForModelValue) {
      activeCountryId.value = firstCountryForModelValue.id;
      return;
   }

   if (socialSecurityNumbers.length) if (countries?.find((country) => country?.id === activeCountryId.value)) return;
   if (!countries?.length) return;
   activeCountryId.value = countries?.[0]?.id;
}

watch([() => props.modelValue, () => countries.value], updateActiveCountry, { immediate: true });

// #endregion

// #region Mask
const mask = ref(new Mask({ mask: '' }));
function updateMask(country?: Country) {
   if (!country?.ssn_mask) {
      mask.value = new Mask({ mask: '' });
   } else {
      mask.value = new Mask({ mask: country.ssn_mask });
   }
}

watch(activeCountry, updateMask);
// #endregion

// #region Model Value
const activeSocialSecurityNumber = computed(() => props.modelValue?.find((ssn) => ssn.country_id === activeCountryId.value));

function setSocialSecurityNumber(value?: string) {
   const socialSecurityNumbersIndex = (props.modelValue || []).findIndex((ssn) => ssn.country_id === activeCountryId.value);
   const maskedValue = mask.value.masked(value || '');

   if (socialSecurityNumbersIndex > -1) {
      const maskedSocialSecurityNumber = mask.value.masked(props.modelValue[socialSecurityNumbersIndex].value);
      if (maskedValue === maskedSocialSecurityNumber) return;

      const socialSecurityNumbers = [...props.modelValue];
      socialSecurityNumbers[socialSecurityNumbersIndex].value = maskedValue || '';
      return emits('update:modelValue', socialSecurityNumbers);
   }

   return emits('update:modelValue', [
      ...props.modelValue,
      { country_id: activeCountryId.value, value: maskedValue } as SocialSecurityNumber,
   ]);
}
// #endregion
</script>

<template>
   <div :class="'precognition-ssn-input'">
      <PrecognitionFormSelect
         :options="countries || []"
         :search-type="'custom'"
         :model-value="activeCountryId"
         :placeholder="$t('actions.choose-an-option', { type: $t('generic.country')?.toLowerCase() })"
         class="precognition-ssn-input__country"
         @update:model-value="activeCountryId = $event"
      >
         <template #displaySelected="{ option }: { option: Country }">
            <div v-if="option" class="precognition-ssn-input__selected">
               <span> {{ getUnicodeFlagIcon(option?.iso_code_2) }}</span>
               <span>{{ option?.iso_code_2 }}</span>
            </div>
         </template>
         <template #displayOption="{ option }: { option: Country }">
            <div class="precognition-ssn-input__option">
               <span> {{ getUnicodeFlagIcon(option?.iso_code_2) }}</span>
               <strong>{{ option?.iso_code_2 }}</strong>
            </div>
         </template>
      </PrecognitionFormSelect>

      <PrecognitionFormInput
         :model-value="activeSocialSecurityNumber?.value"
         :mask="activeCountry?.ssn_mask"
         class="precognition-ssn-input__number"
         @update:model-value="setSocialSecurityNumber"
      />
   </div>
</template>

<style lang="scss" scoped>
.precognition-ssn-input {
   display: grid;
   grid-template-columns: minmax(toRem(80), toRem(100)) 1fr;
}

.precognition-ssn-input__selected {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}

.precognition-ssn-input__option {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}

.precognition-ssn-input__country {
   &:deep(.precognition-select) {
      border-radius: var(--nxt-radius) 0 0 var(--nxt-radius);
   }
}

.precognition-ssn-input__number {
   &:deep(input) {
      border-radius: 0 var(--nxt-radius) var(--nxt-radius) 0;
      border-left: none;
   }
}
</style>
