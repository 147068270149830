export enum TransportMethodKey {
   Car = 'car',
   PublicTransport = 'public_transport',
   Motorcycle = 'motorcycle',
   Moped = 'moped',
   Bike = 'bike',
   OnFoot = 'on-foot',
   CanBeBrought = 'can-be-brought',
}
export interface TransportMethod {
   id: number;
   key: TransportMethodKey;
   name: string;
}
