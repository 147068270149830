<script lang="ts" setup>
// #region Globals
const isTablet = useMediaQuery(TABLET_AND_UP);
// #endregion

// #region Props & Emits
defineEmits(['onCancel', 'onConfirm']);
defineProps({
   title: { type: String, default: 'Confirm something' },
   primaryButtonText: { type: String, default: 'Confirm' },
   primaryButtonIcon: { type: String, default: 'check' },
   secondaryButtonText: { type: String, default: null },
});
// #endregion
</script>

<template>
   <ModalSide :class="'confirm-modal'" :width="isTablet ? '35rem' : undefined" :title="title" @on-close="$emit('onCancel')">
      <slot></slot>

      <template #actions>
         <ButtonLink :color="'var(--nxt-dark)'" @on-click="$emit('onCancel')">
            {{ secondaryButtonText || $t('actions.cancel') }}
         </ButtonLink>

         <ButtonMain
            :icon-name="primaryButtonIcon"
            :background-color="'var(--nxt-red)'"
            :color="'var(--nxt-white)'"
            :icon-position="'left'"
            @on-click="$emit('onConfirm')"
         >
            {{ primaryButtonText }}
         </ButtonMain>
      </template>
   </ModalSide>
</template>

<style lang="scss" scoped></style>
