<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
const dayjs = useDayjs();
const { confirmDeleteType } = useModalStore();

defineExpose({ add: () => (showAddCertificateModal.value = true) });
// #endregion

// #region Props & Emits
const modelValue = defineModel<Array<PersonProfileCertificate>>('modelValue', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region Modals
const showAddCertificateModal = ref(false);
const showChangeCertificateModal = ref(false);
const certificateIndexToChange = ref<number>(-1);

function openChangeCertificateModal(index: number) {
   certificateIndexToChange.value = index;
   showChangeCertificateModal.value = true;
}

function closeChangeCertificateModal() {
   certificateIndexToChange.value = -1;
   showChangeCertificateModal.value = false;
}

// #endregion

// #region Drivers Licenses
function addCertificate(certificate: PersonProfileCertificate) {
   modelValue.value.push(certificate);
   showAddCertificateModal.value = false;

   validate();
   touch();
}

function changeCertificate(certificate: PersonProfileCertificate) {
   if (certificateIndexToChange.value === -1) return;
   modelValue.value[certificateIndexToChange.value] = certificate;
   closeChangeCertificateModal();

   validate();
   touch();
}

function removeCertificate(index: number) {
   confirmDeleteType(t('profile.skills.certificates', 2), () => {
      modelValue.value.splice(index, 1);

      validate();
      touch();
   });
}
// #endregion
</script>

<template>
   <div v-auto-animate class="person-todo-certificates">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <div v-if="modelValue.length <= 0" class="person-todo-certificates__no-certificates">
         <p>
            {{ $t('generic.no-type-add-type', { type: $t('profile.skills.certificates', 2)?.toLowerCase() }) }}
         </p>
      </div>

      <div v-else v-auto-animate class="person-todo-certificates__certificates">
         <LayoutCard
            v-for="(profileCertificate, index) in modelValue"
            :key="profileCertificate.sync_id || profileCertificate.certificate_id || index"
            class="person-todo-certificates__certificate"
         >
            <p class="person-todo-certificates__certificate__dates">
               <small v-if="profileCertificate.from"> {{ dayjs(profileCertificate.from).format('DD/MM/YYYY') }}</small>
               <small v-if="profileCertificate.till"> - {{ dayjs(profileCertificate.till).format('DD/MM/YYYY') }}</small>
            </p>

            <p class="person-todo-certificates__certificate__title">
               <strong>{{ profileCertificate.certificate?.name }}</strong>
            </p>

            <div class="person-todo-certificates__certificate__actions">
               <ButtonIcon
                  :icon-name="'pen'"
                  :icon-label="$t('actions.change-type', { type: $t('profile.skills.certificates') })"
                  @on-click="openChangeCertificateModal(index)"
               />
               <ButtonIcon
                  :icon-name="'trash'"
                  :icon-label="$t('actions.delete-type', { type: $t('profile.skills.certificates') })"
                  @on-click="removeCertificate(index)"
               />
            </div>
         </LayoutCard>
      </div>

      <ClientOnly>
         <!-- Create -->
         <Teleport to=".modal-side__card__content">
            <ProfileCertificateForm v-if="showAddCertificateModal" @on-save="addCertificate" @on-cancel="showAddCertificateModal = false" />
         </Teleport>

         <!-- Change -->
         <Teleport to=".modal-side__card__content">
            <ProfileCertificateForm
               v-if="showChangeCertificateModal && certificateIndexToChange > -1"
               :certificate="modelValue[certificateIndexToChange]"
               @on-save="changeCertificate"
               @on-cancel="closeChangeCertificateModal"
            />
         </Teleport>
      </ClientOnly>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-certificates {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.person-todo-certificates__no-certificates {
   text-align: center;
}

.person-todo-certificates__certificates {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);

   list-style: none;
   padding: 0;
}

.person-todo-certificates__certificate {
   display: grid;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto auto;
   grid-template-areas: 'dates actions' 'title actions';
   gap: 0 var(--nxt-gutter);

   &__dates {
      grid-area: dates;
      margin: 0;
   }

   &__title {
      grid-area: title;
      margin: 0;

      strong {
         margin-right: var(--nxt-gutter-small);
      }
   }

   &__actions {
      grid-area: actions;
      display: flex;
      gap: var(--nxt-gutter-small);
      justify-content: flex-end;
   }
}
</style>
