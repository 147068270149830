import type { UseShareOptions } from '@vueuse/core';
import type { LngLatLike } from 'maplibre-gl';

export type Coordinates = {
   latitude: number;
   longitude: number;
};

export function useLinks() {
   const appStatusStore = useAppStatusStore();
   const { t } = useI18n();
   const { share, isSupported: shareSupported } = useShare();

   function openMaps({ coords, address }: { coords?: LngLatLike; address?: string }) {
      const coordsString = coords
         ? Array.isArray(coords)
            ? coords.toReversed().join(',')
            : // @ts-ignore
              `${coords.lat},${coords.lon || coords.lng}`
         : undefined;

      let url: string;

      if (/iPhone|iPad|iPod/i.test(navigator.userAgent) && !window.matchMedia('(display-mode: standalone)').matches) {
         url = `maps://maps.google.com/maps?q=${coordsString || address}`;
      } else if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
         url = `geo:${coordsString || '0,0'}?q=${address}`;
      } else {
         url = `https://maps.google.com/maps?q=${coordsString || address}`;
      }

      window.open(url);
   }

   function openPhone(phoneNumber: string) {
      const url = `tel:${phoneNumber}`;
      window.open(url);
   }

   function openMail(email: string, subject?: string, body?: string) {
      const url = `mailto:${email}${subject ? `?subject=${encodeURIComponent(subject)}` : ''}${
         body ? `&body=${encodeURIComponent(body)}` : ''
      }`;
      window.open(url);
   }

   function openUrl(url: string, target?: string) {
      window.open(url, target);
   }

   function copyToClipboard(text: string) {
      navigator.clipboard.writeText(text);
      appStatusStore.addAppStatus({ title: t('generic.copied-to-clipboard'), type: 'success' });
   }

   function openShare(shareContent: UseShareOptions) {
      if (!shareContent.url) return;

      if (shareSupported.value) {
         return share(shareContent);
      } else {
         copyToClipboard(shareContent.url);
      }
   }

   return {
      openMaps,
      openPhone,
      openUrl,
      copyToClipboard,
      openShare,
      openMail,
   };
}
