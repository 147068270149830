<script lang="ts" setup>
import type { StyleValue } from 'vue';
import { FontColorContraster as vContrast } from '~/directives/color-contrast.directive';

// #region Props & Emits
const props = defineProps({
   iconName: { type: String, default: null }, // fontawesome icon name
   iconType: { type: String, default: 'solid' },
   iconStyle: { type: Object as PropType<StyleValue>, default: () => ({}) },
   iconLabel: { type: String, default: null },
   iconPosition: { type: String as PropType<'left' | 'right'>, default: 'left' },
   backgroundColor: { type: String, default: 'var(--nxt-main)' },
   color: { type: String, default: 'var(--nxt-white)' },
   border: { type: String, default: null },
   disabled: { type: Boolean, default: false },
   fontContrast: { type: Boolean, default: true },
   circular: { type: Boolean, default: false },
   contrast: { type: Boolean, default: true },
   type: { type: String as PropType<'button' | 'submit' | 'reset'>, default: 'button' },
});

const emits = defineEmits<{ (e: 'onClick'): void }>();
// #endregion

const { handleClick } = usePreventDoubleClick();

function onClick(event: Event) {
   handleClick(() => {
      event.stopPropagation();
      if (props.disabled) return;

      if (props.type === 'button') {
         event.preventDefault();
         return emits('onClick');
      }
   });
}
</script>

<template>
   <button
      :type="type"
      :class="['button-main', { icon: !!iconName, content: !!$slots.default, circular }]"
      :disabled="disabled"
      @click="onClick"
   >
      <i
         v-if="iconName && iconPosition === 'left'"
         v-contrast="contrast"
         :class="[`fa-${iconType}`, `fa-${iconName}`, 'button-main__icon']"
         :style="iconStyle"
         :aria-label="iconLabel"
      ></i>
      <span v-if="$slots.default" v-contrast="contrast">
         <slot></slot>
      </span>
      <i
         v-if="iconName && iconPosition === 'right'"
         v-contrast="contrast"
         :class="[`fa-${iconType}`, `fa-${iconName}`, 'button-main__icon']"
         :style="iconStyle"
         :aria-label="iconLabel"
      ></i>
   </button>
</template>

<style lang="scss" scoped>
.button-main {
   background-color: v-bind(backgroundColor);
   padding: toRem(8) toRem(12);
   border-radius: var(--nxt-radius);
   color: v-bind(color);
   border: v-bind(border);
   display: block;
   cursor: pointer;
   width: 100%;

   &.circular {
      border-radius: 50%;
      padding: 0;
      min-width: unset !important;
      width: toRem(40);
   }

   &.icon {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--nxt-gutter);
   }

   &:not(.content) {
      min-width: toRem(60);
      justify-content: center;
   }

   &:disabled {
      cursor: not-allowed;
      filter: grayscale(0.6);
   }

   &:active {
      transform: scale(0.95);
   }

   i {
      width: auto;
   }
}
</style>
