export default defineNuxtRouteMiddleware((to) => {
   if (process.server) return;

   const keycloakQueryParams = ['code', 'session_state', 'state', 'error'];

   const hasKeycloakParams =
      Object.keys(to.query).filter((param) => keycloakQueryParams.find((keycloakParam) => keycloakParam === param)).length > 0;

   if (hasKeycloakParams) {
      const query = Object.entries(to.query)
         .filter(([key, _]) => !keycloakQueryParams.find((keycloakParam) => keycloakParam === key))
         .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      return navigateTo({ ...to, query });
   }
});
