<script lang="ts" setup>
// #region Props & Emits
const emits = defineEmits<{ (e: 'onIconClicked'): void; (e: 'onChange', event: Event): void }>();
const props = defineProps({
   placeholder: { type: String, default: null },
   minLength: { type: Number, default: null },
   maxLength: { type: Number, default: null },
   type: { type: String, default: 'text' },
   min: { type: [String, Number], default: null },
   max: { type: [String, Number], default: null },
   autofocus: { type: Boolean, default: false },
   iconName: { type: String, default: null },
   iconColor: { type: String, default: null },
   mask: { type: String, default: null },
   disableValidation: { type: Boolean, default: false },
});
// #endregion

// #region Element
const { name, invalid, required, disabled, label, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region ModelValue
const modelValue = defineModel<string | number>('modelValue', { default: '' });

function handleInput(event: Event) {
   const inputElement = event?.target as HTMLInputElement;
   const inputValue = inputElement?.value;
   modelValue.value = inputValue;
}

function handleChange(event: Event) {
   emits('onChange', event);
   const inputElement = event?.target as HTMLInputElement;
   const inputValue = inputElement?.value;
   modelValue.value = inputValue;
   if (!props.disableValidation) {
      touch();
      validate();
   }
}
// #endregion
</script>

<template>
   <div :class="['precognition-input', { invalid, icon: !!iconName }]">
      <input
         :id="name"
         v-maska
         :data-maska="mask"
         :name="name"
         :value="modelValue"
         :placeholder="placeholder || label"
         :disabled="disabled"
         :required="required"
         :minlength="minLength"
         :maxlength="maxLength"
         :type="type"
         :min="min"
         :max="max"
         :autofocus="autofocus"
         autocomplete="off"
         @change="handleChange"
         @input="handleInput"
      />
      <ButtonIcon
         v-if="iconName"
         :class="'precognition-input__icon'"
         :icon-name="iconName"
         :icon-style="{ color: iconColor }"
         :tabindex="-1"
         @on-click="$emit('onIconClicked')"
      />
   </div>
</template>

<style lang="scss">
.precognition-input {
   width: 100%;
   position: relative;
   height: toRem(40);

   input {
      height: toRem(40);
      appearance: none;
   }
}

.precognition-input.invalid input {
   border-color: var(--nxt-red);
}

.precognition-input.icon input {
   padding-right: toRem(40);
}

.precognition-input__icon {
   position: absolute;
   right: 0;
   top: 0;
   bottom: 0;
   margin: auto;
   padding: 0 var(--nxt-gutter-small);
   width: toRem(40);
}
</style>
