<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
const dayjs = useDayjs();
const { confirmDeleteType } = useModalStore();

defineExpose({ add: () => (showAddWorkExperienceModal.value = true) });
// #endregion

// #region Props & Emits
const modelValue = defineModel<Array<PersonWorkExperience>>('modelValue', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region Modals
const showAddWorkExperienceModal = ref(false);
const showChangeWorkExperienceModal = ref(false);
const workExperienceIndexToChange = ref<number>(-1);

function openChangeWorkExperienceModal(index: number) {
   workExperienceIndexToChange.value = index;
   showChangeWorkExperienceModal.value = true;
}

function closeChangeWorkExperienceModal() {
   workExperienceIndexToChange.value = -1;
   showChangeWorkExperienceModal.value = false;
}

// #endregion

// #region Drivers Licenses
function addWorkExperience(workExperience: PersonWorkExperience) {
   modelValue.value.push(workExperience);
   showAddWorkExperienceModal.value = false;

   validate();
   touch();
}

function changeWorkExperience(workExperience: PersonWorkExperience) {
   if (workExperienceIndexToChange.value === -1) return;
   modelValue.value[workExperienceIndexToChange.value] = workExperience;
   closeChangeWorkExperienceModal();

   validate();
   touch();
}

function removeWorkExperience(index: number) {
   confirmDeleteType(t('profile.skills.work-experience'), () => {
      modelValue.value.splice(index, 1);

      validate();
      touch();
   });
}
// #endregion
</script>

<template>
   <div v-auto-animate class="person-todo-work-experiences">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <div v-if="modelValue.length <= 0" :class="'person-todo-work-experiences__no-work-experiences'">
         <p>
            {{ $t('generic.no-type-add-type', { type: $t('profile.skills.work-experience', 2)?.toLowerCase() }) }}
         </p>
      </div>

      <div v-else v-auto-animate class="person-todo-work-experiences__work-experiences">
         <LayoutCard
            v-for="(workExperience, index) in modelValue"
            :key="workExperience.sync_id || workExperience.work_experience_type_id || index"
            class="person-todo-work-experiences__work-experience"
         >
            <p class="person-todo-work-experiences__work-experience__dates">
               <small> {{ dayjs(workExperience.from).format('DD/MM/YYYY') }}</small>
               <small v-if="workExperience.till"> - {{ dayjs(workExperience.till).format('DD/MM/YYYY') }}</small>
            </p>

            <p class="person-todo-work-experiences__work-experience__title">
               <strong>{{ workExperience.title }}</strong>
               <span> {{ workExperience.company_name }}</span>
            </p>

            <div class="person-todo-work-experiences__work-experience__actions">
               <ButtonIcon
                  :icon-name="'pen'"
                  :icon-label="$t('actions.change-type', { type: $t('profile.skills.work-experience') })"
                  @on-click="openChangeWorkExperienceModal(index)"
               />
               <ButtonIcon
                  :icon-name="'trash'"
                  :icon-label="$t('actions.delete-type', { type: $t('profile.skills.work-experience') })"
                  @on-click="removeWorkExperience(index)"
               />
            </div>
         </LayoutCard>
      </div>

      <ClientOnly>
         <!-- Create -->
         <Teleport to=".modal-side__card__content">
            <ProfileWorkExperienceForm
               v-if="showAddWorkExperienceModal"
               @on-save="addWorkExperience"
               @on-cancel="showAddWorkExperienceModal = false"
            />
         </Teleport>

         <!-- Change -->
         <Teleport to=".modal-side__card__content">
            <ProfileWorkExperienceForm
               v-if="showChangeWorkExperienceModal && workExperienceIndexToChange > -1"
               :work-experience="modelValue[workExperienceIndexToChange]"
               @on-save="changeWorkExperience"
               @on-cancel="closeChangeWorkExperienceModal"
            />
         </Teleport>
      </ClientOnly>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-work-experiences {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.person-todo-work-experiences__no-work-experiences {
   text-align: center;
}

.person-todo-work-experiences__work-experiences {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);

   list-style: none;
   padding: 0;
}

.person-todo-work-experiences__work-experience {
   display: grid;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto auto;
   grid-template-areas: 'dates actions' 'title actions';
   gap: var(--nxt-gutter-small) var(--nxt-gutter);

   &__dates {
      grid-area: dates;
      margin: 0;
   }

   &__title {
      grid-area: title;
      margin: 0;

      strong {
         margin-right: var(--nxt-gutter-small);
      }
   }

   &__actions {
      grid-area: actions;
      display: flex;
      gap: var(--nxt-gutter-small);
      justify-content: flex-end;
   }
}
</style>
