import { useScheduleStore } from '../schedule.store';
import { AVAILABILITY_ENDPOINTS } from './availability.endpoints';
import { Availability, AvailabilityBatch } from './availability.interface';

export const useAvailabilityStore = defineStore('availability', () => {
   const scheduleStore = useScheduleStore();

   async function postAvailability(availability: Availability) {
      const response = await authFetch<Availability>(AVAILABILITY_ENDPOINTS.POST(), { method: 'POST', body: availability });
      if (response) scheduleStore.setScheduleDayAvailability(response, availability.repetition_frequency);
   }

   async function postAvailabilitiesBatch(availabilitiesBatch: AvailabilityBatch) {
      return await authFetch<Array<Availability>>(AVAILABILITY_ENDPOINTS.POST_BATCH(), {
         method: 'POST',
         body: availabilitiesBatch,
      });
   }

   return {
      postAvailability,
      postAvailabilitiesBatch,
   };
});
