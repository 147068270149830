<script lang="ts" setup>
import { DRIVERS_LICENSE_ENDPOINTS } from '~/resources/drivers-license/drivers-license.endpoints';

// #region Props & Emits
defineEmits<{
   (e: 'onSave', data: PersonProfileDriversLicense): void;
   (e: 'onCancel'): void;
}>();

const props = defineProps<{ driversLicense?: PersonProfileDriversLicense }>();
// #endregion

// #region Form
const { required } = useFormValidators();

const { data, validation } = useForm<PersonProfileDriversLicense>({
   config: { initialValues: { ...(props.driversLicense || {}) } },
   rules: () => ({
      valid_till: { required },
      drivers_license_id: { required },
   }),
});
// #endregion

// #region Methods
function updateDriversLicense(driversLicense: DriversLicense) {
   data.value.drivers_license_id = driversLicense.id;
   data.value.drivers_license = driversLicense;
}
// #endregion

// #region Drivers Licenses
const { data: driversLicenses } = useAuthFetch<Array<DriversLicense>>(DRIVERS_LICENSE_ENDPOINTS.GET(), {});
// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="props.driversLicense ? 'edit' : 'add'"
      :data-type="$t('profile.skills.drivers-license')"
      :data="data"
      :validation="validation"
      :height="'var(--nxt-modal-layer-3)'"
      @on-save="$emit('onSave', data)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-drivers-license-form'">
         <FormElement :name="'drivers-license_id'" :label="$t('actions.select-type', { type: $t('profile.skills.drivers-license') })">
            <FormSelect
               :model-value="data.drivers_license_id"
               :emit-object="true"
               :options="driversLicenses || []"
               :label="$t('profile.skills.drivers-license')"
               @update:model-value="updateDriversLicense"
            />
         </FormElement>

         <FormElement :name="'valid_till'" :label="$t('generic.valid-till')">
            <FormDatepicker v-model="data.valid_till" />
         </FormElement>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-drivers-license-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-drivers-license-form {
   display: flex;
   justify-content: space-between;
   gap: var(--nxt-gutter);
}
</style>
