import { useMyWeekStore } from '~/resources/my-week/my-week.store';
import { useScheduleStore } from '~/resources/schedule/schedule.store';

export function useResetState() {
   const myWeekStore = useMyWeekStore();
   const scheduleStore = useScheduleStore();
   const dayInfoStore = useDayInfoStore();

   function resetState() {
      myWeekStore.refreshMyWeek();
      scheduleStore.resetScheduleState();
      dayInfoStore.resetDayInfoState();
   }

   return {
      resetState,
      resetMyWeek: myWeekStore.refreshMyWeek,
      resetSchedule: scheduleStore.resetScheduleState,
      resetDayInfo: dayInfoStore.resetDayInfoState,
   };
}
