<script lang="ts" setup>
import type { StyleValue } from 'vue';

defineEmits(['onClick']);

const props = defineProps({
   iconName: { type: String, required: true }, // fontawesome icon name
   iconType: { type: String as PropType<'solid' | 'light' | 'kit' | 'regular' | 'thin'>, default: 'solid' },
   iconStyle: { type: Object as PropType<StyleValue>, default: () => ({}) },
   iconLabel: { type: String, default: null },
   tabindex: { type: Number, default: 0 },
   preventDoubleClick: { type: Boolean, default: true },

   type: { type: String as PropType<'button' | 'submit' | 'reset'>, default: 'button' },
});

const { handleClick } = usePreventDoubleClick(props.preventDoubleClick);
</script>

<template>
   <button
      :type="type"
      :tabindex="tabindex"
      :class="'button-icon'"
      :aria-label="iconLabel"
      @click.stop.prevent="type === 'button' ? handleClick(() => $emit('onClick')) : undefined"
   >
      <i :class="[`fa-${iconType}`, `fa-${iconName}`]" :style="iconStyle"></i>
   </button>
</template>

<style lang="scss" scoped>
.button-icon {
   border: none;
   outline: none;
   background-color: transparent;
   padding: var(--nxt-gutter-small);
   text-decoration: none;
   color: var(--nxt-dark);
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   height: unset;

   &:active {
      transform: scale(0.9);
   }
}
</style>
