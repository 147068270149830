<script lang="ts" setup generic="T extends {}">
import type { Validation } from '@vuelidate/core';

// #region globals
const isTablet = useMediaQuery(TABLET_AND_UP);
// #endregion

// #region Props & emits
defineEmits<{
   (e: 'onSave', data: T): void;
   (e: 'onCancel'): void;
}>();
defineProps<{
   data: T;
   validation: Validation<{}, T>;
   dataType: string;

   type: 'add' | 'edit';
   height?: string;
}>();
// #endregion
</script>

<template>
   <Teleport to=".app">
      <FormContainer :data="data" :validation="validation" @on-valid-submit="$emit('onSave', data as T)">
         <ModalSide
            :title="type === 'add' ? $t('actions.add-type', { type: dataType }) : $t('actions.change-type', { type: dataType })"
            :height="height || 'var(--nxt-modal-layer-3)'"
            :width="isTablet ? '35rem' : undefined"
            :has-unsaved-changes="validation.$anyDirty"
            @on-close="$emit('onCancel')"
         >
            <slot></slot>

            <template #actions="{ onClose }">
               <ButtonLink @click="onClose">
                  {{ $t('actions.cancel') }}
               </ButtonLink>
               <ButtonMain :type="'submit'">
                  <span v-if="type === 'add'"> {{ $t('actions.add-type', { type: dataType }) }}</span>
                  <span v-if="type === 'edit'"> {{ $t('actions.change-type', { type: dataType }) }}</span>
               </ButtonMain>
            </template>
         </ModalSide>
      </FormContainer>
   </Teleport>
</template>
