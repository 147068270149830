<script setup lang="ts">
const props = defineProps({
   onRefresh: { type: Function as PropType<() => void>, default: null },
   minTouchOffset: { type: Number, default: 30 },
   disabled: { type: Boolean, default: false },
});

const startY = ref(0);
const startX = ref(0);
const showStatus = ref(false);
const scrollOffset = ref(0);

function touchStart(e: TouchEvent) {
   if (props.disabled) return;
   startY.value = e.touches[0].clientY;
   startX.value = e.touches[0].clientX;
   const firstScrollableParent = getFirstScrollableParent(e.currentTarget as HTMLElement);
   scrollOffset.value = firstScrollableParent?.scrollTop || 0;
}

function touchMove(e: TouchEvent) {
   if (props.disabled) return;

   const touch = e.touches[0];
   const deltaY = touch.clientY - (startY.value || 0);
   const deltaX = touch.clientX - (startX.value || 0);
   if (scrollOffset.value > 0) {
      return;
   }
   if (Math.abs(deltaX) > 5) {
      return;
   }
   if (deltaY > 20) {
      showStatus.value = true;
   }
}

async function touchEnd() {
   if (props.disabled) return;

   if (showStatus.value) {
      try {
         if (props.onRefresh) await Promise.resolve(props.onRefresh());
      } finally {
         setTimeout(() => {
            showStatus.value = false;
         }, 500);
      }
   }
}
</script>

<template>
   <div
      ref="containerRef"
      class="pull-to-refresh"
      @touchstart.passive="touchStart"
      @touchmove.passive="touchMove"
      @touchend.passive="touchEnd"
   >
      <div v-if="showStatus" class="pull-to-refresh__status">
         <StatusLoader :height="100" :width="80" />
      </div>

      <slot></slot>
   </div>
</template>

<style scoped>
.pull-to-refresh {
   overflow-y: scroll;
   -webkit-overflow-scrolling: touch;
   width: 100%;
   height: fit-content;

   display: flex;
   flex-direction: column;
   align-items: center;
}

.pull-to-refresh__status {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 50px;
}
</style>
