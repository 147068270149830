export function deepEqual(object1: any, object2: any) {
   if (!object1 || !object2) return false;

   const keys1 = Object.keys(object1).filter((k) => !!object1[k]);
   const keys2 = Object.keys(object2).filter((k) => !!object2[k]);
   if (keys1.length !== keys2.length) {
      return false;
   }
   for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
         return false;
      }
   }
   return true;
}
function isObject(obj: any) {
   return obj != null && typeof obj === 'object';
}

export function getPropertyByPath(object: any, path: string) {
   if (typeof object !== 'object') return null;
   if (!path) return null;
   return path.split('.').reduce((o, p) => o && o[p], object);
}

export function updatePropertyByPath<T extends object>(object: T, path: string, value: any) {
   const keys = path.split('.');
   const lastKey = keys.pop();
   const target = keys.reduce((obj: any, key) => (obj[key] = obj[key] || {}), object);
   target[lastKey as any] = value;
   return object;
}
