<script lang="ts" setup>
import SwiperInstance from 'swiper';
import { Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { SwiperWeek } from '~/models/swiper-schedule.interface';

// #region Globals
const swiperInstance = ref<SwiperInstance>();
provide('swiperInstance', swiperInstance);
// #endregion

// #region Props & emits
defineEmits<{ (e: 'onCancel'): void; (e: 'updateOtherWeeks', weekIndex: number): void }>();

// #endregion

// #region Weeks
const weeks = inject('weeks', ref<Array<SwiperWeek>>([]));
const activeWeek = computed(() => weeks.value[swiperInstance.value?.activeIndex || 1]);
// #endregion

// #region Swiper
function appendWeek() {
   if (!weeks.value.length) return;

   const lastWeek = weeks.value[weeks.value.length - 1];
   const nextWeek = { start: lastWeek.start.add(1, 'week'), end: lastWeek.end.add(1, 'week') };
   weeks.value = [...weeks.value, nextWeek];
}

function prependMonth(swiper: SwiperInstance) {
   if (!weeks.value.length) return;

   setTimeout(() => {
      const firstMonth = weeks.value[0];
      const prevMonth = { start: firstMonth.start.subtract(1, 'week'), end: firstMonth.end.subtract(1, 'week') };
      weeks.value = [prevMonth, ...weeks.value];
      swiper.slideTo(1, 0);
   }, 300);
}

function slideNext() {
   return swiperInstance.value?.slideNext();
}

function slidePrev() {
   return swiperInstance.value?.slidePrev();
}

async function updateSwiper() {
   await nextTick();
   swiperInstance.value?.update();
}

watch(activeWeek, updateSwiper, { immediate: true, flush: 'post', deep: true });
// #endregion
</script>

<template>
   <div class="availability">
      <!-- Navigation -->
      <div class="availability__navigation">
         <ButtonIcon :prevent-double-click="false" :icon-name="'chevron-left'" :icon-label="$t('generic.previous')" @on-click="slidePrev" />

         <p class="availability__navigation__active-week">
            <span>{{ activeWeek.start.format('dd DD/MM') }} - {{ activeWeek.end.format('dd DD/MM/YY') }}</span>
            <i class="fa-solid fa-calendar-days"></i>
         </p>

         <ButtonIcon :prevent-double-click="false" :icon-name="'chevron-right'" :icon-label="$t('generic.next')" @on-click="slideNext" />
      </div>

      <!-- Swiper -->
      <Swiper
         :class="'availability__swiper'"
         :no-swiping="true"
         :modules="[Keyboard]"
         :initial-slide="1"
         :no-swiping-class="'swiper-slide'"
         :auto-height="true"
         :observe-parents="true"
         :observer="true"
         @swiper="swiperInstance = $event"
         @reach-end="appendWeek"
         @reach-beginning="prependMonth"
      >
         <SwiperSlide v-for="(week, index) in weeks" :key="`${index} - ${week.start.unix()}`" class="availability__swiper__week">
            <ScheduleWeekAvailabilitiesForm
               :period="week"
               :is-active-week="activeWeek.start.isSame(week.start, 'week')"
               @on-close="$emit('onCancel')"
               @update-other-weeks="$emit('updateOtherWeeks', index)"
            />
         </SwiperSlide>
      </Swiper>
   </div>
</template>

<style lang="scss" scoped>
.availability {
   display: flex;
   flex-direction: column;
}

.availability__navigation {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: var(--nxt-gutter-small);
   position: sticky;
   top: 0;
   background-color: var(--nxt-white);
   z-index: var(--zindex-sticky);
}
.availability__navigation__active-week {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter);
}

.availability__swiper {
   width: 100%;
   overflow: hidden;
}
</style>
