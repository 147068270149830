<script lang="ts" setup>
// #region Props & Emits
const props = defineProps({
   name: { type: String, required: true },
   description: { type: String, default: '' },
   required: { type: Boolean, default: false },
   disabled: { type: Boolean, default: false },

   label: { type: String, default: '' },
   labelPosition: { type: String as PropType<'top' | 'left' | 'right'>, default: 'top' },
   labelStyle: { type: String as PropType<'uppercase' | 'capitalize' | 'custom'>, default: 'capitalize' },
});

const { form } = inject<PrecognitionForm>('form', {} as PrecognitionForm);
// #endregion

// #region Invalid
const errorKey = computed(() => {
   const fieldError = form?.errors?.[props.name] || '';
   const match = fieldError.match(/\[(.*?)\]/);
   return match ? match[1] : null;
});

const errors = computed(() => {
   if (errorKey.value) return '';
   return form?.errors?.[props.name] || '';
});

const invalid = computed(() => !!errors.value || !!errorKey.value);
// #endregion

// #region Provide
const name = computed(() => props.name);
const label = computed(() => props.label);
const required = computed(() => props.required);
const disabled = computed(() => props.disabled || form.processing);

provide<PrecognitionFormElementData>('precognitionFormElement', {
   name,
   label,
   required,
   disabled,
   invalid,
   errors,
   validate: () => form.validate(props.name),
   touch: () => form.touch(props.name),
});
// #endregion
</script>

<template>
   <div :class="['precognition-form-element', { invalid }]">
      <div :class="['precognition-form-element__element', labelPosition]">
         <label v-if="label" :class="['precognition-form-element__label', { required }, labelStyle]" :for="name || ''"> {{ label }}</label>
         <slot v-bind="{ name, label, errors, invalid, required, disabled }"></slot>
      </div>

      <div v-if="invalid" v-auto-animate class="precognition-form-element__errors">
         <slot :name="errorKey">
            <small v-if="errors" class="precognition-form-element__errors__error">
               {{ errors }}
            </small>
         </slot>
      </div>

      <div v-else-if="description" class="precognition-form-element__description">
         <slot name="description">
            <small>
               {{ description }}
            </small>
         </slot>
      </div>
   </div>
</template>

<style lang="scss">
.precognition-form-element {
   display: flex;
   flex-direction: column;
}

.precognition-form-element__element {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
   width: 100%;

   &.left {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: var(--nxt-gutter);
   }

   &.right {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      gap: var(--nxt-gutter);
   }
}

.precognition-form-element__label {
   position: relative;

   &.capitalize {
      text-transform: lowercase;

      &::first-letter {
         text-transform: uppercase;
      }
   }

   &.uppercase {
      text-transform: uppercase;
   }
}

.precognition-form-element__label.required::after {
   position: absolute;
   content: '*';
   color: var(--nxt-red);
}

.precognition-form-element__errors {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-tiny);
   align-items: flex-start;
   margin-top: toRem(5);
}
.precognition-form-element__errors__error {
   color: var(--nxt-red);
   font-size: toRem(10);
}

.precognition-form-element__description {
   color: var(--nxt-grey);
   margin-top: toRem(5);
}
</style>
