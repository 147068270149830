import DOMPurify from 'dompurify';
import type { Directive, DirectiveBinding } from 'vue';

const purifier = DOMPurify();

function purify(el: HTMLElement, binding: DirectiveBinding<string>) {
   try {
      el.innerHTML = purifier.sanitize(binding.value);
   } catch {
      el.innerHTML = '';
   }
}

const PurifyFormatter: Directive = {
   mounted: purify,
   updated: purify,
};

export { PurifyFormatter };
