<script lang="ts" setup>
import { JOB_FUNCTION_ENDPOINTS } from '~/resources/job-function-category/job-function/job-function.endpoints';
import type { JobFunction } from '~/resources/job-function-category/job-function/job-function.interface.ts';

// #region Props & Emits

defineEmits<{
   onSave: [data: Array<JobFunction>];
   onCancel: [];
}>();

// #endregion

// #region Form
const { required } = useFormValidators();

const { data, validation } = useForm<{ job_functions: Array<JobFunction> }>({
   rules: () => ({ job_functions: { required } }),
   config: { initialValues: { job_functions: [] } },
});
// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="'add'"
      :data-type="$t('profile.job-preferences.desired-function')"
      :data="data"
      :validation="validation"
      :height="'var(--nxt-modal-layer-3)'"
      @on-save="$emit('onSave', data.job_functions)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-job-function-form'">
         <FormElement
            :name="'job-function_id'"
            :label="$t('actions.select-type', { type: $t('profile.job-preferences.desired-function') })"
         >
            <FormMultiSelect
               v-model="data.job_functions"
               :label="$t('profile.job-preferences.desired-function', 2)"
               :search-type="'custom'"
               :search-url="JOB_FUNCTION_ENDPOINTS.GET()"
               :options-to-load-initially="1000"
            />
         </FormElement>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-job-function-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-job-function-form {
   display: flex;
   justify-content: space-between;
   gap: var(--nxt-gutter);
}
</style>
