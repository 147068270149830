import { Dayjs } from 'dayjs';
import { PlanningRequestStateKey } from '../planning-request-state/planning-request-state.interface';
import { PLANNING_REQUEST_ENDPOINTS } from './planning-request.endpoints';
import { PlanningRequest } from './planning-request.interface';

export const usePlanningRequestStore = defineStore('planningRequest', () => {
   const planningRequests = ref<{ [key: string]: Array<PlanningRequest> }>({});
   const loadingDays = ref<{ [key: string]: boolean }>({});
   const failedDays = ref<{ [key: string]: boolean }>({});

   // #region Get Planning Request
   function setPlanningRequests(date: string, newPlanningRequests: Array<PlanningRequest>) {
      planningRequests.value[date] = newPlanningRequests;
   }

   function setLoadingDays(date: string, loading: boolean) {
      loadingDays.value[date] = loading;
   }

   async function getPlanningRequest(date: Dayjs) {
      const formattedDate = date.format('YYYY-MM-DD');

      if (loadingDays.value[formattedDate]) return;
      setLoadingDays(formattedDate, true);

      try {
         const response = await authFetch<Array<PlanningRequest>>(PLANNING_REQUEST_ENDPOINTS.GET(), {
            params: { date: formattedDate },
         });
         if (response) setPlanningRequests(date.format('YYYY-MM-DD'), response);
      } catch (e) {
         failedDays.value[formattedDate] = true;
      } finally {
         setLoadingDays(formattedDate, false);
      }
   }
   // #endregion

   // #region Update Planning Request
   async function changePlanningRequestState(syncId: string, planningRequestStateKeystatus: PlanningRequestStateKey) {
      return await authFetch<PlanningRequest>(PLANNING_REQUEST_ENDPOINTS.PUT(syncId), {
         method: 'PUT',
         body: {
            planning_request_state_key: planningRequestStateKeystatus,
         },
      });
   }

   // #endregion

   // #region Reset Planning Request State
   function resetPlanningRequestState() {
      planningRequests.value = {};
      loadingDays.value = {};
      failedDays.value = {};
   }
   // #endregion

   return {
      planningRequests,
      loadingDays,
      failedDays,
      changePlanningRequestState,
      setPlanningRequests,
      getPlanningRequest,
      resetPlanningRequestState,
   };
});
