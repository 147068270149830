function checkSupport() {
   if (!('serviceWorker' in navigator)) {
      return false;
   }

   if (!('PushManager' in window)) {
      return false;
   }

   if (navigator.serviceWorker.controller === null) {
      return false;
   }

   return true;
}

async function requestNotificationPermission() {
   if (!('Notification' in window)) {
      return;
   }

   return await new Promise((resolve) => {
      Notification.requestPermission(resolve)?.then(resolve);
   });
}

async function getSubscription() {
   if (!checkSupport()) return;

   const registration = await navigator.serviceWorker.ready;
   return await registration.pushManager.getSubscription();
}

async function subscribeToWebPush() {
   if (!checkSupport()) return;

   try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
         userVisibleOnly: true,
         applicationServerKey: useRuntimeConfig().public.vapidPublicKey,
      });

      return await authFetch(PUSH_SUBSCRIPTION_ENDPOINTS.SUBSCRIBE(), {
         method: 'POST',
         body: JSON.stringify(subscription),
      });
   } catch (e) {
      useNuxtApp().$sentryCaptureException(e);
   }
}

async function unsubscribeFromWebPush() {
   if (!checkSupport()) return;

   try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      if (!subscription) return;

      await authFetch(PUSH_SUBSCRIPTION_ENDPOINTS.UNSUBSCRIBE(), {
         method: 'POST',
         body: {
            endpoint: subscription?.endpoint,
         },
      });

      return await subscription.unsubscribe();
   } catch (e) {
      useNuxtApp().$sentryCaptureException(e);
   }
}

export { getSubscription, requestNotificationPermission, subscribeToWebPush, unsubscribeFromWebPush };
