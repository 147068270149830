import { defineStore } from 'pinia';
import { AVAILABILITY_TODO_ENDPOINTS } from './availability-todo.endpoints';

export const useAvailabilityTodoStore = defineStore('availability-todo', () => {
   async function getAvailabilityTodo(availabilityTodoId: string) {
      return await authFetch<AvailabilityTodo>(AVAILABILITY_TODO_ENDPOINTS.GET(availabilityTodoId));
   }

   return {
      getAvailabilityTodo,
   };
});
