<script lang="ts" setup>
// #region Globals
const { name, required, disabled, label, invalid, touch } = useFormElement();
// #endregion

// #region Props & Emits

defineProps({
   placeholder: { type: String, default: '' },
   min: { type: String, default: null },
   max: { type: String, default: null },
});

const modelValue = defineModel<string>('modelValue');
// #endregion

// #region ModelValue
function handleInput(e: Event) {
   if (touch) touch();
   const target = e.target as HTMLInputElement;
   const value = target.value;
   modelValue.value = value;
}
// #endregion
</script>

<template>
   <input
      :id="name"
      ref="inputRef"
      :class="['datepicker', { invalid, disabled }]"
      :value="modelValue"
      :disabled="disabled"
      :name="name"
      :required="required"
      :type="'date'"
      :min="min"
      :max="max"
      :tabindex="-1"
      :placeholder="placeholder || label || $t('actions.select')"
      @input="handleInput"
   />
</template>

<style lang="scss" scoped>
.datepicker {
   appearance: none;
   text-align: start;
   width: 100%;
   height: toRem(40);
   border-radius: var(--nxt-radius);
}
</style>
