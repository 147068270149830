export type DefaultLayoutProps = Partial<{
   actions: Array<HeaderAction>;
   background: string;
   color: string;
   logo: string | boolean;
   root: boolean;
   title: string;
   navigateBackTo: (() => void) | string | null;
   navigateBackText: string | null;
   hideProfile: boolean;
   hideNavigation: boolean;
   fullNavigation?: boolean;

   headerBackground: string;
   headerColor: string;
}>;

export const defaultLayoutProps = ref<DefaultLayoutProps>();

export function useDefaultLayout(layoutParams: () => DefaultLayoutProps) {
   const layoutProps = computed(layoutParams);

   const defaultValues: DefaultLayoutProps = {
      actions: [],
      background: 'var(--nxt-special-background)',
      color: 'var(--nxt-base-color)',
      logo: true,
      root: false,
      title: 'NxtPlan',
      navigateBackTo: null,
      navigateBackText: null,
      hideProfile: false,
      hideNavigation: false,
      headerBackground: 'var(--nxt-base-header-background)',
      headerColor: 'var(--nxt-base-header-color)',
      fullNavigation: false,
   };

   function updateProps(props: DefaultLayoutProps) {
      defaultLayoutProps.value = { ...defaultValues, ...props };
   }

   const { pause, resume, stop } = watchPausable(layoutProps, updateProps);

   onActivated(async () => {
      await nextTick();
      resume();
      updateProps(layoutProps.value);
   });
   onMounted(async () => {
      await nextTick();
      resume();
      updateProps(layoutProps.value);
   });
   onDeactivated(pause);
   onBeforeUnmount(stop);
}
