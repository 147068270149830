<script lang="ts" setup>
// #region Props & Emits
const modelValue = defineModel<string | null>('modelValue', { default: null });

defineProps<{
   question: PersonTodoQuestion;
}>();
// #endregion

// #region Retirement
const isRetired = ref(!!modelValue.value);

function setIsRetired(value: boolean) {
   if (!value) {
      modelValue.value = null;
   }
}

watch(isRetired, setIsRetired);
// #endregion
</script>

<template>
   <div class="person-todo-retirement">
      <FormElement name="is_retired" :label="$t('profile.fiscal-data.is-retired')" :label-position="'left'">
         <FormSwitch v-model="isRetired" />
      </FormElement>

      <PrecognitionFormElement v-if="isRetired" :name="question.key" :label="question.name">
         <PrecognitionFormDatepicker v-model="modelValue" />
      </PrecognitionFormElement>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-retirement {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}
</style>
