<script lang="ts" setup>
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.bubble.css';

// #region Props & Emits
const props = defineProps<{
   src?: string | null;
   loading: boolean;
}>();
const emits = defineEmits<{
   onClose: [];
   onSave: [string | null];
}>();
// #endregion

// #region Image
const fileInputRef = ref<HTMLInputElement>();
const file = ref<File>();
const initialFile = ref(props.src);

const fileUrl = computed(() => {
   if (file.value) return URL.createObjectURL(file.value);
   if (initialFile.value) return initialFile.value;
   return null;
});

function setFile(event: Event) {
   const newFile = (event.target as HTMLInputElement).files?.[0];
   if (!newFile) return;
   file.value = newFile;
}

function deleteFile() {
   file.value = undefined;
   initialFile.value = undefined;
}
// #endregion

// #region change
const cropperRef = ref<InstanceType<typeof Cropper>>();

function saveImage() {
   if (fileUrl.value && cropperRef.value) {
      const result = cropperRef.value.getResult();
      const dataUrl = result.canvas?.toDataURL();
      return emits('onSave', dataUrl || null);
   }
   emits('onSave', null);
}
// #endregion
</script>

<template>
   <ModalSide :height="'var(--nxt-modal-layer-1)'" :title="$t('profile.choose-profile-picture')" @on-close="$emit('onClose')">
      <div class="image-cropper">
         <ButtonLink :text-align="'start'" :type="'button'" :disabled="loading" @click.stop="() => fileInputRef?.click()">
            <span v-if="!file">{{ $t('profile.choose-profile-picture') }}</span>
            <span v-else-if="file.name">{{ $t('generic.chosen-photo') }}: {{ file?.name }}</span>
         </ButtonLink>

         <input
            ref="fileInputRef"
            :disabled="loading"
            :class="'file-picker__input'"
            :type="'file'"
            :accept="'image/*,camera'"
            @change="setFile"
         />

         <div class="image-cropper__box">
            <img v-if="!file && props.src" class="image-cropper__image" :src="props.src" alt="" @click.stop="fileInputRef?.click()" />

            <template v-if="!!file">
               <div class="image-cropper__controls">
                  <div>
                     <ButtonIcon
                        :prevent-double-click="false"
                        :icon-label="$t('actions.zoom-in')"
                        :icon-name="'magnifying-glass-plus'"
                        @on-click="cropperRef?.zoom(1.23)"
                     />
                     <ButtonIcon
                        :prevent-double-click="false"
                        :icon-label="$t('actions.zoom-out')"
                        :icon-name="'magnifying-glass-minus'"
                        @on-click="cropperRef?.zoom(0.8)"
                     />

                     <ButtonIcon
                        :prevent-double-click="false"
                        :icon-label="$t('actions.rotate-left')"
                        :icon-name="'rotate-left'"
                        @on-click="cropperRef?.rotate(-90)"
                     />
                     <ButtonIcon
                        :prevent-double-click="false"
                        :icon-label="$t('actions.rotate-right')"
                        :icon-name="'rotate-right'"
                        @on-click="cropperRef?.rotate(90)"
                     />
                  </div>

                  <ButtonIcon
                     :icon-label="$t('actions.delete-type', { type: $t('profile.profile-picture') })"
                     :icon-name="'trash'"
                     @on-click="deleteFile"
                  />
               </div>
               <Cropper
                  ref="cropperRef"
                  class="image-cropper__cropper"
                  :stencil-component="CircleStencil"
                  :stencil-props="{ handlers: {}, movable: false, resizable: false, aspectRatio: 1 / 1 }"
                  :stencil-size="{
                     width: 350,
                     height: 350,
                     minWidth: 150,
                     minHeight: 150,
                  }"
                  :src="fileUrl"
                  image-restriction="stencil"
                  @click="!loading && !fileUrl && fileInputRef?.click()"
               />
            </template>
         </div>
      </div>
      <template #actions>
         <ButtonLink :disabled="loading" @click="$emit('onClose')">{{ $t('actions.cancel') }}</ButtonLink>
         <ButtonMain :disabled="loading" @click="saveImage">{{ $t('actions.save') }}</ButtonMain>
      </template>
   </ModalSide>
</template>

<style lang="scss" scoped>
.image-cropper {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.image-cropper__box {
   position: relative;
   background: var(--nxt-light-grey);
   border-radius: var(--nxt-radius);
   overflow: hidden;
   width: 100%;
}

.image-cropper__image {
   width: 100%;
   height: auto;
   object-fit: cover;
}

.image-cropper__controls {
   display: flex;
   justify-content: space-between;

   > div {
      display: flex;
      gap: var(--nxt-gutter-small);
   }

   &:deep(i) {
      font-size: 1.5rem;
      width: unset;
      padding: var(--nxt-gutter-small);
   }
}

.image-cropper__cropper {
   width: 100%;
   aspect-ratio: 1;
}

.file-picker__input {
   height: 0;
   width: 0;
   border: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1;
}
</style>
