export enum GlobalModalKey {
   // schedule
   PlanningRequest = '#planning-request',
   InputAvailablities = '#input-availabilities',

   // Jobs
   JobApply = '#apply-for-job',

   // Profile
   PersonTodo = '#todo',
   Onboarding = '#onboarding',
}
export interface GlobalModalData {
   // used for prompting the correct modal
   key: GlobalModalKey;

   // used for passing data to the modal
   params?: {
      [key: string]: string;
   };

   // can be added in the page component on a per page basis
   callback?: (data?: any) => void;
}

export interface ConfirmModal {
   title: string;
   message?: string;
   confirmText?: string;
   confirmIcon?: string;
   cancelText?: string;
   confirmCallback?: () => void;
   cancelCallback?: () => void;
}
