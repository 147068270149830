<script lang="ts" setup>
// #region Props & Emits
const props = defineProps<{
   section: PersonTodoSection;
}>();

const modelValue = defineModel<Partial<Person>>('modelValue', { required: true });
// #endregion

// #region Question
const questionRef = ref();
const question = computed(() => props.section.questions[0]);

const questionKey = computed(() => question.value.key.split('.').pop());
// #endregion
</script>

<template>
   <LayoutSection :title="question.name" :has-action="true" @on-action="questionRef?.add()">
      <PrecognitionFormElement :name="question.key">
         <PersonTodoFormEducations v-if="questionKey === 'educations'" ref="questionRef" v-model="modelValue['profile']!['educations']" />

         <PersonTodoFormCourses v-if="questionKey === 'courses'" ref="questionRef" v-model="modelValue['courses']" />

         <PersonTodoFormWorkExperiences
            v-if="questionKey === 'work_experiences'"
            ref="questionRef"
            v-model="modelValue['work_experiences']"
         />

         <PersonTodoFormCompetences
            v-if="questionKey === 'competences'"
            ref="questionRef"
            v-model="modelValue['profile']!['competences']"
         />

         <PersonTodoFormDriversLicenses
            v-if="questionKey === 'drivers_licenses'"
            ref="questionRef"
            v-model:profile-drivers-licenses="modelValue['profile']!['drivers_licenses']"
            v-model:profile-transport-methods="modelValue['profile']!['transport_methods']"
         />

         <PersonTodoFormLanguages v-if="questionKey === 'languages'" ref="questionRef" v-model="modelValue['profile']!['languages']" />

         <PersonTodoFormCertificates
            v-if="questionKey === 'certificates'"
            ref="questionRef"
            v-model="modelValue['profile']!['certificates']"
         />

         <PersonTodoFormJobFunctions
            v-if="questionKey === 'job_functions'"
            ref="questionRef"
            v-model="modelValue['profile']!['job_functions']"
         />

         <PersonTodoFormJobTypes v-if="questionKey === 'job_types'" ref="questionRef" v-model="modelValue['profile']!['job_types']" />

         <PersonTodoFormJobDistances v-if="questionKey === 'job_distances'" ref="questionRef" v-model="modelValue['job_distances']" />
      </PrecognitionFormElement>

      <div class="person-todo-modals"></div>
   </LayoutSection>
</template>

<style lang="scss" scoped></style>
