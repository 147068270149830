<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';

// #region Form Element
const { name, invalid, required, disabled, touch } = useFormElement();
// #endregion

// #region Props
const emits = defineEmits(['update:modelValue', 'update:localValue']);
const props = defineProps({
   max: { type: Number, default: 100 },
   min: { type: Number, default: 0 },
   unit: { type: String, default: null },
   step: { type: Number, default: 1 },
   modelValue: { type: Number, default: null },
   modelModifiers: { type: Object, default: () => ({}) },
   showValue: { type: Boolean, default: false },
   showLabels: { type: Boolean, default: false },
});

const { modelValue, max, min, modelModifiers } = toRefs(props);
// #endregion

// #region ModelValue
const localValue = ref<number>(modelValue.value !== null ? modelValue.value : max.value / 2);

function updateLocalValue(value: number) {
   localValue.value = value;
}

watch(modelValue, updateLocalValue, { immediate: true });

function input(event: Event) {
   let value = +(event.target as HTMLInputElement)?.value;

   if (value < min.value) value = min.value;
   if (value > max.value) value = max.value;

   localValue.value = +value;
   emits('update:localValue', +value);
   if (modelModifiers.value?.lazy) return;
   if (touch) touch();
   emits('update:modelValue', +value);
}
// #endregion

// #region Slider range
const labelRef = ref<HTMLElement | null>(null);

function change(event: Event) {
   const value = (event.target as HTMLInputElement)?.value;
   if (!modelModifiers.value?.lazy) return;
   if (touch) touch();
   emits('update:modelValue', +value);
}

const percentage = computed(() => {
   return ((+localValue.value - min.value) / (max.value - min.value)) * 100;
});

const background = computed(() => {
   return `linear-gradient(to right, var(--nxt-main) 0%, var(--nxt-main) ${percentage.value}%, var(--nxt-extralight-grey) ${percentage.value}%, var(--nxt-extralight-grey) 100%)`;
});

function selectText(event: Event) {
   (event.target as HTMLInputElement).select();
}

// #endregion
</script>

<template>
   <div :class="['slider', { '-invalid': invalid }]">
      <div v-if="unit" ref="labelRef" class="slider__label">
         <label>
            <input
               :disabled="disabled"
               :value="modelValue"
               :type="'number'"
               :min="min"
               :max="max"
               @input="input"
               @focus.stop.prevent="selectText"
            />
            <span>{{ unit }}</span>
         </label>
      </div>
      <div class="slider__input-wrapper">
         <input
            :class="'slider__input'"
            :type="'range'"
            :style="{ background }"
            :min="min"
            :max="max"
            :step="step"
            :value="localValue"
            :disabled="disabled"
            :required="required"
            :name="name"
            @input="input"
            @change="change"
         />
         <span v-if="showValue">{{ localValue }}</span>
      </div>
      <div v-if="unit && showLabels" class="slider__range">
         <span>{{ min }}{{ unit }}</span>
         <span>{{ max }}{{ unit }}</span>
      </div>
   </div>
</template>

<style lang="scss" scoped>
.slider {
   display: flex;
   flex-direction: column;
   position: relative;
   padding: var(--nxt-gutter-small) 0;
}

.slider__label {
   color: var(--nxt-main);
   font-weight: bold;
   align-self: center;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--nxt-extralight-grey);
   padding: toRem(4);
   border-radius: var(--nxt-radius-small);

   input {
      background-color: transparent;
      border: none;
      padding: 0;
      width: toRem(30);
      color: var(--nxt-main);
      font-weight: bold;
      user-select: none;
      padding-left: toRem(4);
   }
}

.slider__label + .slider__input-wrapper {
   margin-top: 1rem;
}

.slider__input-wrapper {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter);
}

.slider__input {
   // Box-model
   height: toRem(6);
   width: 100%;
   padding: 0;
   margin: 0.6rem 0;

   // Typography

   // Visual
   border-radius: toRem(3);
   border: none;
   outline: none;

   // Misc
   appearance: none;
}

.slider__input:disabled {
   cursor: not-allowed;

   &::-moz-range-thumb {
      cursor: not-allowed;
   }

   &::-webkit-slider-thumb {
      cursor: not-allowed;
   }
}

// adds support for: Chrome, Opera, Safari & Edge
.slider__input::-webkit-slider-thumb {
   // Positioning

   // Box-model
   height: toRem(20);
   width: toRem(20);

   // Typography

   // Visual
   background-color: var(--nxt-main);
   border-radius: toRem(12);

   // Misc
   appearance: none;
   cursor: pointer;
}

// adds support for: Firefox
.slider__input::-moz-range-thumb {
   // Positioning

   // Box-model
   height: toRem(20);
   width: toRem(20);

   // Typography

   // Visual
   background-color: var(--nxt-main);
   border-radius: toRem(12);
   border: none;

   // Misc
   appearance: none;
   cursor: pointer;
}

.slider__range {
   display: flex;
   justify-content: space-between;
}
</style>
