import type { Dayjs } from 'dayjs';
import type { Schedule } from './schedule.interface';

export const useScheduleStore = defineStore('schedule', () => {
   const schedule = ref<{ [key: string]: Schedule }>({});
   const loadingMonths = ref<{ [key: string]: boolean }>({});
   const dayjs = useDayjs();

   // #region Get Schedule
   function setScheduleDayAvailability(availability: Availability, repititionFrequency?: RepititionFrequency) {
      const startDate = schedule.value[dayjs(availability.date).startOf('month').format('YYYY-MM-DD')];
      if (!repititionFrequency && startDate) {
         startDate[availability.date] = { availability };
         return;
      }

      let availabilityDate = dayjs(availability.date);
      const repititionEndDate = dayjs(repititionFrequency?.end_date);
      const isTwoWeekly = repititionFrequency?.is_two_weekly;
      const periodBetweenDates = isTwoWeekly ? 14 : 7;

      let counter = 10;

      while (availabilityDate.isSameOrBefore(repititionEndDate) && counter >= 0) {
         counter--;
         const monthStartDate = availabilityDate.startOf('month').format('YYYY-MM-DD');
         const monthEndDate = availabilityDate.endOf('month').format('YYYY-MM-DD');

         if (!schedule.value[monthStartDate]) schedule.value[monthStartDate] = {};
         if (!schedule.value[monthEndDate]) schedule.value[monthEndDate] = {};

         schedule.value[monthStartDate][availabilityDate.format('YYYY-MM-DD')] = { availability };
         schedule.value[monthEndDate][availabilityDate.format('YYYY-MM-DD')] = { availability };

         availabilityDate = availabilityDate.add(periodBetweenDates, 'days');
      }
   }

   function setLoadingMonth(monthStartDate: string, loading: boolean) {
      loadingMonths.value[monthStartDate] = loading;
   }

   async function getMonthSchedule(monthStartDate: Dayjs, monthEndDate: Dayjs) {
      const formattedMonthStartDate = monthStartDate.format('YYYY-MM-DD');

      if (loadingMonths.value[formattedMonthStartDate]) return;
      setLoadingMonth(formattedMonthStartDate, true);

      const response = await authFetch<Schedule>(SCHEDULE_ENDPOINTS.GET(), {
         params: {
            between: `${formattedMonthStartDate},${monthEndDate.format('YYYY-MM-DD')}`,
         },
      })
         .catch(() => {
            console.warn(`Could not request data`);
         })
         .finally(() => {
            setLoadingMonth(formattedMonthStartDate, false);
         });

      if (response) schedule.value[formattedMonthStartDate] = response;
   }

   async function getScheduleForPeriod(weekStartDate: Dayjs, weekEndDate: Dayjs): Promise<Schedule | null> {
      const startDateFormatted = weekStartDate.format('YYYY-MM-DD');
      const endDateFormatted = weekEndDate.format('YYYY-MM-DD');
      const between = `${startDateFormatted},${endDateFormatted}`;

      return await authFetch<Schedule>(SCHEDULE_ENDPOINTS.GET(), { params: { between } });
   }
   // #endregion

   // #region Reset Schdule State
   function resetScheduleState() {
      schedule.value = {};
      loadingMonths.value = {};
   }

   // #endregion

   return {
      schedule,
      setScheduleDayAvailability,
      resetScheduleState,
      getMonthSchedule,
      getScheduleForPeriod,
   };
});
