<script lang="ts" setup>
// #region Globals
const { openPhone, openMail } = useLinks();
const { addAppStatus } = useAppStatusStore();
const { t } = useI18n();
// #endregion

// #region Props & Emits
const props = defineProps({
   planningRequest: { type: Object as PropType<PlanningRequest>, default: null },
});
// #endregion

// #region contact
function contact() {
   if (props.planningRequest.user?.phone_number) openPhone(props.planningRequest.user.phone_number);
   else if (props.planningRequest.user?.email) openMail(props.planningRequest.user.email);
   else addAppStatus({ title: t('generic.contact-not-available'), canClose: true, type: 'warning' });
}
// #endregion
</script>

<template>
   <LayoutCard>
      <div v-if="planningRequest" class="contact-card" @click="contact">
         <LayoutLogoHeader
            :logos="planningRequest?.tenant?.logo"
            :title="planningRequest?.tenant?.name"
            :sub-title="`${planningRequest.user?.first_name} ${planningRequest.user?.last_name}`"
         />
         <i class="contact-card__chevron fa-solid fa-chevron-right"></i>
      </div>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.contact-card {
   width: 100%;
   display: flex;
   gap: var(--nxt-gutter);
   justify-content: space-between;

   padding: 0 var(--nxt-gutter-small);

   &:hover {
      cursor: pointer;
   }
}

.contact-card__chevron {
   grid-area: chevron;

   align-self: center;
}
</style>
