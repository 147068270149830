<script lang="ts" setup>
import type { SchedulePlanningSentCard } from '#build/components';
import type { LngLatLike } from 'maplibre-gl';
import { useAddToCalendar } from '../../composables/useAddToCalendar';

// #region Globals
const { openPhone, openMaps } = useLinks();
const dayjs = useDayjs();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const activeDate = computed(() => route.query.active_date as string);
// #endregion

// #region Props & Emits
const props = defineProps({
   id: { type: String, default: null },
});

const emits = defineEmits<{ (e: 'onClose'): void }>();
// #endregion

// #region Planning request
const { data: planningRequest, pending, error } = useAuthFetch<PlanningRequest>(PLANNING_REQUEST_ENDPOINTS.GET(props.id), { lazy: true });

if (error.value) emits('onClose');

const planningRequestState = computed(() => planningRequest.value?.planning_request_state.key);

const isPassedDeadline = computed(() => {
   if (!planningRequest.value) return false;
   return dayjs().isAfter(dayjs(planningRequest.value.must_be_handled_at), 'day');
});
// #endregion

// #region Show Time Registration
const showTimeRegistrationAction = computed(() => {
   if (planningRequest.value?.slots?.length <= 0) return false;

   // If the first does not start within the next 4 hours, the user can't register time
   const firstSlot = planningRequest.value?.slots?.[0];
   const firstSlotDate = dayjs(firstSlot.started_at);
   const firstSlotStartDateIsInFuture = firstSlotDate.isAfter(dayjs().add(4, 'hour'));
   if (firstSlotStartDateIsInFuture) return false;

   // If the last slot is in the future, the user can register time
   const lastSlot = planningRequest.value?.slots[planningRequest.value?.slots.length - 1];
   const lastSlotDate = dayjs(lastSlot.ended_at);
   const lastSlotEndDateIsInFuture = lastSlotDate.isAfter(dayjs());
   if (lastSlotEndDateIsInFuture) return true;

   // If the last slot has not ended, the user can register time
   const lastSlotHasNotEndendPerformedHours = lastSlot?.performed_hours?.find((hour) => hour.end_time === null);
   if (lastSlotHasNotEndendPerformedHours) return true;

   return false;
});
// #endregion

// #region Calendar
const { addToCalendar } = useAddToCalendar(planningRequest);
// #endregion

// #region Confirmation Sent
const confirmationSentCardRef = ref<InstanceType<typeof SchedulePlanningSentCard>>();
// #endregion

// #region Actions
const hasActions = computed(() => {
   if (!planningRequest.value) return false;
   if (PlanningRequestStateKey.SENT === planningRequestState.value) return true;
   return !!actions.value.length;
});

const maps = computed<null | { coords?: LngLatLike; address?: string }>(() => {
   if (!planningRequest.value.squad?.street) return null;

   const { longitude, latitude } = planningRequest.value.squad;

   return {
      coords: longitude && latitude ? { lat: latitude, lng: longitude } : undefined,
      address: `${planningRequest.value.squad.street} ${planningRequest.value.squad.house_number}, ${planningRequest.value.squad.city?.name}`,
   };
});

const actions = computed(() =>
   [
      {
         key: 'phone',
         icon: 'phone',
         label: t('actions.call'),
         action: () => openPhone(planningRequest.value.team_phone_number!),
         enabled: !!planningRequest.value?.team_phone_number,
      },
      {
         key: 'calendar',
         icon: 'calendar-plus',
         label: t('schedules.add-to-calendar'),
         action: () => addToCalendar(),
         enabled: !isPassedDeadline.value,
      },
      {
         key: 'maps',
         icon: 'location-dot',
         label: t('actions.navigate'),
         action: () => openMaps(maps.value!),
         enabled: !!maps.value,
      },
      {
         key: 'time-registration',
         icon: 'qrcode',
         label: t('schedules.register-time'),
         action: () => router.push('/time-registration'),
         enabled: showTimeRegistrationAction.value,
      },
   ].filter((a) => a.enabled),
);

// #endregion
</script>

<template>
   <ScheduleDayDetailsModal
      :loading="pending"
      :planning-request="planningRequest"
      class="planning-request-modal"
      @on-close="$emit('onClose')"
   >
      <template #card="{ mode }">
         <LayoutCard v-if="pending" />

         <SchedulePlanningApprovedCard
            v-else-if="planningRequest && planningRequestState === PlanningRequestStateKey.APPROVED"
            :planning-request="planningRequest"
            :mode="mode"
            :date="activeDate"
            :is-passed-deadline="isPassedDeadline"
            :background-color="'var(--nxt-extralight-grey)'"
         />

         <SchedulePlanningSentCard
            v-else-if="planningRequest && planningRequestState === PlanningRequestStateKey.SENT"
            ref="confirmationSentCardRef"
            :planning-request="planningRequest"
            :mode="mode"
            :date="activeDate"
            :is-passed-deadline="isPassedDeadline"
            :background-color="'var(--nxt-extralight-grey)'"
            @on-close="$emit('onClose')"
         />

         <StatusNotification v-if="planningRequestState === PlanningRequestStateKey.OUTDATED">
            <span>{{ $t('schedules.status.planning-request-outdated') }}</span>
            <br />
            <NuxtLink
               v-if="planningRequest?.slots?.[0]?.date"
               :to="`/schedule?active_date=${planningRequest.slots?.[0]?.date}`"
               @click="useResetState().resetState()"
            >
               {{ $t('schedules.view-my-schedule') }}
            </NuxtLink>
            <NuxtLink v-else :to="`/schedule`" @click="useResetState().resetState()">
               {{ $t('schedules.view-my-schedule') }}
            </NuxtLink>
         </StatusNotification>
      </template>

      <template #planned-hours>
         <div v-if="!pending && planningRequestState !== PlanningRequestStateKey.OUTDATED" class="planning-request-modal__planned-hours">
            <ScheduleToggleDayCard
               v-for="slot in planningRequest?.slots || []"
               :key="slot.sync_id"
               :planning-request-slot="slot"
               :planning-request="planningRequest"
            />

            <strong>
               {{ $t('schedules.total-days', { amount: planningRequest?.slots?.length ?? 0 }, planningRequest?.slots?.length) }}
            </strong>
         </div>

         <div v-else-if="planningRequestState !== PlanningRequestStateKey.OUTDATED" class="planning-request-modal__planned-hours">
            <LoadingScheduleToggleDayCard v-for="i in 4" :key="i" />
         </div>
      </template>

      <template #location>
         <SchedulePlanningRequestLocation v-if="planningRequest?.squad" :squad="planningRequest.squad" />
      </template>

      <template v-if="hasActions" #actions>
         <div v-if="planningRequestState === PlanningRequestStateKey.APPROVED" class="planning-request-modal__actions">
            <template v-for="action in actions" :key="action.key">
               <ButtonMain
                  v-if="actions.length <= 3"
                  :class="'planning-request-modal__actions__action'"
                  :background-color="'var(--nxt-dark)'"
                  :color="'var(--nxt-white)'"
                  :icon-name="action.icon"
                  :icon-position="'left'"
                  :icon-label="'navigeer'"
                  :icon-style="{ fontSize: '1.5rem' }"
                  @click.stop="action.action"
               >
                  {{ action.label }}
               </ButtonMain>
               <ButtonMain
                  v-else
                  :class="'planning-request-modal__actions__action'"
                  :background-color="'var(--nxt-dark)'"
                  :color="'var(--nxt-white)'"
                  :icon-name="action.icon"
                  :icon-position="'left'"
                  :icon-label="'navigeer'"
                  :icon-style="{ fontSize: '1.5rem' }"
                  @click.stop="action.action"
               />
            </template>
         </div>

         <div
            v-else-if="planningRequest && planningRequestState === PlanningRequestStateKey.SENT && !isPassedDeadline"
            class="planning-request-modal__actions"
         >
            <ButtonMain
               class="planning-request-modal__actions__action"
               :background-color="'var(--nxt-red)'"
               :color="'var(--nxt-white)'"
               :icon-name="'times'"
               :icon-position="'left'"
               :icon-label="$t('actions.i-cannot')"
               @on-click="confirmationSentCardRef?.refuse()"
            >
               {{ $t('actions.i-cannot') }}
            </ButtonMain>
            <ButtonMain
               class="planning-request-modal__actions__action"
               :background-color="'var(--nxt-plan-main)'"
               :color="'var(--nxt-white)'"
               :icon-name="'check'"
               :icon-position="'left'"
               :icon-label="$t('actions.i-confirm')"
               @on-click="() => confirmationSentCardRef?.confirm()"
            >
               {{ $t('actions.i-confirm') }}
            </ButtonMain>
         </div>
      </template>
   </ScheduleDayDetailsModal>
</template>

<style lang="scss" scoped>
.planning-request-modal__planned-hours {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}

.planning-request-modal__actions {
   width: 100%;
   display: flex;
   gap: var(--nxt-gutter-small);

   > * {
      flex: 1;
   }
}

.planning-request-modal__actions__action {
   overflow: hidden;
   &:deep(span) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
   }
}
</style>
