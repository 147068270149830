import { useBrandingStore } from '~/resources/branding.store';

export function useBranding() {
   const brandingStore = useBrandingStore();

   const { branding } = storeToRefs(brandingStore);

   return {
      ...storeToRefs(brandingStore),
      ...toRefs(branding.value),
   };
}
