import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { Directive, DirectiveBinding } from 'vue';

const purifier = DOMPurify();

function timeFormatter(el: HTMLElement, date: DirectiveBinding<Date | string>) {
   try {
      return (el.innerHTML = purifier.sanitize(dayjs(date.value).format('HH:mm')));
   } catch {
      return (el.innerHTML = '');
   }
}

const TimeFormatter: Directive = {
   mounted: timeFormatter,
   updated: timeFormatter,
};

function dayDateFormatter(el: HTMLElement, date: DirectiveBinding<Date | string>) {
   try {
      return (el.innerHTML = purifier.sanitize(dayjs(date.value).format('dd DD/MM')));
   } catch {
      return (el.innerHTML = '');
   }
}

const DayDateFormatter: Directive = {
   mounted: dayDateFormatter,
   updated: dayDateFormatter,
};

function dateFormatter(el: HTMLElement, date: DirectiveBinding<Date | string>) {
   try {
      return (el.innerHTML = purifier.sanitize(dayjs(date.value).format('DD/MM/YYYY')));
   } catch {
      return (el.innerHTML = '');
   }
}

const DateFormatter: Directive = {
   mounted: dateFormatter,
   updated: dateFormatter,
};

export { DateFormatter, DayDateFormatter, TimeFormatter };
