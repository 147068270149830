import {
   GetPlaceCommand,
   LocationClient,
   SearchPlaceIndexForSuggestionsCommand,
   SearchPlaceIndexForTextCommand,
   type SearchForSuggestionsResult,
} from '@aws-sdk/client-location';
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper';
import { Map, Marker, type LngLatLike, type MapOptions } from 'maplibre-gl';

export function useLocationService() {
   // #region globals
   const { awsLocationsApiKey, awsPlacesIndex, awsRegion, awsMapName } = useRuntimeConfig().public;

   const client = ref<LocationClient>();
   // #endregion

   // #region Lifecycle
   async function setup() {
      try {
         const auth = await withAPIKey(awsLocationsApiKey);
         client.value = new LocationClient({
            region: awsRegion,
            ...auth.getLocationClientConfig(),
         });
      } catch {
         console.warn('Could not setup location service');
      }
   }

   onUnmounted(() => {
      client.value?.destroy();
   });
   // #endregion

   // #region search places
   const input = ref('');
   const places = ref<Array<SearchForSuggestionsResult>>();
   const error = ref<any>();

   const searchPlaces = useDebounceFn(async (input?: string) => {
      try {
         if (!client.value) await setup();

         const command = new SearchPlaceIndexForSuggestionsCommand({
            Text: input,
            IndexName: awsPlacesIndex,
            MaxResults: 10,
         });

         places.value = (await client.value!.send(command))?.Results ?? [];
         return places.value;
      } catch (e) {
         error.value = e;
      }
   }, 200);

   watch(input, searchPlaces);
   // #endregion

   // #region Get Place Details
   const address = ref<Partial<Address>>();

   async function getPlaceById(placeId: string) {
      address.value = undefined;

      try {
         if (!client.value) await setup();

         const command = new GetPlaceCommand({ IndexName: awsPlacesIndex, PlaceId: placeId, Language: 'en' });
         const place = (await client.value!.send(command)).Place;

         if (!place) return null;

         let city: City | undefined;

         if (place?.Municipality) {
            const results = await authFetch<ApiResponse<Array<City>>>(CITY_ENDPOINTS.GET(), {
               params: { search: place.Municipality, per_page: 1, page: 1 },
            });

            city = results?.data?.[0];
         }

         address.value = {
            city_id: city?.id,
            city,
            street: place.Street?.replaceAll(' ', ''),
            house_number: place.AddressNumber,
            box: place.UnitNumber,
            longitude: place.Geometry?.Point?.[0],
            latitude: place.Geometry?.Point?.[1],
         };

         return address.value;
      } catch (e) {
         error.value = e;
      }
   }
   // #endregion

   // #region Geocode
   async function getGeocode(place: string) {
      try {
         if (!client.value) await setup();

         const command = new SearchPlaceIndexForTextCommand({
            IndexName: awsPlacesIndex,
            Text: place,
            MaxResults: 1,
         });
         const response = await client.value!.send(command);
         return response?.Results?.[0]?.Place?.Geometry?.Point as LngLatLike;
      } catch (e) {
         error.value = e;
      }
   }
   // #endregion

   // #region Display Map
   const mapContainer = ref<HTMLElement>();
   const map = ref<Map>();
   const mapLoading = ref(false);

   async function initMap(mapOptions: Partial<MapOptions>) {
      try {
         await nextTick();
         if (!mapContainer.value) return;

         mapLoading.value = true;

         map.value = new Map({
            ...mapOptions,
            container: mapContainer.value,
            maplibreLogo: false,
            attributionControl: false,
            style: `https://maps.geo.${awsRegion}.amazonaws.com/maps/v0/maps/${awsMapName}/style-descriptor?key=${awsLocationsApiKey}`,
         });

         map.value.on('load', () => {
            mapLoading.value = false;
         });

         return map.value;
      } catch {
         mapLoading.value = false;
      }
   }

   function addMarker(point: LngLatLike) {
      if (!map.value) return;

      const marker = new Marker().setLngLat(point).addTo(map.value);

      return marker;
   }

   // #endregion

   return { mapContainer, map, input, places, error, searchPlaces, getPlaceById, initMap, addMarker, getGeocode };
}
