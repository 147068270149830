import { vMaska } from 'maska';
import { FontColorContraster } from '~/directives/color-contrast.directive';
import { DateFormatter, DayDateFormatter, TimeFormatter } from '~/directives/date-formatter.directive';
import { PurifyFormatter } from '~/directives/dom-purify.directive';

export default defineNuxtPlugin((nuxt) => {
   nuxt.vueApp.directive('time-formatter', TimeFormatter);
   nuxt.vueApp.directive('day-date-formatter', DayDateFormatter);
   nuxt.vueApp.directive('date-formatter', DateFormatter);
   nuxt.vueApp.directive('purify-html', PurifyFormatter);
   nuxt.vueApp.directive('maska', vMaska);
   nuxt.vueApp.directive('font-color-contrast', FontColorContraster);
});
