import type { RequestMethod, ValidationConfig } from 'laravel-precognition';
import { client, useForm } from 'laravel-precognition-vue';

export interface Options<Data> {
   method?: RequestMethod | (() => RequestMethod);
   url: string | (() => string);
   initialValues?: Data;
   config?: ValidationConfig;
   auth?: boolean;
}

export type PrecognitionForm = {
   form: ReturnType<typeof useForm>;
};

export function usePrecognitionForm<Data extends {}>({
   method = 'patch',
   url,
   initialValues = {} as Data,
   config,
   auth = true,
}: Options<Data>) {
   const { tenantKey } = useBranding();
   const { locale } = useI18n();

   // #region Client
   client.axios().defaults.baseURL = useRuntimeConfig().public.apiBaseUrl;
   client.axios().interceptors.request.use(async (config) => {
      if (auth) {
         const token = await useKeycloakStore().getToken();
         config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers['Content-Type'] = 'application/json';
      config.headers.Accept = 'application/json';
      config.headers['App-Domain'] = tenantKey.value;
      config.headers['Accept-Language'] = locale.value ?? 'nl';

      return config;
   });

   // #region Form
   const form = useForm(method, url, initialValues, { ...config });
   form.setValidationTimeout(300);

   const hasChanges = computed(() => Object.keys(initialValues).some((key) => form.touched(key as keyof Data)));
   // #endregion

   provide('form', { form });

   return { form, hasChanges };
}
