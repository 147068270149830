export interface FormElementParams {
   name: Ref<string>;
   label: Ref<string>;
   disabled: Ref<boolean>;
   required: Ref<boolean>;
   invalid: Ref<boolean>;
   touch: () => void;
   dirty: Ref<boolean>;
}

export function useFormElement() {
   return inject('formElement', {} as FormElementParams);
}
