export function useTotalPerformedHours(slot: Ref<Slot | undefined>) {
   const dayjs = useDayjs();

   const activeSlot = computed(() => {
      if (!slot.value?.performed_hours?.length) return null;
      return slot.value?.performed_hours?.[slot.value?.performed_hours?.length - 1];
   });

   const shouldPoll = computed(() => {
      return !activeSlot.value?.end_time;
   });

   const secondsPassed = ref(0);

   const { pause, resume } = useTimeoutPoll(() => {
      secondsPassed.value += 1;
   }, 1000);

   function togglePolling(shouldPoll: boolean) {
      if (shouldPoll) {
         return resume();
      }
      return pause();
   }

   function formatSeconds(seconds: number) {
      const hours = Math.floor(seconds / 3600)
         .toString()
         .padStart(2, '0');
      const minutes = Math.floor((seconds % 3600) / 60)
         .toString()
         .padStart(2, '0');
      const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
      return `${hours}:${minutes}:${remainingSeconds}`;
   }

   const totalPerformedHours = ref('00:00:00');

   function getSecondsPerformed() {
      if (slot.value?.seconds_performed) return slot.value.seconds_performed;

      return (slot.value?.performed_hours ?? [])
         .filter((ph) => !!ph.ended_at)
         .reduce((acc, performedHour) => {
            const startedAt = dayjs(performedHour.started_at);
            const endedAt = dayjs(performedHour.ended_at);
            return acc + endedAt.diff(startedAt, 'second');
         }, 0);
   }

   watch(
      [secondsPassed, slot],
      () => {
         if (!activeSlot.value?.started_at) return (totalPerformedHours.value = '00:00:00');

         const secondsPerformed = getSecondsPerformed();

         if (activeSlot.value.ended_at) {
            return (totalPerformedHours.value = formatSeconds(secondsPerformed));
         }

         const secondsSinceStartedAt = dayjs().diff(dayjs(activeSlot.value?.started_at), 'second');
         return (totalPerformedHours.value = formatSeconds(secondsSinceStartedAt + secondsPerformed));
      },
      { immediate: true },
   );

   watch(shouldPoll, togglePolling, { immediate: true });

   return {
      totalPerformedHours,
   };
}
