import type { TenantStyles } from '~/models/tenant-branding.interface';

import { MONEEEZ_BRANDING } from './moneeez.branding';
import { MY_AGO_BRANDING } from './myago.branding';
import { MY_LETS_WORK_BRANDING } from './myletswork.branding';
import { NXT_PLAN_BRANDING } from './nxt-plan.branding';

const tenantKeys = ['nxt-plan', 'moneeez', 'myletswork', 'myago'];

const tenantBrandings: TenantStyles = {
   'nxt-plan': NXT_PLAN_BRANDING,
   moneeez: MONEEEZ_BRANDING,
   myletswork: MY_LETS_WORK_BRANDING,
   myago: MY_AGO_BRANDING,
};

export { tenantBrandings, tenantKeys };
