<script lang="ts" setup>
import type { LngLatLike } from 'maplibre-gl';
import type { PlanningRequestSquad } from '~/resources/planning-request/planning-request.interface';

const { openMaps } = useLinks();

const props = defineProps<{
   squad: PlanningRequestSquad;
}>();

const address = computed(() => {
   if (!props.squad) return '';
   return `${props.squad?.street} ${props.squad?.house_number}, ${props.squad?.city?.name}`;
});

const coords = computed(() => {
   if (!props.squad.latitude || !props.squad.longitude) return undefined;
   return { lat: props.squad.latitude, lng: props.squad.longitude };
});

const { mapContainer, initMap, addMarker } = useLocationService();

async function setupMap(center?: LngLatLike) {
   await initMap({ zoom: 14, center, interactive: false });
   if (center) addMarker(center);
}

watch(coords, setupMap, { immediate: true });
</script>

<template>
   <div :class="'schedule-planning-request-location'">
      <LayoutCard :background-color="'var(--nxt-extralight-grey)'">
         <div class="schedule-planning-request-location__address">
            <i class="fa-solid fa-map-marked-alt"></i>
            <ButtonLink
               class="schedule-planning-request-location__address-link"
               :text-align="'start'"
               @on-click="openMaps({ coords, address })"
            >
               {{ squad?.street }} {{ squad?.house_number }}<br />{{ squad?.city?.postal_code }}
               {{ squad?.city?.name }}
            </ButtonLink>
         </div>
         <div ref="mapContainer" class="schedule-planning-request-location__map" @click="openMaps({ coords, address })"></div>
      </LayoutCard>
   </div>
</template>

<style lang="scss" scoped>
.schedule-planning-request-location {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
   height: 100%;
}

.schedule-planning-request-location__address {
   display: flex;
   gap: var(--nxt-gutter-small);
   align-items: center;
}

.schedule-planning-request-location__address-link {
   color: var(--nxt-dark);
}

.schedule-planning-request-location__map {
   border-radius: var(--nxt-radius);
   overflow: hidden;
   height: 200px;
}
</style>
