<script lang="ts" setup>
// #region Globals
const userInfoStore = useUserInfoStore();
const { duoTone } = useBranding();

const firstName = computed(() => userInfoStore.userInfo?.first_name);
// #endregion

// #region Props
defineEmits(['onClose']);

const props = defineProps({
   jobId: { type: String, default: null },
});
// #endregion

// #region Job
const { updateJob } = usePositionStore();
const { data: job, refresh } = useAuthFetch<Position>(POSITION_ENDPOINTS.GET(props.jobId), {
   onResponse: ({ response }) => updateJob(response._data),
});
// #endregion

// #region Form
const { required } = useFormValidators();
const { data, validation } = useForm<{ comment: string }>({
   rules: () => ({ comment: { required } }),
});
// #endregion

// #region Apply
const showSuccess = ref(false);
const loading = ref(false);

async function apply() {
   try {
      if (!job.value) return;

      loading.value = true;
      await authFetch(POSITION_ENDPOINTS.APPLY(job.value?.sync_id), { method: 'POST', body: JSON.stringify(data.value) });
      showSuccess.value = true;
   } finally {
      loading.value = false;
      refresh();
   }
}

// #endregion
</script>

<template>
   <Teleport to=".app">
      <FormContainer :data="data" :validation="validation" @on-valid-submit="apply">
         <ModalSide :title="`${$t('jobs.apply-now')}!`" @on-close="$emit('onClose')">
            <div :class="'jobs-apply-modal'">
               <p>
                  {{
                     $t('jobs.apply-for-job-info', {
                        job_function: job?.title,
                        customer: job?.customer?.name,
                        city: job?.address?.city?.name,
                     })
                  }}
               </p>

               <NuxtImg :src="'/images/jobs/apply-now.svg'" :quality="100" :height="130" />

               <FormElement :name="'comment'" :label="$t('jobs.note-for-employer')">
                  <FormTextarea v-model="data.comment" />
               </FormElement>
            </div>

            <template #actions="{ onClose }">
               <ButtonLink :disabled="loading" :color="'var(--nxt-dark)'" @click="onClose">
                  {{ $t('actions.cancel') }}
               </ButtonLink>
               <ButtonMain :disabled="loading" :type="'submit'" :background-color="duoTone ? 'var(--nxt-secondary)' : 'var(--nxt-main)'">
                  {{ $t('jobs.i-apply') }}
               </ButtonMain>
            </template>
         </ModalSide>

         <ModalSide v-if="showSuccess" @on-close="() => $emit('onClose')">
            <div class="jobs-apply-modal__success">
               <NuxtImg :src="'/images/jobs/apply-success.svg'" :quality="100" :width="68" />
               <p>
                  {{ $t('apply.success', { first_name: firstName }) }}
               </p>
            </div>
         </ModalSide>
      </FormContainer>
   </Teleport>
</template>

<style lang="scss" scoped>
.jobs-apply-modal {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   gap: var(--nxt-gutter);
   gap: var(--nxt-gutter-large);
   height: 100%;
}

.jobs-apply-modal__success {
   display: flex;
   height: 100%;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: var(--nxt-gutter-large);
   text-align: center;
}
</style>
