import type { KeycloakConfig, KeycloakLoginOptions } from 'keycloak-js';
import Keycloak from 'keycloak-js';
import { defineStore } from 'pinia';
import { unsubscribeFromWebPush } from '~/pages/my-week/utils/push.helper';

export const useKeycloakStore = defineStore('keycloak', () => {
   const runtimeConfig = useRuntimeConfig().public;

   const config: KeycloakConfig = {
      url: `${runtimeConfig.keycloakUrl}/auth`,
      realm: runtimeConfig.keycloakRealm,
      clientId: runtimeConfig.keycloakClientId,
   };

   const keycloak = ref<Keycloak>();
   const keycloakReady = ref(false);
   const keycloakAuthenticated = ref(false);

   function init() {
      keycloak.value = new Keycloak(config);

      keycloak.value.onReady = () => {
         keycloakReady.value = true;
      };
      keycloak.value.onAuthSuccess = () => {
         keycloakAuthenticated.value = true;
      };
      keycloak.value.onAuthError = () => {
         keycloakAuthenticated.value = false;
      };

      keycloak.value.onAuthLogout = () => {
         caches.delete('nxt-api-cache');
         caches.delete('nxt-fontawesome-cache');
         caches.delete('nxt-fonts-cache');
      };

      return keycloak.value.init({
         onLoad: 'check-sso',
         checkLoginIframeInterval: 5,
         silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
         responseMode: 'query',
         scope: 'offline_access',
      });
   }

   async function logout(redirectUri = window.location.href) {
      try {
         await unsubscribeFromWebPush();
      } finally {
         keycloak.value?.logout({ redirectUri });
      }
   }

   function login(options?: KeycloakLoginOptions) {
      const { $i18n } = useNuxtApp();
      const url = keycloak.value?.createLoginUrl({ ...options, locale: ($i18n as any).locale.value, scope: 'offline_access' });
      if (url) window.location.replace(url);
   }

   async function getToken() {
      await keycloak.value?.updateToken(5);
      return keycloak.value?.token;
   }

   function waitOnKeycloak() {
      return new Promise((resolve) => checkKeycloakReady(resolve));
   }

   function checkKeycloakReady(resolve: (value?: any) => void) {
      if (keycloakReady.value) resolve();
      else {
         setTimeout(() => checkKeycloakReady(resolve), 50);
      }
   }

   return {
      keycloak,
      keycloakReady,
      keycloakAuthenticated,
      init,
      login,
      logout,
      getToken,
      waitOnKeycloak,
   };
});
