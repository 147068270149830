export interface PersonTodoEndpoints extends Pick<Endpoints, 'GET' | 'GET_ALL'> {
   GET: (syncId?: string) => string;
   GET_ALL: () => string;

   COMPLETE: (syncId: string) => string;
}

export const PERSON_TODO_ENDPOINTS: PersonTodoEndpoints = {
   GET: (syncId?: string) => `/person-todos/${syncId}`,
   GET_ALL: () => `/person-todos`,
   COMPLETE: (syncId: string) => `/person-todos/${syncId}/complete`,
};
