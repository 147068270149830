import type { Slot } from '~/models/slot.interface';
import type { City } from '../city/city.interface';

export interface PlanningRequestSlot extends Slot {
   sync_id: string;
   date: string;
   is_available: boolean;
}

export enum PlanningRequestTypeKey {
   PlanningReserve = 'planning-reserve',
   PlanningConfirmation = 'planning-confirmation',
   PlanningProposal = 'planning-proposal',
}
export interface PlanningRequestType {
   id: number;
   key: PlanningRequestTypeKey;
   name: string;
   color_code: string;
}

export interface PlanningRequestProperty {
   name: string;
   icon: string;
}

export interface PlanningRequestSquad {
   id: number;
   name: string;
   street?: string;
   house_number?: string;
   box?: string;
   city_id?: number;
   city?: City;
   longitude?: number;
   latitude?: number;
}

export interface PlanningRequest {
   sync_id: string;
   must_be_handled_at: string;
   period_summary: string;
   tenant: Tenant;
   job_title: string;
   company_name: string;
   planning_request_state: PlanningRequestState;
   planning_request_type: PlanningRequestType;
   period: string;
   timetable_name: string;
   is_dirty: boolean;
   job_address: string;
   slots: PlanningRequestSlot[];
   properties: Array<PlanningRequestProperty>;
   team_phone_number?: string;

   squad?: PlanningRequestSquad;
   user?: UserInfo;
}
