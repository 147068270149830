<script lang="ts" setup>
const emits = defineEmits<{ (e: 'update:modelValue', value: any): void }>();

const props = defineProps({
   modelValue: { type: [String, Number, Boolean], default: null },
   options: { type: Array as PropType<Array<{ name: string; id: string | number }>>, default: () => [{}] },
   direction: { type: String as PropType<'vertical' | 'horizontal'>, default: 'vertical' },
   color: { type: String, default: 'var(--nxt-main)' },
   emitObject: { type: Boolean, default: false },
});

const { name, disabled, required, invalid, touch } = useFormElement();

function updateModelValue(value?: string | number | boolean) {
   if (touch) touch();
   if (props.emitObject) {
      const option = props.options.find((option) => option.id === value);
      emits('update:modelValue', option);
   } else {
      emits('update:modelValue', value);
   }
}
</script>

<template>
   <div :class="['radio-button-group', `-${direction}`]">
      <input
         :name="name"
         :disabled="disabled"
         :required="required"
         :value="modelValue"
         style="height: 0; width: 0; border: none; padding: 0; position: absolute; z-index: -1"
      />
      <FormRadioButton
         v-for="option in options"
         :key="`${name}-${option.id}`"
         :name="`${name}-${option.id}`"
         :disabled="disabled"
         :required="required"
         :model-value="modelValue"
         :value="option.id"
         :label="option.name"
         :invalid="invalid"
         :color="color"
         @update:model-value="updateModelValue"
      >
         <template #label>
            <slot name="label" v-bind="{ option }"></slot>
         </template>
      </FormRadioButton>
   </div>
</template>

<style lang="scss" scoped>
.radio-button-group {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-large);

   &.-horizontal {
      flex-direction: row;
      gap: var(--nxt-gutter);
   }
}
</style>
