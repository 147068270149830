<script lang="ts" setup>
// #region Form Element
const { name, invalid, required, disabled, label, touch } = useFormElement();
// #endregion

// #region Props & Emits
const emits = defineEmits<{ (e: 'update:modelValue', value?: string): void }>();
const props = defineProps({
   placeholder: { type: String, default: '' },
   minLength: { type: Number, default: null },
   maxLength: { type: Number, default: null },
   modelValue: { type: [String], default: '' },
   modelModifiers: { type: Object, default: () => ({}) },

   minHeight: { type: String, default: '7rem' },
   maxHeight: { type: String, default: '12rem' },
   minWidth: { type: String, default: '100%' },
   maxWidth: { type: String, default: '100%' },
});
// #endregion

// #region Model Value
function handleInput(event: Event) {
   if (props.modelModifiers?.lazy) return;
   if (touch) touch();
   emits('update:modelValue', (event?.target as HTMLTextAreaElement)?.value);
}
function handleChange(event: Event) {
   if (!props.modelModifiers?.lazy) return;
   if (touch) touch();
   emits('update:modelValue', (event?.target as HTMLTextAreaElement)?.value);
}
// #endregion
</script>

<template>
   <textarea
      :id="name"
      :class="['textarea', { invalid }]"
      :style="{ minHeight, maxHeight, minWidth, maxWidth }"
      :name="name"
      :value="modelValue"
      :placeholder="placeholder || label"
      :disabled="disabled"
      :required="required"
      :minlength="minLength"
      :maxlength="maxLength"
      autocomplete="off"
      @input="handleInput"
      @change="handleChange"
   />
</template>

<style lang="scss">
.textarea {
   resize: both;

   height: fit-content;
   min-height: v-bind(minHeight);
   max-height: v-bind(minHeight);

   width: 100%;
   min-width: v-bind(minWidth);
   max-width: v-bind(maxWidth);
   line-height: toRem(20);
}

.textarea.invalid {
   border-color: var(--nxt-red);
}
</style>
