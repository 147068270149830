<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
const { promptConfirmation } = useModalStore();
// #endregion

// #region Props & Emits
const emits = defineEmits<{
   (e: 'onClose'): void;
   (e: 'touchUp', event: Event): void;
   (e: 'touchDown', event: Event): void;
}>();
const props = defineProps({
   title: { type: String, default: '' },
   titleIconName: { type: String, default: null },
   height: { type: String, default: 'fit-content' },
   minHeight: { type: String, default: 'unset' },
   maxHeight: { type: String, default: 'var(--nxt-modal-layer-1)' },
   width: { type: String, default: '40rem' },
   type: { type: String as PropType<'close' | 'return' | 'clean'>, default: 'close' },
   headingEnabled: { type: Boolean, default: true },

   canClose: { type: Boolean, default: true },
   customClose: { type: Boolean, default: false },
   onReturn: { type: Function as PropType<() => void>, default: null },

   actionBackgroundColor: { type: String, default: 'var(--nxt-extralight-grey)' },
   action: { type: Function as PropType<() => void>, default: null },
   actionIcon: { type: String, default: 'plus' },
   actionIconLabel: { type: String, default: 'Add' },

   hasUnsavedChanges: { type: Boolean, default: false },
   saving: { type: Boolean, default: false },
});
// #endregion

// #region Escape
const { escape } = useMagicKeys();
const modalRef = ref<HTMLElement | null>(null);

function onEscape(escapePressed: boolean) {
   if (!escapePressed) return;
   const modals = document.querySelectorAll('.modal-side');
   const lastModal = modals[modals.length - 1];

   if (modalRef.value?.isSameNode(lastModal)) {
      if (props.hasUnsavedChanges) {
         promptConfirmation({
            title: t('generic.unsaved-changes'),
            confirmText: t('actions.dont-save'),
            cancelText: t('actions.cancel'),
            confirmCallback: close,
         });
      } else {
         close();
      }
   }
}

watch(escape, onEscape);
// #endregion

// #region Modal Heading
const modalHeadingRef = ref<HTMLElement | null>(null);
defineExpose({
   modalHeadingRef,
});
// #endregion

// #region Scroll & Touch
const containerRef = ref<HTMLElement | null>(null);
const touchStartY = ref<number>();
const touchStartX = ref<number>();

const handleTouchStart = (event: TouchEvent) => {
   touchStartY.value = event.touches[0].clientY || 0;
   touchStartX.value = event.touches[0].clientX || 0;
};

const handleTouchMove = (event: TouchEvent) => {
   const touch = event.touches[0];
   const deltaY = touch.clientY - (touchStartY.value || 0);
   const deltaX = touch.clientX - (touchStartX.value || 0);
   if (Math.abs(deltaX) > 5) {
      return;
   }
   if (deltaY < 0) {
      emits('touchUp', event);
   } else if (deltaY > 0) {
      emits('touchDown', event);
   }
};

onMounted(() => {
   const container = containerRef.value;
   container?.addEventListener('touchstart', handleTouchStart, { passive: true });
   container?.addEventListener('touchmove', handleTouchMove, { passive: true });
});

onUnmounted(() => {
   const container = containerRef.value;
   container?.removeEventListener('touchstart', handleTouchStart);
   container?.removeEventListener('touchmove', handleTouchMove);
});
// #endregion

// #region Transition
const visible = ref(false);

onMounted(() => {
   visible.value = true;
});

function close() {
   if (!props.canClose) return;

   if (props.customClose) return emits('onClose');

   function handleClose() {
      visible.value = false;

      setTimeout(() => {
         emits('onClose');
      }, 300);
   }

   if (props.hasUnsavedChanges) {
      promptConfirmation({
         title: t('generic.unsaved-changes'),
         confirmText: t('actions.dont-save'),
         cancelText: t('actions.cancel'),
         confirmCallback: handleClose,
      });
   } else {
      handleClose();
   }
}

onMounted(() => {
   if (process.client) {
      document.body.style.overflow = 'hidden';
   }
});

onUnmounted(() => {
   const hasOtherModals = document.querySelectorAll('.modal-side').length > 1;
   if (process.client && !hasOtherModals) {
      document.body.style.overflow = 'unset';
   }
});
// #endregion
</script>

<template>
   <div v-bind="$attrs" ref="modalRef" :class="['modal-side', { visible }]" @click.self="close">
      <Transition name="modal-side">
         <div v-if="visible" ref="containerRef" class="modal-side__card">
            <div v-if="headingEnabled" ref="modalHeadingRef" class="modal-side__card__heading">
               <ButtonIcon
                  v-if="type === 'return'"
                  class="modal-side__card__return"
                  :icon-label="$t('actions.return')"
                  :icon-name="'chevron-left'"
                  @click="onReturn ? onReturn() : close()"
               />
               <h4 :class="['modal-side__card__title', type]">
                  <i v-if="titleIconName && type !== 'return'" :class="['fa-solid', `fa-${titleIconName}`]"></i>
                  <span>{{ title }}</span>
               </h4>
               <div class="modal-side__card__heading__action">
                  <ButtonIcon
                     v-if="canClose && (type === 'close' || customClose)"
                     class="modal-side__card__action"
                     :icon-label="$t('actions.close-type', { type: title })"
                     :icon-name="'times'"
                     @click="close"
                  />
                  <ButtonIcon
                     v-else-if="action"
                     :class="'modal-side__card__action'"
                     :icon-label="actionIconLabel"
                     :icon-name="actionIcon"
                     @on-click="action()"
                  />
               </div>
            </div>
            <div class="modal-side__card__content">
               <slot></slot>
            </div>

            <div v-if="$slots.actions" class="modal-side__card__actions">
               <slot name="actions" v-bind="{ onClose: close }"></slot>
            </div>

            <Transition name="fade">
               <div v-if="saving" class="modal-side__saving">
                  <StatusLoader />
               </div>
            </Transition>
         </div>
      </Transition>
   </div>
</template>

<style lang="scss" scoped>
.modal-side {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: var(--zindex-modal);

   display: flex;
   flex-direction: column;
   justify-content: flex-end;

   background-color: none;
   transition: background-color 0.3s ease-in-out;

   &.visible {
      background-color: rgba(0, 0, 0, 0.3);
   }

   @include tablet {
      flex-direction: row;
      align-items: flex-end;
   }
}
.modal-side__saving {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.1);
   z-index: var(--zindex-popover);
}
.modal-side__card {
   height: v-bind(height);
   min-height: v-bind(minHeight);
   max-height: v-bind(maxHeight);
   overflow: hidden;

   display: flex;
   flex-direction: column;

   background-color: var(--nxt-white);
   border-radius: var(--nxt-radius) var(--nxt-radius) 0 0;
   box-shadow: var(--nxt-shadow);
   padding: 0 var(--nxt-gutter);

   @include tablet {
      width: v-bind(width);
      min-width: 20rem;
      height: 100vh;
      height: 100dvh;
      max-height: unset;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
   }
}

.modal-side__card__heading {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: var(--nxt-gutter);
   margin: 0 var(--nxt-gutter--negative);
}

.modal-side__card__title {
   margin: 0;
   &.return {
      margin-left: toRem(-32);
   }
   &::first-letter {
      text-transform: capitalize;
   }
   i {
      margin-right: var(--nxt-gutter-small);
   }

   span {
      text-transform: lowercase;
   }
}

.modal-side__card__action {
   padding: 0;
}

.modal-side__card__content {
   flex: 1;
   padding-bottom: var(--nxt-gutter);
   overflow-y: auto;
   overflow-x: hidden;
   height: 100%;
}

.modal-side__card__actions {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: var(--nxt-gutter-large);
   position: relative;

   min-height: toRem(68);

   padding-bottom: env(safe-area-inset-bottom);
   background-color: v-bind(actionBackgroundColor);

   &::before {
      content: '';
      width: var(--nxt-gutter);
      height: 100%;
      background-color: v-bind(actionBackgroundColor);
      position: absolute;
      left: var(--nxt-gutter--negative);
      top: 0;
   }
   &::after {
      content: '';
      width: var(--nxt-gutter);
      height: 100%;
      background-color: v-bind(actionBackgroundColor);
      position: absolute;
      right: var(--nxt-gutter--negative);
      top: 0;
   }
}
</style>
