<script lang="ts" setup>
import { PlanningKey } from '~/resources/schedule/planning/planning.interface';

const dayjs = useDayjs();
const emits = defineEmits<{ (e: 'onChangeActiveDate', date: string): void }>();
const props = defineProps({
   showDayOfWeek: { type: Boolean, default: true },
   placeholder: { type: Boolean, default: false },
   number: { type: Number, required: true },
   date: { type: String, required: true },
   activeDateFormatted: { type: String, default: null },
});

const schedule = inject('schedule', ref<Schedule>({}));

const classes = computed(() => {
   const currentDate = dayjs().format('YYYY-MM-DD');
   const { availability, planning } = schedule.value?.[props.date] || {};

   const hasStartAndEndTime = availability?.start_time && availability?.end_time;
   const isCurrentDate = currentDate === props.date;
   const isActiveDate = props.activeDateFormatted === props.date;
   const isUnavailable = availability?.is_available === false;
   const isPartiallyAvailable = availability?.is_available === true && hasStartAndEndTime;
   const isAvailable = availability?.is_available === true && !hasStartAndEndTime;

   return {
      placeholder: props.placeholder,
      current_date: isCurrentDate,
      active: isActiveDate,
      unavailable: isUnavailable,
      partially_available: isPartiallyAvailable,
      available: isAvailable,
      presence_show: planning?.key === PlanningKey.PRESENCE_SHOW,
      presence_no_show: planning?.key === PlanningKey.PRESENCE_NO_SHOW,
      planning_confirmation_sent: planning?.key === PlanningKey.PLANNING_CONFIRMATION_SENT,
      planning_confirmation_approved: planning?.key === PlanningKey.PLANNING_CONFIRMATION_APPROVED,
      planning_reserve_sent: planning?.key === PlanningKey.PLANNING_RESERVE_SENT,
      planning_reserve_approved: planning?.key === PlanningKey.PLANNING_RESERVE_APPROVED,
   };
});
</script>

<template>
   <LayoutDay
      class="calendar-day"
      :show-day-of-week="showDayOfWeek"
      :day-of-week="dayjs(date).format('dd')"
      :status="classes"
      @on-click="emits('onChangeActiveDate', date)"
   >
      {{ number }}
   </LayoutDay>
</template>

<style lang="scss">
.calendar-day {
   width: unset;
}
</style>
