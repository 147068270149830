<script lang="ts" setup>
import type { CSSProperties, StyleValue } from 'vue';

// #region Props & Emits
const props = defineProps({
   logo: { type: String, default: null },
   name: { type: String, default: null },

   height: { type: Number, default: 50 },
   width: { type: Number, default: 50 },

   background: { type: String, default: 'var(--nxt-base-background)' },
   color: { type: String, default: 'var(--nxt-main)' },
   border: { type: String, default: 'none' },
   objectFit: { type: String as PropType<CSSProperties['object-fit']>, default: 'contain' },
});
// #endregion

// #region Logo
const localLogo = ref<string>();

function updateLogo(logo?: string) {
   localLogo.value = logo;
}

watch(() => props.logo, updateLogo, { immediate: true });
// #endregion

// #region Initials
const initials = computed<string>(() => {
   if (!props.name) return '';
   const nameParts = props.name?.split(' ');
   if (nameParts?.length === 2) return `${nameParts[0]?.charAt(0)}${nameParts[1]?.charAt(0)}`;
   return nameParts[0].charAt(0);
});
// #endregion

// #region Height & Width
const style = computed<StyleValue>(() => {
   return {
      minHeight: `${props.height}px`,
      minWidth: `${props.width}px`,
      height: `${props.height}px`,
      width: `${props.width}px`,
      backgroundColor: props.background,
      border: props.border,
      color: props.color,
      objectFit: props.objectFit,
      fontSize: `${props.height / 3}px`,
   };
});

// #endregion
</script>

<template>
   <NuxtImg
      v-if="localLogo"
      :class="'circular-logo'"
      :style="style"
      :src="localLogo"
      :alt="name || initials || 'N/A'"
      :title="name"
      :width="width"
      :height="height"
      :quality="100"
      @error="updateLogo()"
   />
   <div v-else :style="style" class="circular-logo">
      <span>{{ initials }}</span>
   </div>
</template>

<style lang="scss" scoped>
.circular-logo {
   border-radius: 50%;
   object-fit: v-bind(objectFit);
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--nxt-main);
   color: var(--nxt-white);
}
</style>
