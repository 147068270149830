<script lang="ts" setup>
import { LANGUAGE_ENDPOINTS } from '~/resources/language/language.endpoints';
import { SCORE_ENDPOINTS } from '~/resources/score/score.endpoints';

// #region Props & Emits
defineEmits<{
   (e: 'onSave', data: PersonProfileLanguage): void;
   (e: 'onCancel'): void;
}>();

const props = defineProps<{ language?: PersonProfileLanguage }>();
// #endregion

// #region Score
const { data: scores } = await useAuthFetch<Array<Score>>(SCORE_ENDPOINTS.GET(), { lazy: false });
// #endregion

// #region Form
const { required } = useFormValidators();

const { data, validation } = useForm<PersonProfileLanguage>({
   config: { initialValues: JSON.parse(JSON.stringify({ ...(props.language || { score_id: scores.value?.at(2)?.id }) })) },
   rules: () => ({
      language_id: { required },
      score_id: { required },
   }),
});
// #endregion

// #region Methods
function updateScore(value: number) {
   const score = scores.value?.find((s) => s.value === value);
   if (!score) return;
   data.value.score_id = score.id;
   data.value.score = score;
}
// #endregion
</script>

<template>
   <TemplatesFormModal
      :type="'add'"
      :data-type="$t('generic.language')"
      :data="data"
      :validation="validation"
      :height="'var(--nxt-modal-layer-3)'"
      @on-save="$emit('onSave', data)"
      @on-cancel="$emit('onCancel')"
   >
      <div :class="'profile-language-form'">
         <FormElement :name="'language_id'" :label="$t('actions.select-type', { type: $t('generic.language') })">
            <FormSelect
               :model-value="data.language_id"
               :search-url="LANGUAGE_ENDPOINTS.GET()"
               :emit-object="true"
               :options-to-load-initially="25"
               :options="data.language ? [data.language] : []"
               :label-key="`name_translation.${$i18n.locale}`"
               @update:model-value="
                  (language) => {
                     data.language_id = language.id;
                     data.language = language;
                  }
               "
            />
         </FormElement>

         <FormElement :name="'level'" :label="$t('actions.decide-level')">
            <FormSlider
               :model-value="scores?.find((s) => s.id === data.score_id)?.value"
               :min="1"
               :max="4"
               :step="1"
               :show-value="true"
               @update:local-value="updateScore"
            />
         </FormElement>
      </div>
   </TemplatesFormModal>
</template>

<style lang="scss" scoped>
.profile-language-form {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.profile-language-form {
   display: flex;
   justify-content: space-between;
   gap: var(--nxt-gutter);
}
</style>
