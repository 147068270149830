<script lang="ts" setup>
import SwiperInstance from 'swiper';
import 'swiper/css';
import { Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Tabs } from './models/tab.interface';

// #region Props & Emits
const emits = defineEmits<{ (e: 'update:activeTabKey', tabKey: string): void }>();
const props = defineProps({
   tabs: { type: Array as PropType<Tabs>, required: true },
   activeTabKey: { type: String, required: true },
   stickyTabs: { type: Boolean, default: false },
   stickyTop: { type: String, default: '0' },
   activeColor: { type: String, default: 'var(--nxt-dark)' },
   inactiveColor: { type: String, default: 'var(--nxt-dark)' },
   lazy: { type: Boolean, default: false },
   autoHeight: { type: Boolean, default: true },
});
// #endregion

// #region Swiper
const swiperRef = ref<SwiperInstance | null>();

function update() {
   swiperRef.value?.update();
}

function initSwiper(swiper?: SwiperInstance) {
   swiperRef.value = swiper;
   activeTabChanged(props.activeTabKey);
}

function slideChange(swiper: SwiperInstance) {
   const { realIndex } = swiper;
   emits('update:activeTabKey', props.tabs[realIndex].key);
}

async function activeTabChanged(activeTabKey: string) {
   await nextTick();
   if (!swiperRef.value) return;

   if (activeTabKey === props.tabs[swiperRef.value.realIndex].key) return;

   swiperRef.value.slideTo(props.tabs.findIndex((tab) => tab.key === activeTabKey));
}

watch(() => props.activeTabKey, activeTabChanged, { immediate: true });

defineExpose({ update });
// #endregion
</script>

<template>
   <div :class="'tabs'">
      <div :class="['tabs__links', { '-sticky': stickyTabs }]">
         <ButtonLink
            v-for="tab in tabs"
            :key="tab.key"
            :class="['tabs__links__tab', { active: tab.key === activeTabKey }]"
            :color="tab.key === activeTabKey ? activeColor : inactiveColor"
            @click="$emit('update:activeTabKey', tab.key)"
         >
            {{ tab.label }}
         </ButtonLink>
      </div>
      <Swiper
         class="tabs__swiper"
         :modules="[Keyboard]"
         :keyboard="true"
         :slides-per-view="1"
         :auto-height="autoHeight"
         :space-between="14"
         @slide-change="slideChange"
         @swiper="initSwiper"
      >
         <SwiperSlide v-for="tab in tabs" :key="tab.key" class="tabs__swiper__slide">
            <slot v-if="lazy ? tab.key === activeTabKey : true" :name="tab.key"></slot>
         </SwiperSlide>
      </Swiper>
   </div>
</template>

<style lang="scss">
.tabs {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
   height: 100%;
}

.tabs__links {
   display: flex;
   flex-direction: row;
   gap: var(--nxt-gutter);
}

.tabs__links__tab {
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   color: var(--nxt-dark);
   padding: var(--nxt-gutter);

   &.active {
      color: v-bind(activeColor);
      font-weight: bold;
   }
}

.tabs__swiper {
   width: 100%;
   height: 100%;
   overflow: auto;
}
</style>
