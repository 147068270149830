<script lang="ts" setup>
import { Mask, vMaska } from 'maska';
import { useFormElement } from '~/composables/useFormElement';

// #region Form Element
const { name, invalid, required, disabled, label, touch } = useFormElement();
// #endregion

// #region Props & Emits
const emits = defineEmits<{
   (e: 'update:modelValue', value?: string): void;
   (e: 'onIconClicked'): void;
   (e: 'onChange', event: Event): void;
}>();
const props = defineProps({
   placeholder: { type: String, default: '' },
   minLength: { type: Number, default: null },
   maxLength: { type: Number, default: null },
   modelValue: { type: [String, Number], default: null },
   modelModifiers: { type: Object, default: () => ({}) },
   type: { type: String, default: 'text' },
   mask: { type: String, default: null },
   min: { type: [String, Number], default: null },
   max: { type: [String, Number], default: null },
   autofocus: { type: Boolean, default: false },
   accept: { type: String, default: null },

   iconName: { type: String, default: null },
   iconColor: { type: String, default: null },
});
// #endregion

// #region Mask
const localMask = ref<Mask>();

function setMask(newMask?: string) {
   if (!newMask) return;
   localMask.value = new Mask({ mask: newMask });
}

watch(() => props.mask, setMask, { immediate: true });
// #endregion

// #region Model Value
function handleInput(event: Event) {
   const inputElement = event?.target as HTMLInputElement;
   const inputValue = inputElement?.value;

   // Update dirty state
   const isMasked = Boolean(props.mask);
   const isValueChanged = isMasked
      ? localMask.value?.masked(String(props.modelValue) || '') !== localMask.value?.masked(inputValue || '')
      : String(props.modelValue) !== inputValue;

   if (isValueChanged && touch) {
      touch();
   }

   // Update model value
   const isLazy = Boolean(props.modelModifiers?.lazy);
   if (!isLazy) {
      emits('update:modelValue', inputValue);
   }
}

function handleChange(event: Event) {
   emits('onChange', event);

   if (!props.modelModifiers?.lazy) return;
   const value = (event?.target as HTMLInputElement)?.value;
   emits('update:modelValue', value);
}
// #endregion
</script>

<template>
   <div :class="['input', { invalid, icon: !!iconName }]">
      <input
         :id="name"
         v-maska
         :data-maska="mask"
         :name="name"
         :value="modelValue"
         :placeholder="placeholder || label"
         :disabled="disabled"
         :required="required"
         :minlength="minLength"
         :maxlength="maxLength"
         :type="type"
         :min="min"
         :max="max"
         :autofocus="autofocus"
         :accept="accept"
         autocomplete="off"
         @input="handleInput"
         @change="handleChange"
      />
      <ButtonIcon
         v-if="iconName"
         :class="'input__icon'"
         :icon-name="iconName"
         :icon-style="{ color: iconColor }"
         :tabindex="-1"
         @on-click="$emit('onIconClicked')"
      />
   </div>
</template>

<style lang="scss">
.input {
   width: 100%;
   position: relative;
   height: toRem(40);

   input {
      height: toRem(40);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
   }
}

.input.invalid input {
   border-color: var(--nxt-red);
}

.input.icon input {
   padding-right: toRem(40);
}

.input__icon {
   position: absolute;
   right: 0;
   top: 0;
   bottom: 0;
   margin: auto;
   padding: 0 var(--nxt-gutter-small);
   width: toRem(40);
}
</style>
