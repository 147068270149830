import { defineStore } from 'pinia';
import { DayInfo } from './day-info.interface';

export const useDayInfoStore = defineStore('day-info', () => {
   const dayInfoOverview = ref<{ [date: string]: DayInfo }>({});
   const loadingDayInfoOverview = ref<{ [date: string]: boolean }>({});
   const failedDayInfoOverview = ref<{ [date: string]: boolean }>({});
   const abortController = ref<AbortController>();

   // #region get day info
   async function getDayInfo(date: string) {
      loadingDayInfoOverview.value[date] = true;
      failedDayInfoOverview.value[date] = false;

      if (abortController.value && process.client) abortController.value.abort();
      if (process.client) abortController.value = new AbortController();

      try {
         const response = await authFetch<DayInfo>(`/day-info`, { params: { date }, signal: abortController.value?.signal });
         if (response) dayInfoOverview.value[date] = response;
      } catch (error) {
         failedDayInfoOverview.value[date] = true;
      } finally {
         loadingDayInfoOverview.value[date] = false;
         abortController.value = undefined;
      }
   }
   // #endregion

   // #region reset state
   function resetDayInfoState() {
      dayInfoOverview.value = {};
      loadingDayInfoOverview.value = {};
      failedDayInfoOverview.value = {};
   }
   // #endregion

   return {
      dayInfoOverview,
      loadingDayInfoOverview,
      failedDayInfoOverview,
      abortController,
      getDayInfo,
      resetDayInfoState,
   };
});
