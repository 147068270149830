
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/nl'
import updateLocale from 'dayjs/plugin/updateLocale'
import isoWeek from 'dayjs/plugin/isoWeek'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import weekday from 'dayjs/plugin/weekday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'

dayjs.extend(updateLocale)
dayjs.extend(isoWeek)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(weekday)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)
dayjs.extend(updateLocale)
dayjs.locale('fr')
dayjs.locale('en')
dayjs.locale('de')
dayjs.locale('nl')
dayjs.tz.setDefault('Europe/Brussels')


dayjs.updateLocale("nl",{"weekStart":1})

export default dayjs
