<script lang="ts" setup>
// #region Globals
defineExpose({ add: () => (showAddJobFunctionModal.value = true) });
// #endregion

// #region Props & Emits
const modelValue = defineModel<Array<PersonProfileJobFunction>>('modelValue', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region JobFunction Modals
const showAddJobFunctionModal = ref(false);
// #endregion

// #region JobFunctions
function addJobFunctions(jobFunctions: Array<JobFunction>) {
   const personJobFunctions: Array<PersonProfileJobFunction> = jobFunctions
      .filter((jobFunction) => !modelValue.value.find((jf) => jf.job_function_id === jobFunction.id))
      .map((jobFunction) => ({ job_function_id: jobFunction.id, job_function: jobFunction }));

   modelValue.value = [...modelValue.value, ...personJobFunctions];

   showAddJobFunctionModal.value = false;

   validate();
   touch();
}

function removeJobFunction(jobFunction: PersonProfileJobFunction) {
   modelValue.value.splice(modelValue.value.indexOf(jobFunction), 1);
   validate();
   touch();
}
// #endregion
</script>

<template>
   <div v-auto-animate class="person-todo-form-job-functions">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <div v-if="modelValue.length <= 0" :class="'person-todo-form-job-functions__no-desired_functions'">
         <p>
            {{ $t('generic.no-type-add-type', { type: $t('profile.job-preferences.desired-function', 2)?.toLowerCase() }) }}
         </p>
      </div>

      <div v-else v-auto-animate :class="'person-todo-form-job-functions__desired-functions'">
         <li
            v-for="jobFunction in modelValue"
            :key="jobFunction.job_function_id"
            :class="'person-todo-form-job-functions__desired-functions__tag'"
         >
            <span>{{ jobFunction.job_function?.name }}</span>

            <ButtonIcon
               :icon-name="'close'"
               :icon-label="$t('actions.delete-type', { type: $t('profile.job-preferences.desired-function', 1) })"
               @on-click="removeJobFunction(jobFunction)"
            />
         </li>
      </div>

      <ClientOnly>
         <!-- Create -->
         <Teleport to=".modal-side__card__content">
            <ProfileJobFunctionForm
               v-if="showAddJobFunctionModal"
               @on-save="addJobFunctions"
               @on-cancel="showAddJobFunctionModal = false"
            />
         </Teleport>
      </ClientOnly>
   </div>
</template>

<style lang="scss" scoped>
.person-todo-form-job-functions {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.person-todo-form-job-functions__no-desired_functions {
   text-align: center;
}

.person-todo-form-job-functions__desired-functions {
   display: flex;
   flex-wrap: wrap;
   gap: var(--nxt-gutter);

   list-style: none;
   padding: 0;
}

.person-todo-form-job-functions__desired-functions__tag {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
   border-radius: var(--nxt-radius);
   padding: 0 var(--nxt-gutter-small);
   background: var(--nxt-main-highlight);
}
</style>
