<script lang="ts" setup>
// #region Globals
const { t } = useI18n();
const { confirmDeleteType } = useModalStore();

defineExpose({ add: () => (showAddCompetenceModal.value = true) });
// #endregion

// #region Props & Emits
const modelValue = defineModel<Array<PersonProfileCompetence>>('modelValue', { default: [] });
const { invalid, errors, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region Competence Modals
const showAddCompetenceModal = ref(false);
// #endregion

// #region Competences
function addCompetence(competence: PersonProfileCompetence) {
   modelValue.value.push(competence);
   showAddCompetenceModal.value = false;

   validate();
   touch();
}

function removeCompetence(index: number) {
   confirmDeleteType(t('profile.skills.competence'), () => {
      modelValue.value.splice(index, 1);
      validate();
      touch();
   });
}
// #endregion

// #region Score
const { data: scores } = useAuthFetch<Array<Score>>(SCORE_ENDPOINTS.GET(), cacheConfig);
// #endregion

// #region Methods
function updateScore(index: number, value: number) {
   const score = scores.value?.find((s) => s.value === value);
   if (!score) return;
   modelValue.value[index].score_id = score.id;
   modelValue.value[index].score = score;

   validate();
   touch();
}
// #endregion
</script>

<template>
   <div :class="'person-todo-competences'">
      <StatusNotification v-if="invalid" type="error">
         {{ errors }}
      </StatusNotification>

      <div v-if="modelValue.length <= 0" class="person-todo-competences__no-competences">
         <p>
            {{ $t('generic.no-type-add-type', { type: $t('profile.skills.skill', 2).toLowerCase() }) }}
         </p>
      </div>

      <div v-else v-auto-animate class="person-todo-competences__competences">
         <div
            v-for="(personCompetence, index) in modelValue"
            :key="personCompetence.competence_id"
            class="person-todo-competences__competences__competence"
         >
            <p>{{ personCompetence.competence?.name }}</p>
            <PrecognitionFormElement :name="`person.competences.${index}.score`">
               <PrecognitionFormSlider
                  :model-value="scores?.find((s) => s.id === personCompetence.score_id)?.value || 0"
                  :min="0"
                  :max="4"
                  :step="1"
                  :show-value="true"
                  @update:model-value="updateScore(index, $event)"
               />
            </PrecognitionFormElement>
            <ButtonIcon
               :icon-name="'times'"
               :icon-label="$t('actions.delete-type', { type: $t('profile.skills.skill') })"
               @on-click="removeCompetence(index)"
            />
         </div>
      </div>

      <ProfileCompetenceForm v-if="showAddCompetenceModal" @on-cancel="showAddCompetenceModal = false" @on-save="addCompetence" />
   </div>
</template>

<style lang="scss" scoped>
.person-todo-competences {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}
.person-todo-competences__no-competences {
   text-align: center;
}

.person-todo-competences__competences {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.person-todo-competences__competences__competence {
   display: grid;
   grid-template-columns: toRem(100) 1fr toRem(30);
   align-items: center;
   gap: var(--nxt-gutter);
}
</style>
